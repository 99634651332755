import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import GeneralButton from "app/components/GeneralButton";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const TableTPS = ({ data, search, state, queryParams }) => {
  const [loading, setLoading] = useState(true);

  const getTableRowNumber = (index) => {
    return state.page * queryParams.limit + index + 1;
  };

  return (
    <Table style={{ minWidth: 700 }}>
      <TableHead className="t-header">
        <TableRow>
          <TableCell align="center">No</TableCell>
          <TableCell align="left" colSpan={2}>
            Nama TPS
          </TableCell>
          <TableCell align="center">Jumlah Suara</TableCell>
          <TableCell align="center">Aksi</TableCell>
        </TableRow>
      </TableHead>
      <TableBody className="t-body">
        {state.loading ? (
          <TableRow>
            <TableCell colSpan={5} align="center">
              Loading...
            </TableCell>
          </TableRow>
        ) : data.filter((item) =>
            item?.tps?.toLowerCase().includes(search.toLowerCase())
          ).length > 0 ? (
          data
            .filter((item) =>
              item?.tps?.toLowerCase().includes(search.toLowerCase())
            )
            .map((item, index) => (
              <TableRow key={index}>
                <TableCell className="px-0" align="center">
                  {getTableRowNumber(index)}
                </TableCell>
                <TableCell className="px-0 capitalize" align="left" colSpan={2}>
                  {item.tps}
                </TableCell>
                <TableCell className="px-0" align="center">
                  {item.jumlah_suara}
                </TableCell>
                <TableCell className="px-0" align="center">
                  <Link to={"/pemilu_terakhir/detail_tps/" + item.code}>
                    <GeneralButton
                      variant="contained"
                      color="primary"
                      text="Lihat Detail"
                      otherClass="radius-10"
                    />
                  </Link>
                </TableCell>
              </TableRow>
            ))
        ) : (
          data.filter((item) =>
            item?.tps?.toLowerCase().includes(search.toLowerCase())
          ).length === 0 && (
            <TableRow>
              <TableCell colSpan={5} align="center">
                Data kosong
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};

export default TableTPS;
