import { Button } from "@material-ui/core";
import React from "react";

export default function GeneralButton(props) {
  return (
    <div className={props.info ? "d-grid" : ""}>
      <Button
        variant={props.variant || "contained"}
        color={props.color || "primary"}
        className={"px-13 py-3 " + props.otherClass}
        style={{
          textTransform: "none",
          height: "47px",
          padding: "10px 16px",
          boxShadow: "none",
          color: props.textColor || "#fff",
          border: props.border || "none",
          fontWeight: props.fontWeight || "500",
        }}
        onClick={props.onClick}
        disabled={props.disabled || false}
      >
        {props.icon || ""}
        <span className="ms-1">{props.text}</span>
      </Button>
      {props.info ? <small className="fs-10">{props.info}</small> : ""}
    </div>
  );
}
