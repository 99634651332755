import { GET_SEBARAN_SUARA } from '../constant.js'

const initialState = {
  // Province dummy
  dataSebaranSuara: [
    {
      name: 'Aceh',
      jumlah_suara: 1200,
      partai: 'PAN',
      calon_pemilih: 120
    },
    {
      name: 'Bali',
      jumlah_suara: 2183,
      partai: 'PDIP',
      calon_pemilih: 72
    },
    {
      name: 'Bangka-Belitung',
      jumlah_suara: 3283,
      partai: 'Demokrat',
      calon_pemilih: 61
    },
    {
      name: 'Banten',
      jumlah_suara: 2311,
      partai: 'Berkarya',
      calon_pemilih: 72
    },
    {
      name: 'Bengkulu',
      jumlah_suara: 1274,
      partai: 'Garuda',
      calon_pemilih: 78
    },
    {
      name: 'Gorontalo',
      jumlah_suara: 4275,
      partai: 'Gerindra',
      calon_pemilih: 91
    },
    {
      name: 'Irian Jaya Barat',
      jumlah_suara: 4517,
      partai: 'Golkar',
      calon_pemilih: 98
    },
    {
      name: 'Jakarta Raya',
      jumlah_suara: 2258,
      partai: 'Hanura',
      calon_pemilih: 69
    },
    {
      name: 'Jambi',
      jumlah_suara: 3263,
      partai: 'Nasdem',
      calon_pemilih: 90
    },
    {
      name: 'Jawa Barat',
      jumlah_suara: 4457,
      partai: 'PBB',
      calon_pemilih: 89
    },
    {
      name: 'Jawa Tengah',
      jumlah_suara: 3274,
      partai: 'Perindo',
      calon_pemilih: 72
    },
    {
      name: 'Jawa Timur',
      jumlah_suara: 1274,
      partai: 'PSI',
      calon_pemilih: 118
    },
    {
      name: 'Kalimantan Barat',
      jumlah_suara: 3267,
      partai: 'PKPI',
      calon_pemilih: 114
    },
    {
      name: 'Kalimantan Selatan',
      jumlah_suara: 2156,
      partai: 'PPP',
      calon_pemilih: 111
    },
    {
      name: 'Kalimantan Tengah',
      jumlah_suara: 1211,
      partai: 'PPP',
      calon_pemilih: 132
    },
    {
      name: 'Kalimantan Timur',
      jumlah_suara: 2327,
      partai: 'PKS',
      calon_pemilih: 112
    },
    {
      name: 'Kepulauan Riau',
      jumlah_suara: 2198,
      partai: 'PKB',
      calon_pemilih: 52
    },
    {
      name: 'Lampung',
      jumlah_suara: 3256,
      partai: 'PDIP',
      calon_pemilih: 82
    },
    {
      name: 'Maluku',
      jumlah_suara: 2354,
      partai: 'PDIP',
      calon_pemilih: 92
    },
    {
      name: 'Maluku Utara',
      jumlah_suara: 1230,
      partai: 'Nasdem',
      calon_pemilih: 83
    },
    {
      name: 'Nusa Tenggara Barat',
      jumlah_suara: 1294,
      partai: 'PKS',
      calon_pemilih: 74
    },
    {
      name: 'Nusa Tenggara Timur',
      jumlah_suara: 2225,
      partai: 'Gerindra',
      calon_pemilih: 65
    },
    {
      name: 'Papua',
      jumlah_suara: 3249,
      partai: 'Demokrat',
      calon_pemilih: 55
    },
    {
      name: 'Riau',
      jumlah_suara: 1212,
      partai: 'PDIP',
      calon_pemilih: 45
    },
    {
      name: 'Sulawesi Barat',
      jumlah_suara: 3271,
      partai: 'PDIP',
      calon_pemilih: 83
    },
    {
      name: 'Sulawesi Selatan',
      jumlah_suara: 3299,
      partai: 'PKS',
      calon_pemilih: 85
    },
    {
      name: 'Sulawesi Tengah',
      jumlah_suara: 2374,
      partai: 'PDIP',
      calon_pemilih: 59
    },
    {
      name: 'Sulawesi Tenggara',
      jumlah_suara: '89',
      partai: 'Golkar',
      calon_pemilih: 76
    },
    {
      name: 'Sulawesi Utara',
      jumlah_suara: 3284,
      partai: 'PKS',
      calon_pemilih: 67
    },
    {
      name: 'Sumatera Barat',
      jumlah_suara: 2157,
      partai: 'PKB',
      calon_pemilih: 57
    },
    {
      name: 'Sumatera Selatan',
      jumlah_suara: 1225,
      partai: 'Gerindra',
      calon_pemilih: 96
    },
    {
      name: 'Sumatera Utara',
      jumlah_suara: 3286,
      partai: 'Nasdem',
      calon_pemilih: 89
    },
    {
      name: 'Yogyakarta',
      jumlah_suara: 3282,
      partai: 'PDIP',
      calon_pemilih: 88
    }
  ],

  // Kota Dummy
  dataSebaranSuaraKota: [
    {
      id: 3201,
      province_id: '32',
      name: 'KABUPATEN BOGOR',
      partai: 'Demokrat',
      jumlah_suara: 1057,
      calon_pemilih: 198
    },
    {
      id: 3202,
      province_id: '32',
      name: 'KABUPATEN SUKABUMI',
      partai: 'Berkarya',
      jumlah_suara: 1937,
      calon_pemilih: 152
    },
    {
      id: 3203,
      province_id: '32',
      name: 'KABUPATEN CIANJUR',
      partai: 'Garuda',
      jumlah_suara: 1670,
      calon_pemilih: 107
    },
    {
      id: 3204,
      province_id: '32',
      name: 'KABUPATEN BANDUNG',
      partai: 'Gerindra',
      jumlah_suara: 1498,
      calon_pemilih: 171
    },
    {
      id: 3205,
      province_id: '32',
      name: 'KABUPATEN GARUT',
      partai: 'Golkar',
      jumlah_suara: 1641,
      calon_pemilih: 190
    },
    {
      id: 3206,
      province_id: '32',
      name: 'KABUPATEN TASIKMALAYA',
      partai: 'Hanura',
      jumlah_suara: 1882,
      calon_pemilih: 138
    },
    {
      id: 3207,
      province_id: '32',
      name: 'KABUPATEN CIAMIS',
      partai: 'Nasdem',
      jumlah_suara: 1575,
      calon_pemilih: 172
    },
    {
      id: 3208,
      province_id: '32',
      name: 'KABUPATEN KUNINGAN',
      partai: 'PBB',
      jumlah_suara: 1552,
      calon_pemilih: 169
    },
    {
      id: 3209,
      province_id: '32',
      name: 'KABUPATEN CIREBON',
      partai: 'Perindo',
      jumlah_suara: 1398,
      calon_pemilih: 153
    },
    {
      id: 3210,
      province_id: '32',
      name: 'KABUPATEN MAJALENGKA',
      partai: 'PKB',
      jumlah_suara: 1321,
      calon_pemilih: 162
    },
    {
      id: 3211,
      province_id: '32',
      name: 'KABUPATEN SUMEDANG',
      partai: 'PKPI',
      jumlah_suara: 1252,
      calon_pemilih: 182
    },
    {
      id: 3212,
      province_id: '32',
      name: 'KABUPATEN INDRAMAYU',
      partai: 'PPP',
      jumlah_suara: 1310,
      calon_pemilih: 109
    },
    {
      id: 3213,
      province_id: '32',
      name: 'KABUPATEN SUBANG',
      partai: 'PSI',
      jumlah_suara: 1190,
      calon_pemilih: 170
    },
    {
      id: 3214,
      province_id: '32',
      name: 'KABUPATEN PURWAKARTA',
      partai: 'PKS',
      jumlah_suara: 1256,
      calon_pemilih: 124
    },
    {
      id: 3215,
      province_id: '32',
      name: 'KABUPATEN KARAWANG',
      partai: 'PAN',
      jumlah_suara: 1676,
      calon_pemilih: 183
    },
    {
      id: 3216,
      province_id: '32',
      name: 'KABUPATEN BEKASI',
      partai: 'PDIP',
      jumlah_suara: 1798,
      calon_pemilih: 110
    },
    {
      id: 3217,
      province_id: '32',
      name: 'KABUPATEN BANDUNG BARAT',
      partai: 'Demokrat',
      jumlah_suara: 1250,
      calon_pemilih: 108
    },
    {
      id: 3218,
      province_id: '32',
      name: 'KABUPATEN PANGANDARAN',
      partai: 'Berkarya',
      jumlah_suara: 1348,
      calon_pemilih: 118
    },
    {
      id: 3271,
      province_id: '32',
      name: 'KOTA BOGOR',
      partai: 'Garuda',
      jumlah_suara: 1354,
      calon_pemilih: 141
    },
    {
      id: 3272,
      province_id: '32',
      name: 'KOTA SUKABUMI',
      partai: 'Gerindra',
      jumlah_suara: 1090,
      calon_pemilih: 164
    },
    {
      id: 3273,
      province_id: '32',
      name: 'KOTA BANDUNG',
      partai: 'Golkar',
      jumlah_suara: 1164,
      calon_pemilih: 199
    },
    {
      id: 3274,
      province_id: '32',
      name: 'KOTA CIREBON',
      partai: 'Hanura',
      jumlah_suara: 1540,
      calon_pemilih: 193
    },
    {
      id: 3275,
      province_id: '32',
      name: 'KOTA BEKASI',
      partai: 'Nasdem',
      jumlah_suara: 1499,
      calon_pemilih: 196
    },
    {
      id: 3276,
      province_id: '32',
      name: 'KOTA DEPOK',
      partai: 'PBB',
      jumlah_suara: 1370,
      calon_pemilih: 199
    },
    {
      id: 3277,
      province_id: '32',
      name: 'KOTA CIMAHI',
      partai: 'Perindo',
      jumlah_suara: 1377,
      calon_pemilih: 141
    },
    {
      id: 3278,
      province_id: '32',
      name: 'KOTA TASIKMALAYA',
      partai: 'PKB',
      jumlah_suara: 1712,
      calon_pemilih: 182
    },
    {
      id: 3279,
      province_id: '32',
      name: 'KOTA BANJAR',
      partai: 'PKPI',
      jumlah_suara: 1324,
      calon_pemilih: 152
    }
  ]
}

const SebaranSuaraReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_SEBARAN_SUARA: {
      return {
        ...state
        // dataSebaranSuara: action?.payload || [],
      }
    }

    default: {
      return state
    }
  }
}

export default SebaranSuaraReducer
