import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  createStyles,
  Hidden
} from "@material-ui/core";
import { Alert } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton } from "../../components";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { sendResetPassword } from "app/redux/actions/AuthAction";
import { showError, showSuccess } from "app/components/sweetalertService";

const useStyles = makeStyles(({ palette, ...theme }) =>
  createStyles({
    logo: {
      maxWidth: 188,
      marginBottom: 30,
    },
    containerForm: {
      padding: "1rem",
      [theme.breakpoints.up("md")]: {
        padding: "1rem 3rem",
      },
    },
    bgPage: {
      backgroundImage: "url('/assets/images/illustrations/bg-page.webp')",
      backgroundRepeat: "repeat",
    },
  })
);

const ForgotPassword = () => {
  const [emailErrorText, setEmailErrorText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [params, setParams] = useState({
    email: "",
  });

  const handleChange = (e) => {
    setParams({
      ...params,
      [e.target.name]: e.target.value,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await setLoading(true);
    if (!params.email) {
      setEmailErrorText("Please enter username");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(params.email)
    ) {
      setEmailErrorText("Invalid email address");
      setLoading(false);
      return;
    } else {
      setEmailErrorText("");
    }

    if (params.email) {
      sendResetPassword(params)
        .then((res) => {
          showSuccess(res.data.message)
          setParams({ email: "" });
          setLoading(false);
        })
        .catch((error) => {
          setEmailErrorText(error.response.data.message);
          setLoading(false);
        });
    }
  };

  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        margin: "0 auto",
      }}
    >
      <Hidden xsDown>
        <Card className="m-5" style={{ maxWidth: "535px", maxHeight: "531px", padding: "40px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Link to="/">
            <img
              src="/assets/images/logos/logo_pemilu_now.png"
              alt="Pemilu Now"
              className={classes.logo}
            />
          </Link>
          <h4 className="mb-2" style={{ fontSize: "16px" }}>Silahkan masukkan email yang anda daftarkan di situs Pemilu Now untuk melakukan reset password.</h4>

          <Grid container spacing={0} justifyContent="center">
            <Grid item sm={12} xs={12} lg={12} md={12}>
              <Box component="form" autoComplete="off">
                <FormControl className="w-full mb-4" style={{ width: '100%' }}>
                  <label htmlFor="outlined-basic" className="mb-2 fw-bold" style={{ fontWeight: "bold" }}>
                    Email
                  </label>
                  <TextField
                    fullWidth
                    hiddenLabel
                    type="email"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder="Masukan email"
                    name="email"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                  <FormHelperText id="component-error-text" className="text-danger">
                    {emailErrorText}
                  </FormHelperText>
                </FormControl>
                <Grid container>
                  <CustomButton
                    text={loading ? <CircularProgress /> : "Kirim"}
                    fw={true}
                    onClick={handleSubmit}
                    disabled={loading}
                    bgColor="#00ACEE"
                    color="#FFFFFF"

                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Hidden>

      <Hidden smUp>
        <Card style={{ width: "535px", height: "100vh", padding: "40px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Link to="/">
            <img
              src="/assets/images/logos/logo_pemilu_now.png"
              alt="Pemilu Now"
              className={classes.logo}
            />
          </Link>
          <h4 className="mb-2" style={{ fontSize: "16px" }}>Silahkan masukkan email yang anda daftarkan di situs Pemilu Now untuk melakukan reset password.</h4>

          <Grid container spacing={0} justifyContent="center">
            <Grid item sm={12} xs={12} lg={12} md={12}>
              <Box component="form" autoComplete="off">
                <FormControl className="w-full mb-4" style={{ width: '100%' }}>
                  <label htmlFor="outlined-basic" className="mb-2 fw-bold" style={{ fontWeight: "bold" }}>
                    Email
                  </label>
                  <TextField
                    fullWidth
                    hiddenLabel
                    type="email"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder="Masukan email"
                    name="email"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                  <FormHelperText id="component-error-text" className="text-danger">
                    {emailErrorText}
                  </FormHelperText>
                </FormControl>
                <Grid container>
                  <CustomButton
                    text={loading ? <CircularProgress /> : "Kirim"}
                    fw={true}
                    onClick={handleSubmit}
                    disabled={loading}
                    bgColor="#00ACEE"
                    color="#FFFFFF"

                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Hidden>
    </div>
  );
};

export default ForgotPassword;