/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import ChartStatistic from "./components/ChartStatistic";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  getStatisticPemilih,
  getStatisticRelawan,
} from "app/redux/actions/StatisticActions";
import { UsersThree, Users } from "@phosphor-icons/react";
import {
  getDataDashboard,
  getGrafikDashboardPemilihMonthly,
  getGrafikdDashboardPemilihDaily,
} from "app/redux/actions/DashboardActions";
import CardKandidat from "./components/CardKandidat";
import CardHitungMundur from "./components/CardHitungMundur";
import CardTotalRelawan from "app/components/card/CardTotalRelawan";
const Dashboard = () => {
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState("daily");
  const [selectedYear, setSelectedYear] = useState("");

  const { dataStatisticPemilih, dataStatisticRelawan } = useSelector(
    (state) => state.statistic
  );

  const {
    dashboardKandidat = [],
    dataGrafikDashboardDaily,
    dataGrafikDashboardMonthly,
  } = useSelector((state) => state.dashboard);

  const getData = () => {
    try {
      dispatch(getStatisticRelawan());
      dispatch(getStatisticPemilih());
      dispatch(getDataDashboard());
      dispatch(getGrafikdDashboardPemilihDaily());
      dispatch(getGrafikDashboardPemilihMonthly());
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const filterDataGrafik = (data) => {
    let filterTahun = selectedYear ?? "";
    let params = "?tahun=" + filterTahun;
    if (data === "calon_pemilih") {
      if (selectedFilter === "daily") {
        dispatch(getGrafikdDashboardPemilihDaily(params));
      } else {
        dispatch(getGrafikDashboardPemilihMonthly(params));
      }
    }
  };

  useEffect(() => {
    filterDataGrafik();
  }, []);

  // const targetDate = moment("2023-10-10T09:00:00");
  const targetDate = moment(dashboardKandidat.kandidat_hitung_mundur);
  const voteDate = targetDate.format("DD MMMM YYYY, HH:mm");

  const calculateTimeLeft = () => {
    const now = moment();
    const timeLeft = Math.max(targetDate.diff(now), 0);

    const duration = moment.duration(timeLeft);
    const monthsLeft = duration.months();
    const daysLeft = duration.days();
    const hoursLeft = duration.hours();
    const minutesLeft = duration.minutes();
    const secondsLeft = duration.seconds();

    return {
      monthsLeft,
      daysLeft,
      hoursLeft,
      minutesLeft,
      secondsLeft,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <Fragment>
      <div className="p-40">
        <h1 className="fs-2 fw-700 mb-sm-30">Dashboard</h1>
        <Grid container spacing={2}>
          <CardKandidat dashboardKandidat={dashboardKandidat} />
          <CardHitungMundur timeLeft={timeLeft} voteDate={voteDate} />
        </Grid>

        <Grid container className="py-24" spacing={2}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <CardTotalRelawan
              cardTitle="Total Relawan Aktif"
              totalJumlah={dataStatisticRelawan.totalRelawanAktif}
              className="text-green-50"
              icon={UsersThree}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <CardTotalRelawan
              cardTitle="Total Relawan Nonaktif"
              totalJumlah={dataStatisticRelawan.totalRelawanNonAktif}
              className="text-danger"
              icon={UsersThree}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <CardTotalRelawan
              cardTitle="Total Calon Pemilih"
              totalJumlah={dataStatisticPemilih.totalPemilih}
              className=""
              icon={Users}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <ChartStatistic
              dataGrafikPemilihDaily={dataGrafikDashboardDaily}
              dataGrafikPemilihMonthly={dataGrafikDashboardMonthly}
              selectedFilter={selectedFilter}
              onSelectFilter={setSelectedFilter}
              onSelectYear={setSelectedYear}
              getDataGrafik={filterDataGrafik}
            />
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default Dashboard;
