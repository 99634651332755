import {
  GET_DETAIL_TPS,
  GET_GRAFIK_REKAP_SUARA,
  GET_KABUPATEN_PT,
  GET_KECAMATAN_PT,
  GET_KELURAHAN_PT,
  GET_PEMILU_TERAKHIR,
  GET_PROVINSI_PT,
  GET_RANGKUMAN_PARTAI,
  GET_RANGKUMAN_CALEG,
  GET_GRAFIK_RANGKUMAN_CALEG,
  GET_RANGKUMAN_SUARA_PARTAI,
  GET_GRAFIK_RANGKUMAN_SUARA_PARTAI,
  GET_GRAFIK_RANGKUMAN_PARTAI,
  GET_DETAIL_RANGKUMAN_PARTAI,
  GET_LIST_CALEG_PERPARTAI,
  GET_SUARA_CALEG_PERPARTAI
} from '../constant.js'

const initialState = {
  dataPemiluTerakhir: [],
  dataPemiluTerakhirPagination: {},
  provinsi: [],
  kabupaten: [],
  kecamatan: [],
  kelurahan: [],
  tps: {},
  detailTPS: [],
  detailTPSPagination: [],
  detailRekapSuara: [],
  suara_caleg_terbanyak: [],
  suara_partai_terbanyak: [],
  rangkumanPartai: [],
  rangkumanCalegDetail: [],
  rangkumanCalegData: [],
  rangkumanCalegGrafik: [],
  rangkumanSuaraPartaiDetail: [],
  rangkumanSuaraPartaiData: [],
  rangkumanSuaraPartaiGrafik: [],
  rangkumanPartaiDetail: [],
  rangkumanPartaiGrafik: [],
  calegPerPartai: [],
  suaraCalegPerPartai: []
}

const PemiluTerakhirReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_PEMILU_TERAKHIR: {
      return {
        ...state,
        dataPemiluTerakhir: action.payload.data,
        dataPemiluTerakhirPagination: action.payload.pagination
      }
    }
    case GET_PROVINSI_PT: {
      return {
        ...state,
        provinsi: action.payload
      }
    }
    case GET_KABUPATEN_PT: {
      return {
        ...state,
        kabupaten: action.payload
      }
    }
    case GET_KECAMATAN_PT: {
      return {
        ...state,
        kecamatan: action.payload
      }
    }
    case GET_KELURAHAN_PT: {
      return {
        ...state,
        kelurahan: action.payload
      }
    }
    case GET_DETAIL_TPS: {
      return {
        ...state,
        tps: action.payload.detail,
        detailTPS: action.payload.data,
        detailTPSPagination: action.payload.pagination
      }
    }
    case GET_GRAFIK_REKAP_SUARA: {
      return {
        ...state,
        detailRekapSuara: action.payload.detail,
        suara_caleg_terbanyak: action.payload.suara_caleg_terbanyak,
        suara_partai_terbanyak: action.payload.suara_partai_terbanyak
      }
    }
    case GET_RANGKUMAN_PARTAI: {
      return {
        ...state,
        rangkumanPartai: action.payload
      }
    }
    case GET_RANGKUMAN_CALEG: {
      return {
        ...state,
        rangkumanCalegDetail: action.payload.detail,
        rangkumanCalegData: action.payload.data
      }
    }
    case GET_GRAFIK_RANGKUMAN_CALEG: {
      return {
        ...state,
        rangkumanCalegGrafik: action.payload
      }
    }
    case GET_RANGKUMAN_SUARA_PARTAI: {
      return {
        ...state,
        rangkumanSuaraPartaiDetail: action.payload.detail,
        rangkumanSuaraPartaiData: action.payload.data
      }
    }
    case GET_GRAFIK_RANGKUMAN_SUARA_PARTAI: {
      return {
        ...state,
        rangkumanSuaraPartaiGrafik: action?.payload.data
      }
    }
    case GET_GRAFIK_RANGKUMAN_PARTAI: {
      return {
        ...state,
        rangkumanPartaiGrafik: action.payload.data
      }
    }
    case GET_DETAIL_RANGKUMAN_PARTAI: {
      return {
        ...state,
        rangkumanPartaiDetail: action.payload
      }
    }
    case GET_LIST_CALEG_PERPARTAI: {
      return {
        ...state,
        calegPerPartai: action.payload
      }
    }
    case GET_SUARA_CALEG_PERPARTAI: {
      return {
        ...state,
        suaraCalegPerPartai: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default PemiluTerakhirReducer
