import {
  GET_TPS
} from "../constant.js";

const initialState = {
  dataTPS: []
};

const TPSReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_TPS: {
      return {
        ...state,
        dataTPS: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default TPSReducer;
