import { API, setAuthToken } from 'config/API.js'
import { GET_WARGA_BINAAN, GET_DETAIL_WARGA_BINAAN } from '../constant.js'

// export const getDataWargaBinaan = (params = '') => {
//   return dispatch => {
//     const token = localStorage.getItem('jwt_token')
//     setAuthToken(token)
//     return API.get(`/user/wargabinaan?${new URLSearchParams(params)}`)
//       .then(res => {
//         dispatch({
//           type: GET_WARGA_BINAAN,
//           payload: res.data.data || []
//         })
//       })
//       .catch(() => {
//         dispatch({
//           type: GET_WARGA_BINAAN,
//           payload: []
//         })
//       })
//   }
// }

export const getDataWargaBinaan = (params = "") => {
  return (dispatch) => {
    dispatch({
      type: GET_WARGA_BINAAN,
      payload: {
        data: [],
        pagination: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
          total: 1,
        },
      },
    });

    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    return API.get(`/user/wargabinaan?${new URLSearchParams(params)}`)
      .then((res) => {
        dispatch({
          type: GET_WARGA_BINAAN,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_WARGA_BINAAN,
          payload: [],
        });
      });
  };
};

export const getDetailWargaBinaan = id => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`/user/wargabinaan/${id}`)
      .then(res => {
        dispatch({
          type: GET_DETAIL_WARGA_BINAAN,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_DETAIL_WARGA_BINAAN,
          payload: []
        })
      })
  }
}

export const editWargaBinaan = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/user/wargabinaan/update', body)
}

export const addWargaBinaan = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/user/wargabinaan/add', body)
}

export const deleteWargaBinaan = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/user/wargabinaan/delete', body)
}

export const importWargaBinaan = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/user/wargabinaan/import', body)
}
