import { API, setAuthToken } from 'config/API.js'
import {
  GET_RELAWAN,
  GET_DETAIL_RELAWAN,
  GET_DETAIL_LOGISTIK_KAMPANYE_RELAWAN,
  GET_RELAWAN_STATISTIC,
  GET_GRAFIK_RELAWAN_DAILY,
  GET_GRAFIK_RELAWAN_MONTHLY
} from '../constant.js'

export const getDataRelawan = (params = '') => {
  return dispatch => {
    dispatch({
      type: GET_RELAWAN,
      payload: {
        data: [],
        pagination: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
          total: 1
        }
      }
    })

    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    return API.get(`/user/relawan?${new URLSearchParams(params)}`)
      .then(res => {
        dispatch({
          type: GET_RELAWAN,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_RELAWAN,
          payload: []
        })
      })
  }
}

export const getDetailRelawan = (relawan_code = '') => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`/user/relawan/` + relawan_code)
      .then(res => {
        dispatch({
          type: GET_DETAIL_RELAWAN,
          payload: res.data || {}
        })
      })
      .catch(() => {
        dispatch({
          type: GET_DETAIL_RELAWAN,
          payload: {}
        })
      })
  }
}
// export const getDetailLogistikKampanyeRelawan = (relawan_code = '', params = '') => {
//   return dispatch => {
//     const token = localStorage.getItem('jwt_token')
//     setAuthToken(token)
//     API.get(`/user/relawan/` + relawan_code + '/logistik/kampanye' + params)
//       .then(res => {
//         dispatch({
//           type: GET_DETAIL_LOGISTIK_KAMPANYE_RELAWAN,
//           payload: res.data.data || []
//         })
//       })
//       .catch(() => {
//         dispatch({
//           type: GET_DETAIL_LOGISTIK_KAMPANYE_RELAWAN,
//           payload: []
//         })
//       })
//   }
// }

export const getDetailLogistikKampanyeRelawan = (relawan_code = '', params = '') => {
  return dispatch => {
    dispatch({
      type: GET_DETAIL_LOGISTIK_KAMPANYE_RELAWAN,
      payload: {
        data: [],
        pagination: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
          total: 1
        }
      }
    })

    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    return API.get(`/user/relawan/` + relawan_code + '/logistik/kampanye' + params)
      .then(res => {
        dispatch({
          type: GET_DETAIL_LOGISTIK_KAMPANYE_RELAWAN,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_DETAIL_LOGISTIK_KAMPANYE_RELAWAN,
          payload: []
        })
      })
  }
}

export const getRelawanStatistic = relawan_code => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`/user/relawan/${relawan_code}/statistik`)
      .then(res => {
        dispatch({
          type: GET_RELAWAN_STATISTIC,
          payload: res.data || {}
        })
      })
      .catch(() => {
        dispatch({
          type: GET_RELAWAN_STATISTIC,
          payload: {}
        })
      })
  }
}

export const getRelawanStatisticDaily = (relawan_code, params = '') => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`/user/relawan/${relawan_code}/statistik/grafik/daily` + params)
      .then(res => {
        dispatch({
          type: GET_GRAFIK_RELAWAN_DAILY,
          payload: res.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_GRAFIK_RELAWAN_DAILY,
          payload: []
        })
      })
  }
}

export const getRelawanStatisticMonthly = (relawan_code, params = '') => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`/user/relawan/${relawan_code}/statistik/grafik/monthly` + params)
      .then(res => {
        dispatch({
          type: GET_GRAFIK_RELAWAN_MONTHLY,
          payload: res.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_GRAFIK_RELAWAN_MONTHLY,
          payload: []
        })
      })
  }
}

export const addRelawan = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/user/relawan/add', body)
}

export const updateRelawan = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/user/relawan/update', body)
}

export const deleteRelawan = code => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/user/relawan/delete', code)
}

export const importRelawan = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/user/relawan/import', body)
}
