import Survey from "./Survey";
import AddSurvey from "./AddSurvey";
import DetailSurvey from "./DetailSurvey";
import EditSurvey from "./EditSurvey";

const SurveyRoutes = [
  {
    path: "/survey",
    exact: true,
    component: Survey
  },
  {
    path: "/survey/tambah",
    exact: true,
    component: AddSurvey
  },
  {
    path: "/survey/detail",
    exact: true,
    component: DetailSurvey
  },
  {
    path: "/survey/edit",
    exact: true,
    component: EditSurvey
  },
];

export default SurveyRoutes;
