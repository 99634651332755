import {
    GET_DASHBOARD,
    GET_DASHBOARD_GRAFIK_DAILY,
    GET_DASHBOARD_GRAFIK_MONTHLY
} from "../constant.js";

const initialState = {
    dashboardKandidat: {},
    dashboardRelawan: {},
    dashboardCalonPemilih: {},
    dataGrafikDashboardDaily: [],
    dataGrafikDashboardMonthly: [],
};

const DashboardReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_DASHBOARD: {
            return {
                ...state,
                dashboardKandidat: action.payload.kandidat,
                dashboardRelawan: action.payload.relawan,
                dashboardCalonPemilih: action.payload.calon_pemilih,
            };
        }
        case GET_DASHBOARD_GRAFIK_DAILY: {
            return {
                ...state,
                dataGrafikDashboardDaily: action?.payload || []
            };
        }
        case GET_DASHBOARD_GRAFIK_MONTHLY: {
            return {
                ...state,
                dataGrafikDashboardMonthly: action?.payload || []
            };
        }
        default: {
            return state;
        }
    }
};

export default DashboardReducer;
