import {
  GET_DETAIL_ARUS_KAS,
  GET_KATEGORI_ARUS_KAS
} from '../actions/ArusKasActions.js'
import { GET_ARUS_KAS } from '../constant.js'

const initialState = {
  dataArusKas: [],
  dataDetailArusKas: [],
  kategori: [],
  paginationArusKas: []
}

const ArusKasReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ARUS_KAS: {
      return {
        ...state,
        dataArusKas: action?.payload.data || [],
        paginationArusKas: action?.payload.pagination || {}
      }
    }
    case GET_DETAIL_ARUS_KAS: {
      return {
        ...state,
        dataDetailArusKas: action?.payload.data || []
      }
    }
    case GET_KATEGORI_ARUS_KAS: {
      return {
        ...state,
        kategori: action?.payload.data || []
      }
    }
    default: {
      return state
    }
  }
}

export default ArusKasReducer
