/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from "react";
import { Grid, Card } from "@mui/material";
import ChartStatisticLogistik from "./components/ChartStatisticLogistik";

const StatistikLogistik = ({ dataStatisticPemilih, color, dataY, dataX }) => {

    return (
        <Fragment>
            <Card
                style={{
                    padding: "38px",
                    borderRadius: "10px",
                }}
            >
                <Grid container spacing={3} className="mb-4" alignItems="center">
                    <Grid item xs={12} lg={6} md={6}>
                        <h3 className="font-size-20 fw-bold">Statistik Stok Logistik</h3>
                    </Grid>
                </Grid>
                <ChartStatisticLogistik
                    chart={dataStatisticPemilih}
                    color={color}
                    dataX={dataY}
                    dataY={dataY}
                />
            </Card>
        </Fragment>
    );
};

export default StatistikLogistik;
