import {
    GET_CATEGORY_LOGISTIK
} from "../constant.js";

const initialState = {
    dataKategoriLogistik: [],
};

const CategoryLogisticReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_CATEGORY_LOGISTIK: {
            return {
                ...state,
                dataKategoriLogistik: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default CategoryLogisticReducer;
