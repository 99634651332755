import {
  Card,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Icon,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { UsersThree } from "@phosphor-icons/react";
import InputFileImg from "app/components/Input/InputFileImg";
import dayjs from "dayjs";
import { format } from "date-fns";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CalendarIcon } from "app/components/Icons";
import Avatar from "@mui/material/Avatar";
import { green, red } from "@mui/material/colors";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
import GeneralButton from "app/components/GeneralButton";
import { AddCircleOutline } from "@material-ui/icons";
import FormControlLabel from "@mui/material/FormControlLabel";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#e34242",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const DetailSurvey = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [state, setState] = useState({
    name: "",
    ketua: "",
    provinsi: "Jawa Barat",
    kab_kota: "Bandung",
    kecamatan: "Cileunyi",
    kelurahan_desa: "Ciliwung",
    image_preview: "",
    image_ktp: "",
  });

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    try {
      const data = {
        name: state.name,
        ketua: state.ketua,
        provinsi: state.provinsi,
        kab_kota: state.kab_kota,
        kecamatan: state.kecamatan,
        kelurahan_desa: state.kelurahan_desa,
        lainnya: state.other,
      };
    } catch (e) {
      Swal.fire("Oopss!", e.message, "error");
    }
  };

  // ==== PERTANYAAN ====
  const [question, setQuestion] = useState([
    {
      title: "Test 1",
      options: ["Option A", "Option B", "Option C", "Option D"],
    },
  ]);

  const handleAddQuestion = () => {
    setQuestion([...question, { title: "", options: [""] }]);
  };

  const handleDeleteQuestion = (index) => {
    const values = [...question];
    values.splice(index, 1);
    console.log(index);
    console.log(values);
    setQuestion(values);
  };

  const handleChangeQuestion = (index) => (e) => {
    const values = [...question];
    values[index].title = e.target.value;
    console.log(index);
    console.log(values);
    setQuestion(values);
  };

  const handleChangeOption = (question_index, option_index) => (e) => {
    const values = [...question];
    values[question_index].options[option_index] = e.target.value;
    console.log(question_index);
    console.log(option_index);
    console.log(values);
    setQuestion(values);
  };

  const handleAddOption = (question_index, option_index) => (e) => {
    const values = [...question];
    values[question_index].options.splice(option_index + 1, 0, "");
    console.log(question_index);
    console.log(option_index);
    console.log(values);
    setQuestion(values);
  };

  const handleDeleteOption = (question_index, option_index) => (e) => {
    const values = [...question];
    values[question_index].options.splice(option_index, 1);
    console.log(question_index);
    console.log(option_index);
    console.log(values);
    setQuestion(values);
  };

  return (
    <div className="analytics m-sm-30 mt-7">
      <div
        className="mb-sm-30"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 className="font-size-32 fw-700 fw-bold m-0">Detail Survei</h1>
        <div>
          <Button
            variant="contained"
            className="p-10"
            color="primary"
            style={{
              textTransform: "none",
              color: "#FFFFFF",
              marginRight: "10px",
            }}
          >
            <span style={{ marginRight: "5px" }}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.75 8.25V12.75L8.25 11.25"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.75 12.75L5.25 11.25"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5 7.5V11.25C16.5 15 15 16.5 11.25 16.5H6.75C3 16.5 1.5 15 1.5 11.25V6.75C1.5 3 3 1.5 6.75 1.5H10.5"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5 7.5H13.5C11.25 7.5 10.5 6.75 10.5 4.5V1.5L16.5 7.5Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span className="">Cetak PDF</span>
          </Button>
          <Button
            onClick={() => history.goBack()}
            variant="contained"
            className="p-10 bg-green-50"
            style={{ textTransform: "none", color: "#FFFFFF" }}
          >
            <span className="">Kembali</span>
          </Button>
        </div>
      </div>
      <Card style={{ padding: "20px", marginBottom: "20px" }}>
        <Grid container className="mt-2 p-2 " spacing={2}>
          <Grid
            container
            spacing={3}
            className="mb-4 px-2"
            alignItems="center"
            justifyContent="space-between"
            style={{ marginLeft: "0px", marginRight: "0px" }}
          >
            <Grid item lg={9} md={9}>
              <Grid container spacing={3}>
                <Grid item lg={6} md={6} className="p-0">
                  <h3 className="fw-bold font-size-40">Detail Survei</h3>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={3} md={3}>
              <Grid
                item
                xs={12}
                sm
                className="flex flex-end"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <span style={{ color: "#757575" }}>Status</span>
                <FormControlLabel
                  control={
                    <IOSSwitch sx={{ m: 1, marginRight: "0" }} defaultChecked />
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={12} md={12} xs={12} sm={12}>
            <InputLabel htmlFor="umur">
              <h5 className="font-size-14">Judul</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.umur}
              name="nik"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Judul"
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>

          <Grid item lg={12} md={12} xs={12} sm={12}>
            <InputLabel htmlFor="name">
              <h5 className="font-size-14">Catatan</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.lainnya}
              name="name"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              multiline
              rows={3}
              placeholder="Tulis catatan disini..."
              variant="outlined"
              onChange={handleChange}
              type="text"
            />
          </Grid>
        </Grid>
      </Card>

      {/* ==== PERTANYAAN ==== */}
      {question.length > 0
        ? question.map((item, question_index) => (
            <Card
              style={{ padding: "20px", marginBottom: "20px" }}
              key={question_index}
            >
              <Grid container className="mt-2 p-2 " spacing={2}>
                <Grid
                  container
                  spacing={3}
                  className="mb-4 px-2"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                >
                  <Grid item lg={12} md={12}>
                    <Grid container spacing={3}>
                      <Grid item lg={12} md={12} className="p-0">
                        <h3 className="fw-bold font-size-40">
                          Pertanyaan {question_index + 1}
                        </h3>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} xs={12} sm={12}>
                  <InputLabel htmlFor="umur">
                    <h5 className="font-size-14">Pertanyaan</h5>
                  </InputLabel>
                  <TextField
                    required={true}
                    size="small"
                    value={question[question_index].title}
                    name="nik"
                    className={`w-100`}
                    InputProps={{
                      style: {
                        borderRadius: 5,
                      },
                    }}
                    placeholder="Tulis pertanyaan disini"
                    variant="outlined"
                    onChange={handleChangeQuestion(question_index)}
                  />
                </Grid>
                {question[question_index].options.length > 0
                  ? question[question_index].options.map(
                      (item, option_index) => (
                        <React.Fragment key={option_index}>
                          <Grid item lg={6} md={6} xs={12} sm={12}>
                            <InputLabel htmlFor={`umur${option_index}`}>
                              <h5 className="font-size-14">
                                Opsi {option_index + 1}
                              </h5>
                            </InputLabel>
                            <TextField
                              required={true}
                              size="small"
                              value={
                                question[question_index].options[option_index]
                              }
                              className={`w-100`}
                              InputProps={{
                                style: {
                                  borderRadius: 5,
                                },
                              }}
                              placeholder="Option"
                              variant="outlined"
                              onChange={handleChangeOption(
                                question_index,
                                option_index
                              )}
                            />
                          </Grid>
                        </React.Fragment>
                      )
                    )
                  : null}
              </Grid>
            </Card>
          ))
        : ""}
    </div>
  );
};

export default DetailSurvey;
