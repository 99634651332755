import SignUp from "./SignUp";
import SignIn from "./SignIn";
import NotFound from "./NotFound";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import Register from "./Register";
import ConfirmPassword from "./ConfirmPassword";
const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false
    },
    leftSidebar: {
      show: false,
      mode: "close"
    }
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false
    },
    navbar: { show: false }
  },
  secondarySidebar: { show: false },
  footer: { show: false }
};

const sessionRoutes = [
  // {
  //   path: "/session/signup",
  //   component: SignUp,
  //   settings
  // },
  // {
  //   path: "/register",
  //   component: Register,
  //   settings
  // },
  {
    path: "/login",
    // path: "/session/signin",
    component: Login,
    // component: SignIn,
    settings
  },
  {
    path: "/register",
    // path: "/session/signin",
    component: Register,
    // component: SignIn,
    settings
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    settings
  },
  {
    path: "/confirm-password",
    component: ConfirmPassword,
    settings
  },
  {
    path: "/session/404",
    component: NotFound,
    settings
  },
];

export default sessionRoutes;
