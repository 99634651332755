/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Grid,
  Hidden,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Box,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuActions from "app/components/button/MenuActions";
import GeneralButton from "app/components/GeneralButton";
import { AddCircleOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import {
  deleteKandidat,
  getDataKandidatLain,
} from "app/redux/actions/PengaturanActions";
import Swal from "sweetalert2";
import {
  showConfirmation,
  showError,
  showSuccess,
} from "app/components/sweetalertService";

const KandidatLain = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: true,
    search: "",
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
    rowsPerPageOption: [5, 10, 50, 100],
  });

  const { dataKandidatLain } = useSelector((state) => state.pengaturan);

  const [search, setSearch] = useState("");
  const getData = (search = state.search) => {
    dispatch(getDataKandidatLain({ search }));
  };

  const handleSearch = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      getData(search);
    }
  };

  const setRowsPerPage = (event) => {
    const numRowsPerPage = parseInt(event.target.value);
    const newPage = 0;
    setState({
      ...state,
      rowsPerPage: numRowsPerPage,
      page: newPage,
      loading: true,
    });
    getData();
  };

  const setPage = (page) => {
    setState({
      ...state,
      page,
      loading: true,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = async (code) => {
    const confirmed = await showConfirmation("Hapus", "Apakah kamu yakin ?");

    if (confirmed) {
      try {
        deleteKandidat({ kandidat_code: code }).then((res) => {
          showSuccess("Data berhasil dihapus!");
          getData();
        });
      } catch (error) {
        showError("Data gagal dihapus !");
      }
    } else {
      showError("Data gagal dihapus !");
    }

    // Swal.fire({
    //   title: "Hapus",
    //   text: "Apakah kamu yakin",
    //   showCancelButton: true,
    //   confirmButtonText: "Yakin",
    //   cancelButtonText: "Batal",
    //   icon: "warning",
    //   buttonsStyling: false,
    //   reverseButtons: true,
    //   customClass: {
    //     confirmButton: "custom-success-button",
    //     cancelButton: "custom-error-button",
    //   },
    // }).then((res) => {
    //   if (res.isConfirmed) {
    //     deleteKandidat({ kandidat_code: code })
    //       .then((res) => {
    //         Swal.fire({
    //           title:
    //             '<div class="custom-swal-title">Data berhasil dihapus</div>',
    //           icon: "success",
    //           buttonsStyling: false,
    //           customClass: {
    //             confirmButton: "custom-success-button",
    //           },
    //         });
    //         getData();
    //       })
    //       .catch((err) => {
    //         Swal.fire({
    //           title: '<div class="custom-swal-title">Data gagal dihapus!</div>',
    //           icon: "error",
    //           buttonsStyling: false,
    //           customClass: {
    //             confirmButton: "custom-error-button",
    //           },
    //         });
    //       });
    //   }
    // });
  };

  useEffect(() => {
    getData();
  }, [state.page, state.rowsPerPage]);

  useEffect(() => {
    if (dataKandidatLain.length > 0) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
    }
  }, [dataKandidatLain]);

  return (
    <>
      <Card className="w-100 overflow-auto p-24" elevation={6}>
        <Grid
          container
          spacing={3}
          className="mb-2 d-flex align-items-center justify-content-between"
        >
          {/* Mobile Layout */}
          <Hidden smUp>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Link to="/pengaturan/kandidat_lain/tambah">
                <GeneralButton
                  variant="contained"
                  color="primary"
                  text="Tambah Baru"
                  icon={<AddCircleOutline fontSize="small" />}
                />
              </Link>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={9} md={9} lg={9}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  size="small"
                  variant="outlined"
                  color="primary"
                  className="items-center background-white w-full"
                  placeholder={"Cari"}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  onKeyDown={handleSearch}
                  name="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon color="disabled">search</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Desktop Layout */}
          <Hidden xsDown>
            <Grid item xs={12} sm className="flex flex-end">
              <Link to="/pengaturan/kandidat_lain/tambah">
                <GeneralButton
                  variant="contained"
                  color="primary"
                  text="Tambah Baru"
                  icon={<AddCircleOutline fontSize="small" />}
                />
              </Link>
            </Grid>
          </Hidden>
        </Grid>

        <TableContainer>
          <Table style={{ minWidth: 700 }}>
            <TableHead className="t-header">
              <TableRow>
                <TableCell align="center" colSpan={1}>
                  No
                </TableCell>
                <TableCell align="left" colSpan={3}>
                  Nama Kandidat
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  Nomor Kandidat
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  Partai
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  Hitung mundur pemilu
                </TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="t-body">
              {state.loading ? (
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    Loading ...
                  </TableCell>
                </TableRow>
              ) : dataKandidatLain && dataKandidatLain.length > 0 ? (
                dataKandidatLain
                  .slice(
                    state.page * state.rowsPerPage,
                    state.page * state.rowsPerPage + state.rowsPerPage
                  )
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="px-0" align="center">
                        {state.page * state.rowsPerPage + (index + 1)}
                      </TableCell>
                      <TableCell className="px-0" align="left" colSpan={3}>
                        {item.kandidat_nama}
                      </TableCell>
                      <TableCell className="px-0" align="center" colSpan={2}>
                        {item.kandidat_nomor_urut}
                      </TableCell>
                      <TableCell className="px-0" align="center" colSpan={2}>
                        {item.partai_nama}
                      </TableCell>
                      <TableCell className="px-0" align="center" colSpan={2}>
                        {item.kandidat_hitung_mundur_format}
                      </TableCell>
                      <TableCell>
                        <MenuActions
                          editPath={`/pengaturan/kandidat_lain/edit/${item.kandidat_code}`}
                          deleteActions={() => handleDelete(item.kandidat_code)}
                        />
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    Data kosong
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <div style={{ minWidth: 700 }}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" flexDirection="row" p={1} alignItems="center">
                <Box p={1}>
                  <div className="flex">
                    <p>Rows per page : </p>
                    <select
                      labelid="simple-select"
                      value={state.rowsPerPage}
                      name="rowsPerPage"
                      onChange={setRowsPerPage}
                      variant="standard"
                      className="border-none"
                    >
                      {state.rowsPerPageOption.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </Box>
              </Box>
              <Box>
                <TablePagination
                  className="px-16"
                  rowsPerPageOptions={[]}
                  component="div"
                  count={dataKandidatLain?.length || -1}
                  rowsPerPage={state.rowsPerPage}
                  labelRowsPerPage={""}
                  page={state.page}
                  backIconButtonProps={{
                    "aria-label": "Previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next page",
                  }}
                  backIconButtonText="Previous page"
                  nextIconButtonText="Next page"
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={setRowsPerPage}
                />
              </Box>
            </Box>
          </div>
        </TableContainer>
      </Card>
    </>
  );
};

export default KandidatLain;
