import {
  Button,
  Card,
  Chip,
  FormControl,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { getDataSurvey } from "app/redux/actions/SurveyActions";
import React, { Fragment, useEffect, useState, useLayoutEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import AddIcon from "@material-ui/icons/Add";
import MenuActions from "./components/MenuActions";
import GeneralButton from "app/components/GeneralButton";
import { AddCircleOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";

const Survey = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: true,
    search: "",
    searchTgl: 0,
    page: 0,
    rowsPerPage: 10,
    rowsPerPageOption: [5, 10, 50, 100],
  });

  const { dataSurvey } = useSelector((state) => state.dataSurvey);

  const getData = () => {
    dispatch(getDataSurvey());
  };

  const setRowsPerPage = (event) => {
    console.log(event);
    setState({
      ...state,
      rowsPerPage: event.target.value,
    });
  };

  const setPage = (page) => {
    setState({
      ...state,
      page,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    getData();
    console.log("state.data_checked: ", dataSurvey);
  }, []);

  console.log(dataSurvey);

  return (
    <div className="p-40">
      <div className="mb-sm-30">
        <h2 className="font-size-32 fw-700 fw-bold">Survey</h2>
      </div>
      <Card className="w-100 overflow-auto p-24" elevation={6}>
        <Grid
          container
          spacing={3}
          className="mb-4 px-2"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item lg={9} md={9}>
            <Grid container spacing={3}>
              <Grid item lg={6} md={6} className="p-0">
                <TextField
                  size="small"
                  variant="outlined"
                  color="primary"
                  className="items-center background-white w-full"
                  placeholder={"Cari"}
                  // onChange={(e) => setSearch(e.target.value)}
                  // value={search}
                  // onKeyDown={submitSearch}
                  name="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon color="disabled">search</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6}>
                {/*  */}
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} md={3} className="pr-1">
            <Grid item xs={12} sm className="flex flex-end">
              <Link to="/survey/tambah">
                <GeneralButton
                  variant="contained"
                  color="primary"
                  text="Tambah Survey"
                  icon={<AddCircleOutline fontSize="small" />}
                />
              </Link>
            </Grid>
          </Grid>
        </Grid>

        <TableContainer>
          <Table className="t-container">
            <TableHead className="t-header">
              <TableRow>
                <TableCell align="center" colSpan={1}>
                  No
                </TableCell>
                <TableCell align="left" colSpan={2}>
                  Judul
                </TableCell>
                <TableCell align="center">Catatan</TableCell>
                <TableCell align="center" colSpan={2}>
                  Status
                </TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="t-body">
              {dataSurvey.length > 0 ? (
                dataSurvey
                  .slice(
                    state.page * state.rowsPerPage,
                    state.page * state.rowsPerPage + state.rowsPerPage
                  )
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="px-0" align="center">
                        {index + 1}
                      </TableCell>
                      <TableCell className="px-0" align="left" colSpan={2}>
                        {item.judul}
                      </TableCell>
                      <TableCell className="px-0" align="center">
                        {item.catatan}
                      </TableCell>
                      <TableCell className="px-0" align="center" colSpan={2}>
                        <Chip label={item.status} style={{padding: '2px 10px', fontSize: '12px', color: "#FFF"}} className={`${item.status == "Aktif" ? "bg-green-50" : "bg-red-50"}`} />
                      </TableCell>
                      <TableCell>
                        <MenuActions detailPath="/survey/detail" editPath="/survey/edit"/>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    Data kosong
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Grid container justify="space-between" alignItems="center">
            <div className="flex">
              <p>Rows per page : </p>
              <select
                labelid="simple-select"
                value={state.rowsPerPage}
                name="rowsPerPage"
                onChange={setRowsPerPage}
                variant="standard"
                className="border-none"
              >
                {state.rowsPerPageOption.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <TablePagination
              className="px-16"
              rowsPerPageOptions={[]}
              component="div"
              count={dataSurvey.length}
              rowsPerPage={state.rowsPerPage}
              labelRowsPerPage={""}
              page={state.page}
              backIconButtonProps={{
                "aria-label": "Previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next page",
              }}
              backIconButtonText="Previous page"
              nextIconButtonText="Next page"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={setRowsPerPage}
            />
          </Grid>
        </TableContainer>
      </Card>
    </div>
  );
};

export default Survey;
