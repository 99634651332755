// import { MatxLoadable } from "matx";
// import { authRoles } from "../../auth/authRoles";
import Statistik from "./Statistik";

const StatistikRoutes = [
  {
    path: "/statistik",
    component: Statistik,
    // auth: authRoles.all,
    // exact: true,
  },
];

export default StatistikRoutes;
