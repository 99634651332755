import { API, setAuthToken } from "config/API.js";

export function register(params) {
    return API.post('register', params);
}
export function sendVerifyEmail(email, token, expires, bearer) {
    setAuthToken(bearer);
    return API.get(`email/verify/${email}/${token}/${expires}`);
}

export const updateProfile = (params) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    return API.post("/user/edit/profile", params);
  };

export function checkTokenReset(params) {
    return API.post(`forgot_password/check_token`, params);
}

export function sendResetPassword(params) {
    return API.post(`forgot_password`, params);
}

export function confirmPassword(token, params) {
    return API.post(`forgot_password/next?token=${token}`, params);
}