import { API, setAuthToken } from 'config/API.js'
import {
  GET_LOGISTIK,
  GET_PEMAKAIAN,
  GET_CATEGORY_LOGISTIK,
  GET_PESANAN
} from '../constant.js'

export const getKategoriLogistik = (params = '') => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`/user/kategori/logistik` + params)
      .then(res => {
        dispatch({
          type: GET_CATEGORY_LOGISTIK,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_CATEGORY_LOGISTIK,
          payload: []
        })
      })
  }
}

export const getDataLogistik = (params = '') => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    return API.get(`/user/logistik?${new URLSearchParams(params)}`)
      .then(res => {
        dispatch({
          type: GET_LOGISTIK,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_LOGISTIK,
          payload: []
        })
      })
  }
}

export const addLogistik = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/user/logistik/add', body)
}

export const updateLogistik = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/user/logistik/update', body)
}

export const deleteLogistik = code => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.delete('/user/logistik/delete/' + code)
}

export const getDataPemakaian = (params = '') => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    return API.get(`/user/pemakaian?${new URLSearchParams(params)}`)
      .then(res => {
        dispatch({
          type: GET_PEMAKAIAN,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_PEMAKAIAN,
          payload: []
        })
      })
  }
}

export const addPemakaian = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/user/pemakaian/add', body)
}

export const updatePemakaian = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/user/pemakaian/update', body)
}

export const deletePemakaian = code => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.delete('/user/pemakaian/delete/' + code)
}

export const getDataPesanan = (params = '') => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    return API.get(`/user/pesanan?${new URLSearchParams(params)}`)
      .then(res => {
        dispatch({
          type: GET_PESANAN,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_PESANAN,
          payload: []
        })
      })
  }
}

export const addPesanan = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/user/pesanan/add', body)
}

export const updatePesanan = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/user/pesanan/update', body)
}

export const updateStatusPesanan = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/user/pesanan/status', body)
}

export const deletePesanan = code => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.delete('/user/pesanan/delete/' + code)
}
