import {
    GET_WILAYAH
} from "../constant.js";

const initialState = {
    dataWilayah: [],
};

const WilayahReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_WILAYAH: {
            return {
                ...state,
                dataWilayah: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default WilayahReducer;
