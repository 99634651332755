/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {
  Dialog,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  TextField
} from '@material-ui/core'
import { useDispatch, connect } from 'react-redux'
import { getKategoriArusKas } from 'app/redux/actions/ArusKasActions'
import { useEffect } from 'react'
// import GeneralButton from 'app/components/GeneralButton'
import { CalendarIcon } from 'app/components/Icons'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import InputAdornment from '@material-ui/core/InputAdornment'
import GreenButton from 'app/components/GreenButton'
// import dayjs from 'dayjs'

const ModalFilter = ({
  open,
  handleClose,
  kategori,
  handleClickOpen,
  handleSubmit,
  handleSearchChange,
  handleSearchChangeTgl,
  state,
  setState,
  value
}) => {
  const dispatch = useDispatch()

  const getData = () => {
    dispatch(getKategoriArusKas())
  }

  useEffect(() => {
    getData()
  }, [])

  const color = '#7FFFD4'

  return (
    <Dialog open={open} maxWidth={'xs'} onClose={handleClose}>
      <div className='p-4'>
        <h2 className='fs-24 mb-20 fw-600'>Silahkan Pilih Filter</h2>
        <Grid className='mb-16' container spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h2 className='fs-16 fw-700 '>Tanggal</h2>
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <InputLabel htmlFor='name'>
              <h5 className='fs-12 text-muted fw-400'>Tanggal</h5>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <div style={{ display: 'flex' }}>
                  <DatePicker
                    className={`w-100`}
                    size='small'
                    required={true}
                    inputFormat='DD/MM/YYYY'
                    variant='outlined'
                    onChange={handleSearchChangeTgl}
                    components={{
                      OpenPickerIcon: CalendarIcon
                    }}
                    views={['year', 'month', 'day']}
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          sx={{
                            label: { color }
                          }}
                        />
                      )
                    }}
                    format='DD/MM/YYYY'
                  // label='Tanggal'
                  />
                </div>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={6} />

          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h2 className='fs-16 fw-700 '>Kategori</h2>
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <InputLabel htmlFor='filter_kategori'>
              <h5 className='fs-12 text-muted fw-400'>Kategori</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                  backgroundColor: '#FBFBFB'
                }
              }}
              size='small'
              labelId='filter_kategori'
              variant='outlined'
              className='w-100'
              onChange={handleSearchChange}
              value={value.filter_kategori}
              name='filter_kategori'
              displayEmpty
            >
              <MenuItem key={'x'} value={''}>
                Pilih Kategori
              </MenuItem>
              {kategori.map((item, index) => (
                <MenuItem key={index} value={item.kategori_arus_kas_code}>
                  {/* <MenuItem key={index} value={item.kategori_arus_kas_nama}> */}
                  {item.kategori_arus_kas_nama}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <Grid className='mb-16' container spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h2 className='fs-16 fw-700'>Total Harga</h2>
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <InputLabel htmlFor='name'>
              <h5 className='fs-12 text-muted fw-400'>Min</h5>
            </InputLabel>
            <TextField
              required={true}
              size='small'
              labelid='filter_minTotal'
              onChange={handleSearchChange}
              value={value.filter_minTotal}
              name='filter_minTotal'
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5
                },
                min: 0,
                startAdornment: (
                  <InputAdornment position='start' style={{ fontSize: 16 }}>
                    Rp
                  </InputAdornment>
                )
              }}
              placeholder='0'
              variant='outlined'
              type='number'
            />
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <InputLabel htmlFor='name'>
              <h5 className='fs-12 text-muted fw-400'>Max</h5>
            </InputLabel>
            <TextField
              required={true}
              size='small'
              labelid='filter_maxTotal'
              onChange={handleSearchChange}
              value={value.filter_maxTotal}
              name='filter_maxTotal'
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5
                },
                min: 0,
                startAdornment: (
                  <InputAdornment position='start' style={{ fontSize: 16 }}>
                    Rp
                  </InputAdornment>
                )
              }}
              placeholder='0'
              variant='outlined'
              type='number'
            />
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6} className='mt-4'>
            <GreenButton
              variant='outlined'
              text='Batal'
              //   onClick={handleClickOpen}
              onClick={handleClose}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6} className='mt-4'>
            <GreenButton
              variant='contained'
              text='Terapkan'
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

const mapStateToProps = state => {
  return {
    kategori: state.arusKas.kategori
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getKategoriArusKas: () => dispatch(getKategoriArusKas())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalFilter)
