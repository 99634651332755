import { authRoles } from "app/auth/authRoles";
import { MatxLoadable } from "matx";

const Laporan = MatxLoadable({
  loader: () => import("./Laporan"),
});
const AddLaporan = MatxLoadable({
  loader: () => import("./AddLaporan"),
});
const EditLaporan = MatxLoadable({
  loader: () => import("./EditLaporan"),
});
const DetailLaporan = MatxLoadable({
  loader: () => import("./DetailLaporan"),
});

const LaporanRoutes = [
  {
    path: "/laporan",
    exact: true,
    auth: authRoles.admin,
    component: Laporan,
  },
  {
    path: "/laporan/tambah",
    exact: true,
    auth: authRoles.admin,
    component: AddLaporan,
  },
  {
    path: "/laporan/edit/:laporan_code",
    exact: true,
    auth: authRoles.admin,
    component: EditLaporan,
  },
  {
    path: "/laporan/detail/:laporan_code",
    exact: true,
    auth: authRoles.admin,
    component: DetailLaporan,
  },
];

export default LaporanRoutes;
