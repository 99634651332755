import React, { useState, useEffect } from "react";
import { Card, CardContent, CardMedia, Grid, Typography, Paper, Box } from "@material-ui/core";
import { useLocation, useHistory, Link } from "react-router-dom";
import { formatCurrency } from "helpers/formatCurrency";
import { ContentCopy } from "@mui/icons-material";
import { getDataPembayaran, getTotalPembayaran } from "app/redux/actions/PembayaranActions";
import { connect, useDispatch, useSelector } from "react-redux";

// import NavTabs from "app/components/fragment/NavTabs";
const Pembayaran = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const { dataPembayaran, totalPembayaran } = useSelector(
        (state) => state.pembayaran
    );

    const getData = () => {
        dispatch(getDataPembayaran());
        dispatch(getTotalPembayaran());
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="p-40">
            <div className="mb-sm-30">
                <h2 className="fw-700 fw-bold">Pembayaran</h2>
            </div>

            <Card className="w-100 overflow-auto p-24">
                <Grid container spacing={3} className="mb-4">
                    <Grid item lg={12} md={12}>
                        <Paper elevation={3} square>
                            <Card
                                className={"p-12"}
                                style={{ borderRadius: "41.5px !important" }}
                            >
                                <Box
                                    component="div"
                                    className="d-flex flex-column justify-content-center align-items-center py-4 "
                                >
                                    <h2 className="fs-500" style={{ fontSize: 28, color: "#2d3142" }}>Tagihan pembelian paket berlangganan Aplikasi Pemilu Now.</h2>
                                    <Typography className="fs-500 fw-bold mb-2" variant="p" style={{ fontSize: 32, color: "#00ACEE" }}>
                                        {formatCurrency(totalPembayaran?.subscription_nominal)}
                                    </Typography>
                                </Box>
                            </Card>
                        </Paper>
                    </Grid>
                </Grid>

                <Typography className="fs-500 mt-2" variant="h6" style={{ fontSize: 18, marginBottom: "15px" }}>
                    Pilih metode pembayaran melalui bank berikut:
                </Typography>

                <Grid container spacing={3}>
                    {dataPembayaran?.map((item, index) => (
                        <Grid item key={index} lg={4} md={6} sm={12} xs={12}>
                            <Paper elevation={3} square>
                                <Card
                                    className={"p-12 cursor-pointer"}
                                    style={{ borderRadius: "41.5px !important" }}
                                >
                                    <Box
                                        component="div"
                                        className="d-flex justify-content-center align-items-center py-4 "
                                    >
                                        <Link to={`/pembayaran/detail/${item.bank_code}`}>
                                            <img src={item.bank_image} alt={item.bank_name} height={65} />
                                        </Link>
                                    </Box>
                                </Card>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Card>

        </div>
    );
};

export default Pembayaran;
