import React from "react";

const Filter = ({ color = "#C2C2C2" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_1568_36450)">
      <path
        d="M14.6667 1.77777H1.33336C1.21549 1.77777 1.10244 1.8246 1.01909 1.90795C0.935741 1.9913 0.888916 2.10434 0.888916 2.22222V2.96444C0.888999 3.06941 0.909856 3.17333 0.950283 3.2702C0.990711 3.36708 1.04991 3.45499 1.12447 3.52888L6.22225 8.70222V13.2355L7.11114 13.5733V8.44444C7.11148 8.38595 7.10027 8.32796 7.07815 8.27381C7.05603 8.21966 7.02344 8.17041 6.98225 8.12888L1.7778 2.92888V2.66666H14.2222V2.93777L9.03558 8.12888C8.99117 8.16891 8.95529 8.21749 8.93008 8.27171C8.90488 8.32593 8.89088 8.38468 8.88892 8.44444V14.3155L9.77781 14.6667V8.66666L14.8756 3.55555C14.9513 3.47978 15.0112 3.38965 15.0517 3.29043C15.0921 3.19121 15.1123 3.08491 15.1111 2.97777V2.22222C15.1111 2.10434 15.0643 1.9913 14.981 1.90795C14.8976 1.8246 14.7846 1.77777 14.6667 1.77777Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_1568_36450">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Filter;
