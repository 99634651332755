import * as React from "react";
import { Button } from "@material-ui/core";
import { UsersThree } from "@phosphor-icons/react";

const TabButton = ({
  title = "Button",
  onClick = "",
  selected = false,
  icon = "",
  otherClass = "",
}) => {
  return (
    <Button
      className={
        "p-12 btntab d-flex align-items-center " +
        (selected ? "active" : "") +
        " " +
        otherClass
      }
      variant={selected ? "contained" : "outlined"}
      onClick={onClick}
    >
      <div>
        {icon ? icon : <UsersThree style={{ fontSize: 14, marginRight: 5 }} />}
      </div>
      <div style={{ fontSize: "14px" }}>{title}</div>
    </Button>
  );
};

export default TabButton;
