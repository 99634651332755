import React from "react";
import { Card, CardContent, Grid } from "@mui/material";

const CardTotalRelawan = ({
  totalJumlah,
  cardTitle,
  className,
  icon: Icon,
  iconSize = 20,
}) => {
  return (
    <Card style={{ padding: "12px 0", height: "100%", borderRadius: "10px" }}>
      <CardContent>
        <Grid container wrap="nowrap" className="flex align-items-center">
          <Grid item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "42px",
                width: "42px",
                backgroundColor: "#E5F8FF",
                color: "#00ACEE",
                borderRadius: "50%",
                marginRight: "10px",
              }}
            >
              <Icon style={{ fontSize: `${iconSize}px` }} />
            </div>
          </Grid>
          <Grid item>
            <h3 className="fw-500 fs-5">{cardTitle}</h3>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="mt-1">
          <Grid item>
            <h2 className={`fs-1 fw-700 fw-bold ${className}`}>
              {totalJumlah || 0}
              <span className="fs-6">Org</span>
            </h2>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CardTotalRelawan;
