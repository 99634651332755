function formatCurrency (amount) {
  if (amount === undefined) {
    return 'Rp. 0'
  }

  if (typeof amount !== 'number') {
    const amountString = amount.replace(/[^0-9]/g, '') // Remove non-numeric characters and commas
    const amountInt = parseFloat(amountString)
    const formattedAmount = amountInt.toLocaleString('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    return formattedAmount.replace('IDR', '')
  }

  const formattedAmount = amount.toLocaleString('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  return formattedAmount.replace('IDR', '')
}

function rawCurrency (amount) {
  //   if (typeof formattedValue === 'string') {
  //   const numericValue = amount.replace(/[^\d]/g, '')
  const numericValue = amount.replace(/\D/g, '')

  return parseInt(numericValue, 10)
  //   return parseFloat(numericValue)

  //   } else {
  // return NaN
  //   }
}

export { formatCurrency, rawCurrency }
