import axios from "axios";
import jwtDecode from "jwt-decode";
import localStorageService from "./localStorageService";
import { API, setAuthToken } from "config/API";

class apiAuthService {
  loginWithEmailAndPassword = (usersname, password, token) => {
    const params = { usersname, password, token };
    return API.post("/login", params).then((response) => {
      const data = response.data.data;
      this.setSession(data.token);
      this.setUser(data);
      return data;
    });
  };

  // You need to send http requst with existing token to your server to check token is valid
  // This method is being used when user logged in & app is reloaded
  loginWithToken = (token) => {
    setAuthToken(token);
    return API.post("check/token").then((response) => {
      this.setSession(token);
      this.setUser(response.data.data);
      return response.data.data;
    });
  };

  // loginWithToken = () => {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       resolve(this.user);
  //     }, 100);
  //   }).then(data => {
  //     // Token is valid
  //     this.setSession(data.token);
  //     this.setUser(data.token);
  //     return data;
  //   });
  // };

  logout = (token) => {
    setAuthToken(token);
    return API.post('user/logout')
      .then((response) => {
        this.setSession(null);
        this.removeUser();
        return response.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };

  // Set token to all http request header, so you don't need to attach everytime
  setSession = (token) => {
    if (token) {
      localStorage.setItem('jwt_token', token)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    } else {
      localStorage.removeItem('jwt_token')
      delete axios.defaults.headers.common['Authorization']
    }
  }

  // Save user to localstorage
  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  };

  setSession = (token) => {
    if (token) {
      localStorage.setItem('jwt_token', token);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    } else {
      localStorage.removeItem('jwt_token');
      delete axios.defaults.headers.common['Authorization'];
    }
  };

  // Remove user from localstorage
  removeUser = () => {
    localStorage.removeItem("auth_user");
  };
}

export default new apiAuthService()
