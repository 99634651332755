import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect } from "react";

const TableKecamatan = ({ data, search, state, queryParams }) => {
  useEffect(() => {
    //
  }, []);

  const getTableRowNumber = (index) => {
    return state.page * queryParams.limit + index + 1;
  };

  return (
    <Table style={{ minWidth: 700 }}>
      <TableHead className="t-header">
        <TableRow>
          <TableCell align="center">No</TableCell>
          <TableCell align="left" colSpan={2}>
            Nama Daerah Kecamatan
          </TableCell>
          <TableCell align="center">Suara Caleg</TableCell>
          <TableCell align="center">Suara Partai</TableCell>
        </TableRow>
      </TableHead>
      <TableBody className="t-body">
        {state.loading ? (
          <TableRow>
            <TableCell colSpan={5} align="center">
              Loading...
            </TableCell>
          </TableRow>
        ) : data.filter((item) =>
            item?.kecamatan?.toLowerCase().includes(search.toLowerCase())
          ).length > 0 ? (
          data
            .filter((item) =>
              item?.kecamatan?.toLowerCase().includes(search.toLowerCase())
            )
            .map((item, index) => (
              <TableRow key={index}>
                <TableCell className="px-0" align="center">
                  {getTableRowNumber(index)}
                </TableCell>
                <TableCell className="px-0 capitalize" align="left" colSpan={2}>
                  {item.kecamatan}
                </TableCell>
                <TableCell className="px-0" align="center">
                  {item.suara_caleg}
                </TableCell>
                <TableCell className="px-0" align="center">
                  {item.suara_partai}
                </TableCell>
              </TableRow>
            ))
        ) : (
          <TableRow>
            <TableCell colSpan={5} align="center">
              Data kosong
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default TableKecamatan;
