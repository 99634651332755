import { GET_TOTAL_DPT, GET_ADMIN_CONTACT } from "../constant.js";

const initialState = {
  dataDPT: {
    total_dpt: 1500,
    total_dpt_pria: 750,
    total_dpt_wanita: 750
  },
  dataAdminContact: {
    contact_phone: "08192732367238",
    contact_email: "adminpemilu@pemilunow.com"
  }
};

const OtherReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_TOTAL_DPT: {
      return {
        ...state,
        dataDPT: action?.payload.data || {},
      };
    }
    case GET_ADMIN_CONTACT: {
      return {
        ...state,
        dataAdminContact: action?.payload.data || {},
      };
    }
    default: {
      return state;
    }
  }
};

export default OtherReducer;
