import {
  GET_RELAWAN,
  GET_DETAIL_RELAWAN,
  GET_DETAIL_LOGISTIK_KAMPANYE_RELAWAN,
  GET_RELAWAN_STATISTIC,
  GET_GRAFIK_RELAWAN_DAILY,
  GET_GRAFIK_RELAWAN_MONTHLY,
} from '../constant.js'

const initialState = {
  dataRelawan: [],
  detailRelawan: {},
  dataRelawanPagination: {},
  relawanlogistikPagination: {},
  relawanStatistic: [],
  grafikRelawanDaily: [],
  grafikRelawanMonthly: []
}

const RelawanReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_RELAWAN: {
      return {
        ...state,
        dataRelawan: action?.payload.data || [],
        dataRelawanPagination: action?.payload.pagination || []
      }
    }
    case GET_DETAIL_RELAWAN: {
      return {
        ...state,
        detailRelawan: action?.payload.data || {}
      }
    }
    case GET_DETAIL_LOGISTIK_KAMPANYE_RELAWAN: {
      return {
        ...state,
        detailLogistikKampanyeRelawan: action?.payload || [],
        relawanlogistikPagination: action?.payload.pagination || {}
      }
    }
    case GET_RELAWAN_STATISTIC: {
      return {
        ...state,
        relawanStatistic: action?.payload.data || []
      }
    }
    case GET_GRAFIK_RELAWAN_DAILY: {
      return {
        ...state,
        grafikRelawanDaily: action?.payload.data || []
      }
    }
    case GET_GRAFIK_RELAWAN_MONTHLY: {
      return {
        ...state,
        grafikRelawanMonthly: action?.payload.data || []
      }
    }
    default: {
      return state
    }
  }
}

export default RelawanReducer
