/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import CardTotalRelawan from "app/components/card/CardTotalRelawan";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getGrafikStatistikPemilihDaily,
  getGrafikStatistikPemilihMonthly,
  getStatisticPemilih,
} from "app/redux/actions/StatisticActions";
import ChartStatisticPemilih from "./components/ChartStatisticPemilih";
import { Users } from "@phosphor-icons/react";
import DoughnutChart from "./components/DoughnutChart";
import { Card } from "@material-ui/core";
import { getTotalDPT } from "app/redux/actions/OtherActions";
import { useStatistikPemilih } from "./hooks/useStatistik";
import { ca } from "date-fns/locale";

const StatistikPemilih = ({
  selectedFilter,
  setSelectedFilter,
  setSelectedYear,
  filterDataGrafik,
}) => {
  const {
    calonPemilihStatistik,
    dptStatistik,
    dataGrafikPemilihDaily,
    dataGrafikPemilihMonthly,
  } = useStatistikPemilih();

  // const dispatch = useDispatch();

  // const tabTexts = {
  //   title: "Warga Binaan",
  //   card_title_1: "Total Calon Pemilih",
  //   card_title_2: "Calon Pemilih Pria",
  //   card_title_3: "Calon Pemilih Wanita",
  //   card_title_4: "Total Calon Pemilih Pasti Memilih",
  //   card_title_5: "Total Calon Pemilih Ragu Ragu",
  //   card_title_6: "Total Calon Pemilih Tidak Memilih",
  //   card_title_7: "Total DPT Dapil",
  //   card_title_8: "Total DPT Pria",
  //   card_title_9: "Total DPT Wanita",
  // };

  // const dptDapil = {
  //   totalDpt: 100,
  //   dptPria: 50,
  //   dptWanita: 50,
  // };

  // const {
  //   dataStatisticPemilih,
  //   dataGrafikPemilihDaily,
  //   dataGrafikPemilihMonthly,
  // } = useSelector((state) => state.statistic);

  // const { dataDPT } = useSelector((state) => state.other);

  // const getData = () => {
  //   dispatch(getStatisticPemilih());
  //   dispatch(getGrafikStatistikPemilihDaily());
  //   dispatch(getGrafikStatistikPemilihMonthly());
  //   // dispatch(getTotalDPT());
  // };

  // useEffect(() => {
  //   getData();
  //   console.log('dpt', dataDPT)
  // }, [dispatch]);

  return (
    <>
      {/* <div className="mb-3">
        <Grid container spacing={2} marginBottom="15px">
          <Grid item xs={12} lg={4} md={4} sm={12}>
            <CardTotalRelawan
              cardTitle={tabTexts.card_title_1}
              totalJumlah={dataStatisticPemilih.totalPemilih}
              icon={Users}
            />
          </Grid>
          <Grid item xs={12} lg={4} md={4} sm={12}>
            <CardTotalRelawan
              cardTitle={tabTexts.card_title_2}
              totalJumlah={dataStatisticPemilih.totalPemilihPria}
              icon={Users}
            />
          </Grid>
          <Grid item xs={12} lg={4} md={4} sm={12}>
            <CardTotalRelawan
              cardTitle={tabTexts.card_title_3}
              totalJumlah={dataStatisticPemilih.totalPemilihWanita}
              icon={Users}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginBottom="15px">
          <Grid item xs={12} lg={4} md={4} sm={12}>
            <CardTotalRelawan
              cardTitle={tabTexts.card_title_4}
              totalJumlah={dataStatisticPemilih.totalPemilihMemilih}
              className="text-green-50"
              icon={Users}
            />
          </Grid>

          <Grid item xs={12} lg={4} md={4} sm={12}>
            <CardTotalRelawan
              cardTitle={tabTexts.card_title_5}
              totalJumlah={dataStatisticPemilih.totalPemilihRagu}
              className="text-yellow"
              icon={Users}
            />
          </Grid>

          <Grid item xs={12} lg={4} md={4} sm={12}>
            <CardTotalRelawan
              cardTitle={tabTexts.card_title_6}
              totalJumlah={dataStatisticPemilih.totalPemilihTidakMemilih}
              className="text-red-50"
              icon={Users}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginBottom="15px">
          <Grid item xs={12} lg={4} md={4} sm={12}>
            <CardTotalRelawan
              cardTitle={tabTexts.card_title_7}
              totalJumlah={dataDPT.total_dpt}
              icon={Users}
            />
          </Grid>
          <Grid item xs={12} lg={4} md={4} sm={12}>
            <CardTotalRelawan
              cardTitle={tabTexts.card_title_8}
              totalJumlah={dataDPT.total_dpt_pria}
              icon={Users}
            />
          </Grid>
          <Grid item xs={12} lg={4} md={4} sm={12}>
            <CardTotalRelawan
              cardTitle={tabTexts.card_title_9}
              totalJumlah={dataDPT.total_dpt_wanita}
              icon={Users}
            />
          </Grid>
        </Grid>
      </div> */}

      <Grid container spacing={2} className="mb-3">
        {calonPemilihStatistik.map((item) => (
          <Grid item xs={12} lg={4} md={4} sm={4}>
            <CardTotalRelawan
              cardTitle={item.label}
              totalJumlah={item.value}
              icon={Users}
              className={item.color ?? ""}
            />
          </Grid>
        ))}
        {dptStatistik.map((item) => (
          <Grid item xs={12} lg={4} md={4} sm={4}>
            <CardTotalRelawan
              cardTitle={item.label}
              totalJumlah={item.value}
              icon={Users}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2} marginBottom="15px">
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <ChartStatisticPemilih
            dataGrafikPemilihDaily={dataGrafikPemilihDaily}
            dataGrafikPemilihMonthly={dataGrafikPemilihMonthly}
            selectedFilter={selectedFilter}
            onSelectFilter={setSelectedFilter}
            onSelectYear={setSelectedYear}
            getDataGrafik={filterDataGrafik}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} marginBottom="15px">
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Card className="w-100 overflow-auto p-24" elevation={6}>
            <div>
              <p className="text-grey">Grafik</p>
              <h2 className="fs-20 fw-600">Jumlah DPT Pria dan Wanita</h2>
            </div>
            <hr />
            <DoughnutChart
              height="250px"
              color={["#2EC5FF", "#BBFF92", "#B7EBFF"]}
              data={dptStatistik}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default StatistikPemilih;
