import {
  GET_KANDIDAT_LAIN,
  GET_PENGGUNA,
  GET_PARTAI,
  GET_KANDIDAT_MAIN,
  GET_DAPIL,
  GET_CALON_TETAP_SEBAGAI,
  GET_DETAIL_KANDIDAT,
  GET_DETAIL_PARTAI,
  GET_DETAIL_PENGGUNA,
  GET_DETAIL_CALON_TETAP,
} from "../constant.js";

const initialState = {
  detailPengguna: {},
  dataPengguna: [],
  dataPenggunaPaginate: {
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
  },
  dataKandidatLain: [],
  dataKandidatLainPaginate: [],
  partaiSekarang: [],
  partaiTerakhir: [],
  partaiTerakhirkandidat_main: {},
  dataDapil: [],
  dataCalonTetap: [],
  kandidat_main: {},
  detail_kandidat: {},
  detail_partai: {},
  detail_calon_tetap: {},
};

const PengaturanReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_PENGGUNA: {
      return {
        ...state,
        dataPengguna: action.payload.data,
        dataPenggunaPaginate: action.payload.pagination,
      };
    }
    case GET_DETAIL_PENGGUNA: {
      return {
        ...state,
        detailPengguna: action.payload,
      };
    }
    case GET_KANDIDAT_LAIN: {
      return {
        ...state,
        dataKandidatLain: action.payload.data,
        dataKandidatLainPaginate: action.payload.pagination,
      };
    }
    case GET_PARTAI: {
      return {
        ...state,
        partaiSekarang: action.payload.partai_sekarang,
        partaiTerakhir: action.payload.partai_terakhir,
      };
    }
    case GET_DETAIL_PARTAI: {
      return {
        ...state,
        detail_partai: action.payload,
      };
    }
    case GET_KANDIDAT_MAIN: {
      return {
        ...state,
        kandidat_main: action.payload,
      };
    }
    case GET_DETAIL_KANDIDAT: {
      return {
        ...state,
        detail_kandidat: action.payload,
      };
    }
    case GET_DAPIL: {
      return {
        ...state,
        dataDapil: action.payload,
      };
    }
    case GET_DETAIL_CALON_TETAP: {
      return {
        ...state,
        detail_calon_tetap: action.payload,
      };
    }
    case GET_CALON_TETAP_SEBAGAI: {
      return {
        ...state,
        dataCalonTetap: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default PengaturanReducer;
