import React, { Fragment } from "react";
import { Box, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import Kandidat from "./Kandidat";
import Pengguna from "./Pengguna/Pengguna";
import KandidatLain from "./KandidatLain/KandidatLain";
import { useLocation, useHistory } from "react-router-dom";
import NavTabs from "app/components/fragment/NavTabs";
import { User, UserPlus, Users } from "@phosphor-icons/react";

const Pengaturan = () => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const initialTab = params.get("tab") || "kandidat";

  const tabs = [
    {
      key: "kandidat",
      title: "Kandidat",
      path: "/pengaturan?tab=kandidat",
      icon: <User style={{ fontSize: 24, marginRight: 5 }} />,
    },
    {
      key: "pengguna",
      title: "Managemen Pengguna",
      path: "/pengaturan?tab=pengguna",
      icon: <UserPlus style={{ fontSize: 24, marginRight: 5 }} />,
    },
    {
      key: "kandidat_lain",
      title: "Kandidat Lain",
      path: "/pengaturan?tab=kandidat_lain",
      icon: <Users style={{ fontSize: 24, marginRight: 5 }} />,
    },
  ];

  return (
    <Fragment>
      <Box className="p-40">
        <h2 className="font-size-32 fw-700 fw-bold mb-sm-30">Pengaturan</h2>

        <Card className="w-100 mb-3">
          <NavTabs initialTab={initialTab} history={history} tabs={tabs} />
        </Card>

        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              {initialTab === "kandidat" && <Kandidat />}
              {initialTab === "pengguna" && <Pengguna />}
              {initialTab === "kandidat_lain" && <KandidatLain />}
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Fragment>
  );
};

export default Pengaturan;
