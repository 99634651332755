/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useHistory, Link } from "react-router-dom";
import InputFileImg from "app/components/Input/InputFileImg";
import GeneralButton from "app/components/GeneralButton";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  addKandidat,
  getDataCalonTetapSebagaiPublic,
  getDataDapilPublic,
  getDataPartaiPublic,
} from "app/redux/actions/PengaturanActions";
import { ArrowCircleLeft } from "@phosphor-icons/react";
import GreenButton from "app/components/GreenButton";
import { showError, showSuccess } from "app/components/sweetalertService";

const AddKandidatLain = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [state, setState] = useState({
    kandidat_images: "",
    kandidat_images_preview: "",
    kandidat_nama: "",
    kandidat_nomor_urut: "",
    kandidat_partai: "",
    kandidat_dapil: "",
    kandidat_tingkat_code: "",
    kandidat_hitung_mundur: new Date(),
  });

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [errorField, setErrorField] = useState({});

  const handleSubmit = () => {
    addKandidat(state)
      .then((res) => {
        showSuccess("Data berhasil disimpan");
        history.push("/pengaturan?tab=kandidat_lain");
      })
      .catch((err) => {
        showError("Data gagal disimpan!");
        setErrorField(err.response.data.data);
      });
  };

  const handleChangeFoto = (file, path) => {
    setState((prev) => ({
      ...prev,
      kandidat_images: file,
      kandidat_images_preview: path,
    }));
  };

  const handleDateChange = (date) => {
    setState((prev) => ({
      ...prev,
      kandidat_hitung_mundur: date,
    }));
  };

  useEffect(() => {
    dispatch(getDataPartaiPublic());
    dispatch(getDataDapilPublic());
    dispatch(getDataCalonTetapSebagaiPublic());
  }, []);

  const { partaiSekarang, dataDapil, dataCalonTetap } = useSelector(
    (state) => state.pengaturan
  );

  return (
    <div className="analytics m-sm-30 mt-7">
      <Grid
        container
        spacing={3}
        className="mb-2 d-flex justify-content-center align-items-center"
      >
        <Grid item lg={6} md={6} xs={12} sm={6}>
          <h2 className="font-size-32 fw-700 fw-bold">Tambah Kandidat Lain</h2>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          sm={6}
          spacing={2}
          container
          justify="flex-end"
        >
          <Grid item>
            <GreenButton
              text="Kembali"
              onClick={() => history.push("/pengaturan?tab=kandidat_lain")}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <GreenButton
              text="Simpan Data"
              onClick={handleSubmit}
              variant="contained"
            />
          </Grid>
        </Grid>
      </Grid>

      <div className="analytics mt-3">
        <Card style={{ padding: "20px", marginBottom: "20px" }}>
          <Grid container className="mt-2 p-2 " spacing={2}>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}
              className="d-flex flex-space-between"
            >
              <h3 className="fw-600 fs-24">Detail Kandidat</h3>
            </Grid>

            <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="image">
                <h5 className="font-size-14">Foto Kandidat</h5>
                <InputFileImg
                  noPadding={true}
                  name="kandidat_images"
                  uploadFoto={handleChangeFoto}
                  preview={state.kandidat_images_preview}
                  acceptFile="image"
                />
              </InputLabel>
              <small className="text-danger">
                {errorField.kandidat_images}
              </small>
            </Grid>

            <Grid item lg={6} md={6} xs={12} sm={12}>
              {/*  */}
            </Grid>

            <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="kandidat_nama">
                <h5 className="font-size-14">Nama Kandidat</h5>
              </InputLabel>
              <TextField
                required={true}
                size="small"
                value={state.kandidat_nama}
                name="kandidat_nama"
                className={`w-100`}
                InputProps={{
                  style: {
                    borderRadius: 5,
                  },
                }}
                placeholder="Nama Kandidat"
                variant="outlined"
                onChange={handleChange}
                type="text"
                error={errorField?.kandidat_nama !== ""}
                helperText={errorField?.kandidat_nama}
              />
            </Grid>

            <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="kandidat_nomor_urut">
                <h5 className="font-size-14">Nomor Kandidat</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="kandidat_nomor_urut"
                value={state.kandidat_nomor_urut}
                onChange={handleChange}
                variant="outlined"
                className="w-100"
                name="kandidat_nomor_urut"
                displayEmpty
              >
                <MenuItem value="">Pilih Nomor Kandidat</MenuItem>
                {[...Array(100).keys()]?.map((item) => (
                  <MenuItem value={item + 1}>{item + 1}</MenuItem>
                ))}
              </Select>
              <small className="text-danger">
                {errorField.kandidat_nomor_urut}
              </small>
            </Grid>

            <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="kandidat_partai">
                <h5 className="font-size-14">Partai</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="kandidat_partai"
                value={state.kandidat_partai}
                onChange={handleChange}
                variant="outlined"
                className="w-100"
                name="kandidat_partai"
                displayEmpty
              >
                <MenuItem value="">Pilih Partai</MenuItem>
                {partaiSekarang?.map((item) => (
                  <MenuItem value={item.partai_code}>
                    {item.partai_nama}
                  </MenuItem>
                ))}
              </Select>
              <small className="text-danger">
                {errorField.kandidat_partai}
              </small>
            </Grid>

            <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="kandidat_hitung_mundur">
                <h5 className="font-size-14">Hitung Mundur Pemilu</h5>
              </InputLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils} className="w-full">
                <DateTimePicker
                  margin="none"
                  id="mui-pickers-date"
                  inputVariant="outlined"
                  hiddenLabel
                  autoOk={true}
                  className="w-full"
                  name="kandidat_hitung_mundur"
                  value={state.kandidat_hitung_mundur}
                  onChange={handleDateChange}
                />
              </MuiPickersUtilsProvider>
              <small className="text-danger">
                {errorField.kandidat_hitung_mundur}
              </small>
            </Grid>
          </Grid>
        </Card>

        <Card style={{ padding: "20px", marginBottom: "20px" }}>
          <Grid container className="mt-2 p-2 " spacing={2}>
            <Grid item lg={12} md={12} xs={12} sm={12}>
              <h3 className="fw-600 fs-24">Jenis Pemilihan</h3>
            </Grid>
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="kandidat_dapil">
                <h5 className="font-size-14">Dapil</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="kandidat_dapil"
                value={state.kandidat_dapil}
                onChange={handleChange}
                variant="outlined"
                className="w-100"
                name="kandidat_dapil"
                displayEmpty
              >
                <MenuItem value="">Pilih Dapil</MenuItem>
                {dataDapil?.map((item) => (
                  <MenuItem value={item.dapil_code}>{item.dapil_nama}</MenuItem>
                ))}
              </Select>
              <small className="text-danger">{errorField.kandidat_dapil}</small>
            </Grid>
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <InputLabel htmlFor="kandidat_tingkat_code">
                <h5 className="font-size-14">Calon tetap sebagai:</h5>
              </InputLabel>
              <Select
                SelectDisplayProps={{
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 5,
                  },
                }}
                size="small"
                labelid="kandidat_tingkat_code"
                value={state.kandidat_tingkat_code}
                onChange={handleChange}
                variant="outlined"
                className="w-100"
                name="kandidat_tingkat_code"
                displayEmpty
              >
                <MenuItem value="">Pilih Calon Tetap Sebagai</MenuItem>
                {dataCalonTetap?.map((item) => (
                  <MenuItem value={item.tingkat_calon_tetap_code}>
                    {item.tingkat_calon_tetap_nama}
                  </MenuItem>
                ))}
              </Select>
              <small className="text-danger">
                {errorField.kandidat_tingkat_code}
              </small>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
};

export default AddKandidatLain;
