import {
    GET_CATEGORY_ARUS_KAS
} from "../constant.js";

const initialState = {
    dataKategoriArusKas: [],
};

const CategoryArusKasReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_CATEGORY_ARUS_KAS: {
            return {
                ...state,
                dataKategoriArusKas: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default CategoryArusKasReducer;
