import { API, setAuthToken } from "config/API.js";
import { GET_SURVEY } from "../constant.js";

export const getDataSurvey = (params = '') => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`/warga_binaan` + params)
      .then((res) => {
        dispatch({
          type: GET_SURVEY,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_SURVEY,
          payload: [],
        });
      });
  };
};