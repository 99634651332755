import React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "@material-ui/core";
import TouchRipple from "@material-ui/core/ButtonBase";
import MatxVerticalNavExpansionPanel from "./MatxVerticalNavExpansionPanel";
import { withStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";

const styles = (theme) => ({
  expandIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(90deg)",
  },
  collapseIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(0deg)",
  },
});

const MatxVerticalNav = () => {
  const navigations = useSelector((state) => state.navigations);
  // const { settings } = useSelector((state) => state.layout);
  // const { mode } = settings.layout1Settings.leftSidebar;
  // const classes = useStyles();
  // const state = useState({
  //   collapsed: false,
  // });
  // const handleClick = () => {
  //   setState({ collapsed: !state.collapsed });
  // };

  const renderLevels = (data) => {
    return data.map((item, index) => {
      if (item.children) {
        return (
          <MatxVerticalNavExpansionPanel item={item} key={index}>
            {this.renderLevels(item.children)}
          </MatxVerticalNavExpansionPanel>
        );
      } else {
        return (
          <NavLink
            key={index}
            to={item.path}
            onClick={(e) => item.disabled && e.preventDefault()}
            className="nav-item "
          >
            {/* <div style={{width: 5, height: 30, background: '#00ACEE', borderRadius: '0px 8px 8px 0px', marginRight: 20, marginTop: 10}} /> */}
            <TouchRipple key={item.name} name="child" className="w-100">
              {(() => {
                if (item.icon) {
                  return (
                    <Icon className="item-icon text-middle">{item.icon}</Icon>
                  );
                } else {
                  return (
                    <span className="item-icon icon-text">{item.iconText}</span>
                  );
                }
              })()}
              <span className="text-middle pl-20 item-text">{item.name}</span>
              <div className="mx-auto"></div>
              {item.badge && (
                <div
                  className={`badge bg-${item.badge.color} d-flex align-items-center fs-10 text-white`}
                >
                  {item.badge.value}
                </div>
              )}
            </TouchRipple>
          </NavLink>
        );
      }
    });
  };

  return <div className="navigation pt-10">{renderLevels(navigations)}</div>;
};

export default withStyles(styles)(MatxVerticalNav);
