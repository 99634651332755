import { authRoles } from "app/auth/authRoles";
import { MatxLoadable } from "matx";

const Relawan = MatxLoadable({
  loader: () => import("./Relawan"),
});

const AddVolunteer = MatxLoadable({
  loader: () => import("./AddVolunteer"),
});

const EditVolunteer = MatxLoadable({
  loader: () => import("./EditVolunteer"),
});

const DetailVolunteer = MatxLoadable({
  loader: () => import("./DetailVolunteer"),
});

const AddTeamManagement = MatxLoadable({
  loader: () => import("./AddTeamManagement"),
});

const EditTeamManagement = MatxLoadable({
  loader: () => import("./EditTeamManagement"),
});

const RelawanRoutes = [
  {
    path: "/tim_sukses",
    exact: true,
    auth: authRoles.admin,
    component: Relawan,
  },
  {
    path: "/tim_sukses/tambah",
    exact: true,
    auth: authRoles.admin,
    component: AddVolunteer,
  },
  {
    path: "/tim_sukses/edit/:relawan_code",
    exact: true,
    auth: authRoles.admin,
    component: EditVolunteer,
  },
  {
    path: "/tim_sukses/detail/:relawan_code",
    exact: true,
    auth: authRoles.admin,
    component: DetailVolunteer,
  },
  {
    path: "/team_management/tambah",
    exact: true,
    auth: authRoles.admin,
    component: AddTeamManagement,
  },
  {
    path: "/team_management/edit/:team_pemenangan_code",
    exact: true,
    auth: authRoles.admin,
    component: EditTeamManagement,
  },
];

export default RelawanRoutes;
