import {
    GET_STATISTIC_PEMILIH,
    GET_STATISTIC_RELAWAN,
    GET_STATISTIC_LOGISTIC,
    GET_GRAFIK_PEMILIH_DAILY,
    GET_GRAFIK_PEMILIH_MONTHLY,
    GET_GRAFIK_RELAWAN_DAILY,
    GET_GRAFIK_RELAWAN_MONTHLY,
} from "../constant.js";

const initialState = {
    dataStatisticPemilih: [],
    dataStatisticRelawan: [],
    dataStatisticLogistik: [],
    dataGrafikPemilihDaily: [],
    dataGrafikPemilihMonthly: [],
    dataGrafikRelawanDaily: [],
    dataGrafikRelawanMonthly: [],
};

const StatisticReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_STATISTIC_PEMILIH: {
            return {
                ...state,
                dataStatisticPemilih: action?.payload || []
            };
        }
        case GET_STATISTIC_RELAWAN: {
            return {
                ...state,
                dataStatisticRelawan: action?.payload || []
            };
        }
        case GET_STATISTIC_LOGISTIC: {
            return {
                ...state,
                dataStatisticLogistik: action?.payload || []
            };
        }
        case GET_GRAFIK_PEMILIH_DAILY: {
            return {
                ...state,
                dataGrafikPemilihDaily: action?.payload || []
            };
        }
        case GET_GRAFIK_PEMILIH_MONTHLY: {
            return {
                ...state,
                dataGrafikPemilihMonthly: action?.payload || []
            };
        }
        case GET_GRAFIK_RELAWAN_DAILY: {
            return {
                ...state,
                dataGrafikRelawanDaily: action?.payload || []
            };
        }
        case GET_GRAFIK_RELAWAN_MONTHLY: {
            return {
                ...state,
                dataGrafikRelawanMonthly: action?.payload || []
            };
        }
        default: {
            return state;
        }
    }
};

export default StatisticReducer;
