import { API, setAuthToken } from "config/API.js";
import { GET_GRAFIK_RELAWAN_DAILY, GET_GRAFIK_RELAWAN_MONTHLY, GET_GRAFIK_PEMILIH_DAILY, GET_GRAFIK_PEMILIH_MONTHLY, GET_STATISTIC_LOGISTIC, GET_STATISTIC_PEMILIH, GET_STATISTIC_RELAWAN } from "../constant.js";

export const getStatisticPemilih = () => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/user/statistik/pemilih`)
            .then((res) => {
                dispatch({
                    type: GET_STATISTIC_PEMILIH,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_STATISTIC_PEMILIH,
                    payload: [],
                });
            });
    };
};

export const getStatisticRelawan = () => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/user/statistik/relawan`)
            .then((res) => {
                dispatch({
                    type: GET_STATISTIC_RELAWAN,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_STATISTIC_RELAWAN,
                    payload: [],
                });
            });
    };
};

export const getStatisticLogistik = () => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/user/statistik/logistik`)
            .then((res) => {
                dispatch({
                    type: GET_STATISTIC_LOGISTIC,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_STATISTIC_LOGISTIC,
                    payload: [],
                });
            });
    };
};


export const getGrafikStatistikPemilihDaily = (params = '') => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/user/statistik/pemilih/grafik/daily` + params)
            .then((res) => {
                dispatch({
                    type: GET_GRAFIK_PEMILIH_DAILY,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_GRAFIK_PEMILIH_DAILY,
                    payload: [],
                });
            });
    };
};


export const getGrafikStatistikPemilihMonthly = (params = '') => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/user/statistik/pemilih/grafik/monthly` + params)
            .then((res) => {
                dispatch({
                    type: GET_GRAFIK_PEMILIH_MONTHLY,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_GRAFIK_PEMILIH_MONTHLY,
                    payload: [],
                });
            });
    };
};
export const getGrafikStatistikRelawanDaily = (params = '') => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/user/statistik/relawan/grafik/daily` + params)
            .then((res) => {
                dispatch({
                    type: GET_GRAFIK_RELAWAN_DAILY,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_GRAFIK_RELAWAN_DAILY,
                    payload: [],
                });
            });
    };
};


export const getGrafikStatistikRelawanMonthly = (params = '') => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/user/statistik/relawan/grafik/monthly` + params)
            .then((res) => {
                dispatch({
                    type: GET_GRAFIK_RELAWAN_MONTHLY,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_GRAFIK_RELAWAN_MONTHLY,
                    payload: [],
                });
            });
    };
};