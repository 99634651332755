/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Chip,
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Hidden,
  Box,
} from "@material-ui/core";
import {
  deleteWargaBinaan,
  getDataWargaBinaan,
  importWargaBinaan,
} from "app/redux/actions/WargaBinaanActions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import MenuActions from "app/components/button/MenuActions";
import GeneralButton from "app/components/GeneralButton";
import { AddCircleOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Filter, Import } from "app/components/icon";
import ModalFilter from "./components/ModalFilter";
import ModalImport from "./components/ModalImport";
import { useQueryParams } from "helpers/useQueryParams.hook";
import {
  showConfirmation,
  showError,
  showSuccess,
} from "app/components/sweetalertService";

const WargaBinaan = () => {
  const dispatch = useDispatch();

  const { queryParams, setQueryParams } = useQueryParams(
    {
      page: 1,
      limit: 10,
      search: "",
      min: "",
      max: "",
      provinsi: "",
      kota_kab: "",
      kecamatan: "",
      desa: "",
      vote: "",
    },
    {
      page: 1,
      limit: 10,
      search: "",
      min: "",
      max: "",
      provinsi: "",
      kota_kab: "",
      kecamatan: "",
      desa: "",
      vote: "",
    }
  );

  function handleSearch({
    page,
    limit,
    search,
    min,
    max,
    provinsi,
    kota_kab,
    kecamatan,
    desa,
    vote,
  }) {
    setQueryParams({
      ...(page && { page }),
      ...(limit && { limit }),
      ...(search && { search }),
      ...(min && { min }),
      ...(max && { max }),
      ...(provinsi && { provinsi }),
      ...(kota_kab && { kota_kab }),
      ...(kecamatan && { kecamatan }),
      ...(desa && { desa }),
      ...(vote && { vote }),
    });
  }

  const handleSearchChange = (e) => {
    const { name, value } = e.target;

    const updatedSearch = {
      ...queryParams,
      page: 1,
      [name]: value,
    };

    updatePagePagination();
    handleSearch(updatedSearch);
  };

  const [state, setState] = useState({
    loading: false,
    page: queryParams.page > 0 ? queryParams.page - 1 : 0,
    rowsPerPageOptions: [5, 10, 50, 100],
    paramsChange: false,
    limit: 10,
  });
  const [modalFilter, setModalFilter] = useState(false);
  const [modalImport, setModalImport] = useState(false);

  const { dataWargaBinaan, dataWargaBinaanPagination } = useSelector(
    (state) => state.wargaBinaan
  );

  const getData = () => {
    setState((prevState) => ({ ...prevState, loading: true }));

    dispatch(getDataWargaBinaan(queryParams))
      .then(() => {
        setState((prevState) => ({ ...prevState, loading: false }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setState((prevState) => ({ ...prevState, loading: false }));
      });
  };

  const submitSearch = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      setState({ ...state, paramsChange: !state.paramsChange });
    }
  };

  const handleSubmitFilter = () => {
    getData();
    handleClose();
  };

  const updatePagePagination = () => {
    setState({ ...state, page: 0 });
  };

  const setRowsPerPage = (event) => {
    const newLimit = parseInt(event.target.value);
    setState({ ...state, limit: newLimit, page: 0 });
    setQueryParams({ limit: newLimit });
  };

  const handleChangePage = (event, newPage) => {
    handleSearch({
      ...queryParams,
      page: newPage + 1,
      limit: queryParams.limit,
    });
    setState({ ...state, page: newPage, paramsChange: !state.paramsChange });
  };

  const getTableRowNumber = (index) => {
    return state.page * state.limit + index + 1;
  };

  const handleDelete = async (code) => {
    const confirmed = await showConfirmation("Hapus", "Apakah kamu yakin ?");

    if (confirmed) {
      try {
        deleteWargaBinaan({ warga_binaan_code: code }).then((res) => {
          showSuccess("Data berhasil dihapus!");
          getData();
        });
      } catch (error) {
        showError("Data gagal dihapus !");
      }
    } else {
      showError("Data gagal dihapus !");
    }
  };

  const handleClose = () => {
    setModalFilter(!modalFilter);
  };

  const handleCloseImport = () => {
    setModalImport(!modalImport);
  };

  const handleSubmitImport = (data) => {
    importWargaBinaan(data)
      .then((res) => {
        Swal.fire({
          title: '<div class="custom-swal-title">Data berhasil diimport</div>',
          icon: "success",
          buttonsStyling: false,
          customClass: {
            confirmButton: "custom-success-button",
          },
        });
        handleCloseImport();

        getData();
      })
      .catch((err) => {
        Swal.fire({
          title: '<div class="custom-swal-title">Data gagal diimport</div>',
          icon: "error",
          buttonsStyling: false,
          customClass: {
            confirmButton: "custom-error-button",
          },
        });
        handleCloseImport();
      });
  };

  useEffect(() => {
    getData();
  }, [dispatch, state.paramsChange, state.limit]);

  return (
    <div className="p-40">
      <div className="mb-sm-30">
        <h2 className="font-size-32 fw-700 fw-bold">Calon Pemilih</h2>
      </div>
      <Card className="w-100 overflow-auto p-24" elevation={6}>
        {/* Start New */}
        <Grid
          container
          spacing={3}
          className="mb-2 d-flex justify-content-between align-items-center"
        >
          {/* Mobile Layout */}
          <Hidden smUp>
            <div className="flex gap-2 p-2 flex-wrap">
              <GeneralButton
                variant="contained"
                color="primary"
                text="Import Calon Pemilih"
                icon={<Import color="#FFFFFF" />}
                info="Unggah file Excel"
                onClick={handleCloseImport}
              />
              <Link to="/calon_pemilih/tambah">
                <GeneralButton
                  variant="contained"
                  color="primary"
                  text="Tambah Calon Pemilih"
                  icon={<AddCircleOutline fontSize="small" />}
                />
              </Link>
            </div>
          </Hidden>

          <Grid item xs={12} sm={9} md={6} lg={6}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={8} sm={6} md={6} lg={6}>
                <TextField
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  placeholder="Cari"
                  onChange={handleSearchChange}
                  onKeyDown={submitSearch}
                  value={queryParams.search}
                  name="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon color="disabled">search</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3}>
                <GeneralButton
                  variant="outlined"
                  text="Filter"
                  otherClass="text-secondary"
                  border="1px solid #E6E9ED"
                  icon={<Filter />}
                  onClick={handleClose}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Desktop Layout */}
          <Hidden xsDown>
            <Grid
              item
              xs={3}
              sm={3}
              md={6}
              lg={6}
              className="flex flex-end"
              style={{ gap: "10px" }}
            >
              <GeneralButton
                variant="contained"
                color="primary"
                text="Import Calon Pemilih"
                icon={<Import color="#FFFFFF" />}
                info="Unggah file Excel"
                onClick={handleCloseImport}
              />
              <Link to="/calon_pemilih/tambah">
                <GeneralButton
                  variant="contained"
                  color="primary"
                  text="Tambah Calon Pemilih"
                  icon={<AddCircleOutline fontSize="small" />}
                />
              </Link>
            </Grid>
          </Hidden>
        </Grid>
        {/* End New */}

        <TableContainer>
          <Table
            className="t-container"
            sx={{
              width: "max-content",
              overflow: "auto",
            }}
          >
            <TableHead className="t-header">
              <TableRow>
                <TableCell align="center" className="table-cell-row">
                  No
                </TableCell>
                <TableCell align="left" className="table-cell-binaan">
                  Nama Calon Pemilih
                </TableCell>
                <TableCell align="center" className="table-cell-binaan">
                  Umur
                </TableCell>
                <TableCell align="center" className="table-cell-binaan">
                  Nomor Ponsel
                </TableCell>
                <TableCell align="center" className="table-cell-binaan">
                  Nomor ID
                </TableCell>
                <TableCell align="center" className="table-cell-binaan">
                  Kab/Kota
                </TableCell>
                <TableCell align="center" className="table-cell-binaan">
                  Provinsi
                </TableCell>
                <TableCell align="center" className="table-cell-binaan">
                  Status
                </TableCell>
                <TableCell align="center" className="table-cell-binaan">
                  Aksi
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="t-body">
              {state.loading ? (
                <TableRow>
                  <TableCell
                    colSpan={9}
                    align="center"
                    className="table-cell-binaan"
                  >
                    Loading ...
                  </TableCell>
                </TableRow>
              ) : dataWargaBinaan.length > 0 ? (
                dataWargaBinaan.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className="table-cell-row" align="center">
                      {getTableRowNumber(index)}
                    </TableCell>
                    <TableCell className="table-cell-binaan" align="left">
                      {item.warga_binaan_nama}
                    </TableCell>
                    <TableCell className="table-cell-binaan" align="center">
                      {item.warga_binaan_umur}
                    </TableCell>
                    <TableCell className="table-cell-binaan" align="center">
                      {item.warga_binaan_telepon}
                    </TableCell>
                    <TableCell className="table-cell-binaan" align="center">
                      {item.warga_binaan_nik}
                    </TableCell>
                    <TableCell className="px-0" align="center">
                      {item.kota_kab_name}
                    </TableCell>
                    <TableCell className="px-0" align="center">
                      {item.province_name}
                    </TableCell>
                    <TableCell className="table-cell-binaan" align="center">
                      {item.warga_binaan_vote === 1 && (
                        <Chip
                          label={"Memilih"}
                          size="small"
                          style={{
                            padding: "2px 10px",
                            fontSize: "12px",
                            color: "#FFFFFF",
                            backgroundColor: "#71BE42",
                          }}
                        />
                      )}
                      {item.warga_binaan_vote === 2 && (
                        <Chip
                          label={"Tidak Memilih"}
                          size="small"
                          style={{
                            padding: "2px 10px",
                            fontSize: "12px",
                            color: "#FFFFFF",
                            backgroundColor: "#E34242",
                          }}
                        />
                      )}
                      {item.warga_binaan_vote === 3 && (
                        <Chip
                          label={"Ragu - Ragu"}
                          size="small"
                          style={{
                            padding: "2px 10px",
                            fontSize: "12px",
                            color: "#FFFFFF",
                            backgroundColor: "#00ACEE",
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell className="table-cell-binaan" align="center">
                      <MenuActions
                        editPath={`/calon_pemilih/edit/${item.warga_binaan_code}`}
                        detailPath={`/calon_pemilih/${item.warga_binaan_code}`}
                        deleteActions={() =>
                          handleDelete(item.warga_binaan_code)
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={9}
                    align="center"
                    className="table-cell-binaan"
                  >
                    Data kosong
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box display="flex" justifyContent="space-between">
          <Box
            display="flex"
            flexDirection="row"
            p={1}
            // m={1}
            alignItems="center"
          >
            <Box p={1}>
              <div className="flex">
                <p>Rows per page : </p>
                <select
                  labelid="simple-select"
                  //   value={queryParams.limit}
                  value={state.limit}
                  name="rowsPerPage"
                  onChange={setRowsPerPage}
                  variant="standard"
                  className="border-none"
                >
                  {state.rowsPerPageOptions.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </Box>
          </Box>
          <Box>
            <TablePagination
              className="px-16"
              rowsPerPageOptions={[]}
              component="div"
              count={dataWargaBinaanPagination.total || 0}
              rowsPerPage={queryParams.limit}
              labelRowsPerPage={""}
              page={dataWargaBinaanPagination.current_page - 1 || 0}
              backIconButtonProps={{
                "aria-label": "Previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next page",
              }}
              backIconButtonText="Previous page"
              nextIconButtonText="Next page"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={setRowsPerPage}
            />
          </Box>
        </Box>
      </Card>

      <ModalFilter
        open={modalFilter}
        handleClose={handleClose}
        state={state}
        setState={setState}
        handleSubmit={handleSubmitFilter}
        handleSearchChange={handleSearchChange}
        value={queryParams}
        handleSearch={handleSearch}
      />
      <ModalImport
        open={modalImport}
        handleClose={handleCloseImport}
        handleSubmit={handleSubmitImport}
      />
    </div>
  );
};

export default WargaBinaan;
