import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getGrafikStatistikRelawanDaily,
  getGrafikStatistikRelawanMonthly,
  getStatisticRelawan,
  getGrafikStatistikPemilihDaily,
  getGrafikStatistikPemilihMonthly,
  getStatisticPemilih,
} from "app/redux/actions/StatisticActions";
import { UsersThree, Users } from "@phosphor-icons/react";

export const useStatistikRelawan = () => {
  const dispatch = useDispatch();

  const {
    dataStatisticRelawan,
    dataGrafikRelawanDaily,
    dataGrafikRelawanMonthly,
  } = useSelector((state) => state.statistic);

  useEffect(() => {
    const getData = () => {
      dispatch(getStatisticRelawan());
      dispatch(getGrafikStatistikRelawanDaily());
      dispatch(getGrafikStatistikRelawanMonthly());
    };

    getData();
  }, [dispatch]);

  const relawanStatistik = [
    {
      label: "Total Relawan",
      value: dataStatisticRelawan.totalRelawan,
      icon: UsersThree,
    },
    {
      label: "Relawan Aktif",
      value: dataStatisticRelawan.totalRelawanAktif,
      icon: UsersThree,
      color: "text-green-50",
    },
    {
      label: "Relawan Nonaktif",
      value: dataStatisticRelawan.totalRelawanNonAktif,
      icon: UsersThree,
      color: "text-red-50",
    },
  ];

  return {
    relawanStatistik,
    dataGrafikRelawanDaily,
    dataGrafikRelawanMonthly,
  };
};

export const useStatistikPemilih = () => {
  const dispatch = useDispatch();

  const {
    dataStatisticPemilih,
    dataGrafikPemilihDaily,
    dataGrafikPemilihMonthly,
  } = useSelector((state) => state.statistic);

  // const { dataDPT } = useSelector((state) => state.other);

  useEffect(() => {
    const getData = () => {
      dispatch(getStatisticPemilih());
      dispatch(getGrafikStatistikPemilihDaily());
      dispatch(getGrafikStatistikPemilihMonthly());
      // dispatch(getTotalDPT());
    };

    getData();
    // console.log('dpt', dataDPT)
  }, [dispatch]);

  const calonPemilihStatistik = [
    {
      label: "Total Calon Pemilih",
      value: dataStatisticPemilih.totalPemilih,
    },
    {
      label: "Calon Pemilih Pria",
      value: dataStatisticPemilih.totalPemilihPria,
    },
    {
      label: "Calon Pemilih Wanita",
      value: dataStatisticPemilih.totalPemilihWanita,
    },
    {
      label: "Total Calon Pemilih Pasti Memilih",
      value: dataStatisticPemilih.totalPemilihMemilih,
      color: "text-green-50",
    },
    {
      label: "Total Calon Pemilih Ragu Ragu",
      value: dataStatisticPemilih.totalPemilihRagu,
      color: "text-yellow",
    },
    {
      label: "Total Calon Pemilih Tidak Memilih",
      value: dataStatisticPemilih.totalPemilihTidakMemilih,
      color: "text-red-50",
    },
  ];

  const dptStatistik = [
    {
      label: "Total DPT Dapil",
      value: 1500,
    },
    {
      label: "Total DPT Pria",
      value: 750,
    },
    {
      label: "Total Calon Pemilih Tidak Memilih",
      value: 750,
    },
  ];

  return {
    calonPemilihStatistik,
    dptStatistik,
    dataGrafikPemilihDaily,
    dataGrafikPemilihMonthly,
  };
};
