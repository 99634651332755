/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useHistory, useParams, Link } from "react-router-dom";
import { UsersThree } from "@phosphor-icons/react";
import InputFileImg from "app/components/Input/InputFileImg";
import { getProvince } from "app/redux/actions/ProvinceActions";
import { getCity } from "app/redux/actions/CityActions";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CalendarIcon } from "app/components/Icons";
import { getDataRelawan } from "app/redux/actions/RelawanActions";
import { getDistrict } from "app/redux/actions/DistrictActions";
import { getVillages } from "app/redux/actions/VillagesActions";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import {
  editWargaBinaan,
  getDetailWargaBinaan,
} from "app/redux/actions/WargaBinaanActions";
import dayjs from "dayjs";
import { getTPS } from "app/redux/actions/TPSActions";
import { ArrowCircleLeft } from "@phosphor-icons/react";
import { showError, showSuccess } from "app/components/sweetalertService";
import GreenButton from "app/components/GreenButton";
import Header from "./components/Header";

const EditWargaBinaan = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    warga_binaan_code: id,
    warga_binaan_nama: "",
    warga_binaan_nik: "",
    warga_binaan_telepon: "",
    warga_binaan_email: "",
    warga_binaan_kelamin: "pria",
    warga_binaan_pernikahan: "menikah",
    warga_binaan_tempat_lahir: "",
    warga_binaan_tanggal_lahir: new Date(),
    warga_binaan_umur: "",
    warga_binaan_agama: "islam",
    warga_binaan_vote: "",
    warga_binaan_alamat: "",
    warga_binaan_provinsi: "",
    warga_binaan_kota: "",
    warga_binaan_kecamatan: "",
    warga_binaan_desa: "",
    warga_binaan_refferal_relawan: "",
    warga_binaan_ktp: "",
    warga_binaan_ktp_preview: "",
    warga_binaan_foto: "",
    warga_binaan_foto_preview: "",
    warga_binaan_tps: "",
    warga_binaan_rt: "",
    warga_binaan_rw: "",
  });
  const [errorField, setErrorField] = useState({});

  const getData = () => {
    dispatch(getDetailWargaBinaan(id));
    dispatch(getDataRelawan());
    dispatch(getProvince());
    dispatch(getTPS());
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeProvince = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    dispatch(getCity({ province_code: e.target.value }));
  };

  const handleChangeKotaKab = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    dispatch(getDistrict({ city_code: e.target.value }));
  };

  const handleChangeDistrict = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    dispatch(getVillages({ districts_code: e.target.value }));
  };

  const handleSubmit = () => {
    editWargaBinaan(state)
      .then((res) => {
        showSuccess("Data berhasil diperbarui");
        history.push("/calon_pemilih");
      })
      .catch((err) => {
        showError("Data gagal diperbarui");
        setErrorField(err.response.data.data);
      });
  };

  const handleChangeFoto = (file, path, state) => {
    setState((prev) => ({
      ...prev,
      [state]: file,
      [`${state}_preview`]: path,
    }));
  };

  const handleChangeTanggal = (e) => {
    setState((prevState) => ({
      ...prevState,
      warga_binaan_tanggal_lahir: e,
    }));
  };

  const { dataProvince } = useSelector((state) => state.province);
  const { dataCity } = useSelector((state) => state.city);
  const { dataDistrict } = useSelector((state) => state.district);
  const { dataVillages } = useSelector((state) => state.villages);
  const { dataRelawan } = useSelector((state) => state.relawan);
  const { detailWargaBinaan } = useSelector((state) => state.wargaBinaan);
  const { dataTPS } = useSelector((state) => state.tps);

  useEffect(() => {
    let newState = {
      ...detailWargaBinaan,
      warga_binaan_ktp: "",
      warga_binaan_foto: "",
      warga_binaan_ktp_preview: detailWargaBinaan?.warga_binaan_ktp,
      warga_binaan_foto_preview: detailWargaBinaan?.warga_binaan_foto,
    };
    setState({
      ...newState,
    });
    if (detailWargaBinaan.warga_binaan_provinsi) {
      dispatch(
        getCity({ province_code: detailWargaBinaan.warga_binaan_provinsi })
      );
    }
    if (detailWargaBinaan.warga_binaan_kota) {
      dispatch(getDistrict({ city_code: detailWargaBinaan.warga_binaan_kota }));
    }
    if (detailWargaBinaan.warga_binaan_kecamatan) {
      dispatch(
        getVillages({
          districts_code: detailWargaBinaan.warga_binaan_kecamatan,
        })
      );
    }
  }, [detailWargaBinaan]);

  return (
    <div className="analytics m-sm-30 mt-7">
      {/* <Grid
        container
        spacing={3}
        className="mb-2 d-flex justify-content-center align-items-center"
      >
        <Grid item lg={6} md={6} xs={12} sm={6}>
          <h2 className="font-size-32 fw-700 fw-bold">Edit Calon Pemilih</h2>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          sm={6}
          spacing={2}
          container
          justify="flex-end"
        >
          <Grid item>
            <GreenButton
              text="Kembali"
              onClick={() => history.push("/calon_pemilih")}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <GreenButton
              text="Simpan Data"
              onClick={handleSubmit}
              variant="contained"
            />
          </Grid>
        </Grid>
      </Grid> */}
      <Header
        title="Edit Calon Pemilih"
        backAction={() => history.goBack()}
        saveAction={handleSubmit}
      />

      <Card style={{ padding: "20px", marginBottom: "20px" }}>
        <Grid container className="mt-2 p-2 " spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h3 className="fw-bold font-size-40">Detail Calon Pemilih</h3>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="image">
              <h5 className="font-size-14">Foto Calon Pemilih</h5>
              <InputFileImg
                noPadding={true}
                name="warga_binaan_foto"
                uploadFoto={(file, path) =>
                  handleChangeFoto(file, path, "warga_binaan_foto")
                }
                preview={state.warga_binaan_foto_preview}
                acceptFile="image"
              />
            </InputLabel>
            <small className="text-danger">
              {errorField?.warga_binaan_foto}
            </small>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="image_ktp">
              <h5 className="font-size-14">Foto KTP</h5>
              <InputFileImg
                noPadding={true}
                name="warga_binaan_ktp"
                uploadFoto={(file, path) =>
                  handleChangeFoto(file, path, "warga_binaan_ktp")
                }
                preview={state.warga_binaan_ktp_preview}
                acceptFile="image"
              />
            </InputLabel>
            <small className="text-danger">
              {errorField?.warga_binaan_ktp}
            </small>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="warga_binaan_nama">
              <h5 className="font-size-14">Nama Lengkap</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.warga_binaan_nama ?? " "}
              name="warga_binaan_nama"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Nama Lengkap"
              variant="outlined"
              onChange={handleChange}
              type="text"
              error={errorField?.warga_binaan_nama !== ""}
              helperText={errorField?.warga_binaan_nama}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="warga_binaan_nik">
              <h5 className="font-size-14">NIK</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.warga_binaan_nik ?? " "}
              name="warga_binaan_nik"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="NIK"
              variant="outlined"
              onChange={handleChange}
              type="number"
              error={errorField?.warga_binaan_nik !== ""}
              helperText={errorField?.warga_binaan_nik}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="warga_binaan_telepon">
              <h5 className="font-size-14">Nomor Ponsel</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.warga_binaan_telepon ?? " "}
              name="warga_binaan_telepon"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="No Ponsel"
              variant="outlined"
              onChange={handleChange}
              type="number"
              error={errorField?.warga_binaan_telepon !== ""}
              helperText={errorField?.warga_binaan_telepon}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="warga_binaan_email">
              <h5 className="font-size-14">Email</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.warga_binaan_email ?? " "}
              name="warga_binaan_email"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Email"
              variant="outlined"
              onChange={handleChange}
              type="text"
              error={errorField?.warga_binaan_email !== ""}
              helperText={errorField?.warga_binaan_email}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="warga_binaan_kelamin">
              <h5 className="font-size-14">Jenis Kelamin</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="warga_binaan_kelamin"
              value={state.warga_binaan_kelamin ?? " "}
              onChange={handleChange}
              variant="outlined"
              className="w-100"
              name="warga_binaan_kelamin"
              displayEmpty
            >
              <MenuItem key="1" value="pria">
                Laki-laki
              </MenuItem>
              <MenuItem key="2" value="wanita">
                Perempuan
              </MenuItem>
            </Select>
            <small className="text-danger">
              {errorField.warga_binaan_kelamin}
            </small>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="warga_binaan_pernikahan">
              <h5 className="font-size-14">Status Pernikahan</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="warga_binaan_pernikahan"
              value={state.warga_binaan_pernikahan ?? " "}
              onChange={handleChange}
              variant="outlined"
              className="w-100"
              name="warga_binaan_pernikahan"
              displayEmpty
            >
              <MenuItem key="1" value="menikah">
                Sudah Menikah
              </MenuItem>
              <MenuItem key="2" value="lajang">
                Lajang
              </MenuItem>
              <MenuItem key="3" value="cerai">
                Cerai
              </MenuItem>
            </Select>
            <small className="text-danger">
              {errorField.warga_binaan_pernikahan}
            </small>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="warga_binaan_tempat_lahir">
              <h5 className="font-size-14">Tempat Lahir</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.warga_binaan_tempat_lahir ?? " "}
              name="warga_binaan_tempat_lahir"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Tempat Lahir"
              variant="outlined"
              onChange={handleChange}
              type="text"
              error={errorField?.warga_binaan_tempat_lahir !== ""}
              helperText={errorField?.warga_binaan_tempat_lahir}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="provinsi">
              <h5 className="font-size-14" style={{ marginBottom: "-5px" }}>
                Tanggal Lahir
              </h5>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker"]}
                style={{ marginTop: "50px" }}
              >
                <DatePicker
                  label=""
                  className={`w-100 pb-0`}
                  size="small"
                  required={true}
                  InputProps={{
                    style: {
                      borderRadius: 5,
                      padding: 0,
                    },
                  }}
                  placeholder=""
                  variant="outlined"
                  onChange={handleChangeTanggal}
                  components={{
                    OpenPickerIcon: CalendarIcon,
                  }}
                  views={["year", "month", "day"]}
                  value={dayjs(state.warga_binaan_tanggal_lahir)}
                />
              </DemoContainer>
              <small className="text-danger">
                {errorField?.warga_binaan_tanggal_lahir}
              </small>
            </LocalizationProvider>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="warga_binaan_umur">
              <h5 className="font-size-14">Umur</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.warga_binaan_umur ?? " "}
              name="warga_binaan_umur"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Umur"
              variant="outlined"
              onChange={handleChange}
              type="number"
              error={errorField?.warga_binaan_umur !== ""}
              helperText={errorField?.warga_binaan_umur}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="warga_binaan_agama">
              <h5 className="font-size-14">Agama</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="warga_binaan_agama"
              value={state.warga_binaan_agama ?? " "}
              onChange={handleChange}
              variant="outlined"
              className="w-100"
              name="warga_binaan_agama"
              displayEmpty
            >
              <MenuItem key="1" value="islam">
                Islam
              </MenuItem>
              <MenuItem key="2" value="protestan">
                Kristen Protestan
              </MenuItem>
              <MenuItem key="3" value="katholik">
                Kristen Katholik
              </MenuItem>
              <MenuItem key="4" value="hindu">
                Hindu
              </MenuItem>
              <MenuItem key="5" value="buddha">
                Budha
              </MenuItem>
              <MenuItem key="6" value="khonghucu">
                Khonghucu
              </MenuItem>
            </Select>
            <small className="text-danger">
              {errorField.warga_binaan_agama}
            </small>
          </Grid>
        </Grid>
      </Card>

      <Card style={{ padding: "20px", marginBottom: "20px" }}>
        <Grid container className="mt-2 p-2 " spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h3 className="fw-bold font-size-40">
              Daerah Calon Pemilih Sekarang
            </h3>
          </Grid>
          <Grid item lg={6} md={6} xs={12} sm={6}>
            <InputLabel htmlFor="warga_binaan_provinsi">
              <h5 className="font-size-14">Provinsi</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="warga_binaan_provinsi"
              value={state.warga_binaan_provinsi ?? " "}
              onChange={handleChangeProvince}
              variant="outlined"
              className="w-100"
              name="warga_binaan_provinsi"
              displayEmpty
            >
              <MenuItem key={"x"} value={""}>
                Pilih Provinsi
              </MenuItem>
              {dataProvince.map((province) => (
                <MenuItem key={province.id} value={province.id}>
                  {province.name}
                </MenuItem>
              ))}
            </Select>
            <small className="text-danger">
              {errorField.warga_binaan_provinsi}
            </small>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={6}>
            <InputLabel htmlFor="warga_binaan_kota">
              <h5 className="font-size-14">Kab/Kota</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="warga_binaan_kota"
              value={state.warga_binaan_kota ?? " "}
              onChange={handleChangeKotaKab}
              variant="outlined"
              className="w-100"
              name="warga_binaan_kota"
              displayEmpty
            >
              <MenuItem key={"x"} value={""}>
                Pilih Kab/Kota
              </MenuItem>
              {dataCity.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <small className="text-danger">
              {errorField.warga_binaan_kota}
            </small>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={6}>
            <InputLabel htmlFor="warga_binaan_kecamatan">
              <h5 className="font-size-14">Kecamatan</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="warga_binaan_kecamatan"
              value={state.warga_binaan_kecamatan ?? " "}
              onChange={handleChangeDistrict}
              variant="outlined"
              className="w-100"
              name="warga_binaan_kecamatan"
              displayEmpty
            >
              <MenuItem key={"x"} value={""}>
                Pilih Kecamatan
              </MenuItem>
              {dataDistrict.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <small className="text-danger">
              {errorField.warga_binaan_kecamatan}
            </small>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={6}>
            <InputLabel htmlFor="warga_binaan_desa">
              <h5 className="font-size-14">Kelurahan/Desa</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="warga_binaan_desa"
              value={state.warga_binaan_desa ?? " "}
              onChange={handleChange}
              variant="outlined"
              className="w-100"
              name="warga_binaan_desa"
              displayEmpty
            >
              <MenuItem key={"x"} value={""}>
                Pilih Kelurahan
              </MenuItem>
              {dataVillages.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <small className="text-danger">
              {errorField.warga_binaan_desa}
            </small>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="warga_binaan_rt">
              <h5 className="font-size-14">RT</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.warga_binaan_rt ?? " "}
              name="warga_binaan_rt"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="RT"
              variant="outlined"
              onChange={handleChange}
              type="number"
              error={errorField?.warga_binaan_rt !== ""}
              helperText={errorField?.warga_binaan_rt}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="warga_binaan_rw">
              <h5 className="font-size-14">RW</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.warga_binaan_rw ?? " "}
              name="warga_binaan_rw"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="RW"
              variant="outlined"
              onChange={handleChange}
              type="number"
              error={errorField?.warga_binaan_rw !== ""}
              helperText={errorField?.warga_binaan_rw}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={6} sm={6}>
            <InputLabel htmlFor="warga_binaan_tps">
              <h5 className="font-size-14">TPS</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="warga_binaan_tps"
              value={state.warga_binaan_tps ?? " "}
              onChange={handleChange}
              variant="outlined"
              className="w-100"
              name="warga_binaan_tps"
              displayEmpty
            >
              <MenuItem key={"x"} value="">
                Pilih TPS
              </MenuItem>
              {dataTPS.map((item) => (
                <MenuItem
                  key={item.kategori_nomor_tps_code}
                  value={item.kategori_nomor_tps_code}
                >
                  {item.kategori_nomor_tps_nama}
                </MenuItem>
              ))}
            </Select>
            <small className="text-danger">{errorField.warga_binaan_tps}</small>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="warga_binaan_alamat">
              <h5 className="font-size-14">Alamat</h5>
            </InputLabel>
            <textarea
              name="warga_binaan_alamat"
              placeholder="Alamat"
              rows="5"
              className="w-full"
              id="warga_binaan_alamat"
              value={state.warga_binaan_alamat ?? " "}
              onChange={handleChange}
            ></textarea>
            <small className="text-danger">
              {errorField.warga_binaan_alamat}
            </small>
          </Grid>
        </Grid>
      </Card>

      <Card style={{ padding: "20px", marginBottom: "20px" }}>
        <Grid container className="mt-2 p-2 " spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h3 className="fw-bold font-size-40">Lainnya</h3>
          </Grid>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <InputLabel htmlFor="name">
              <h5 className="font-size-14">Referal Relawan</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="warga_binaan_refferal_relawan"
              value={state.warga_binaan_refferal_relawan ?? " "}
              onChange={handleChangeDistrict}
              variant="outlined"
              className="w-100"
              name="warga_binaan_refferal_relawan"
              displayEmpty
            >
              <MenuItem key={"x"} value={""}>
                Pilih Referal Relawan
              </MenuItem>
              {dataRelawan.map((item) => (
                <MenuItem key={item.relawan_code} value={item.relawan_code}>
                  {item.relawan_nama}
                </MenuItem>
              ))}
            </Select>
            <small className="text-danger">
              {errorField.warga_binaan_refferal_relawan}
            </small>
          </Grid>

          <Grid item lg={12} md={12} xs={12} sm={12}>
            <InputLabel htmlFor="warga_binaan_vote">
              <h5 className="font-size-14">Status</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="warga_binaan_vote"
              value={state.warga_binaan_vote ?? " "}
              onChange={handleChange}
              variant="outlined"
              className="w-100"
              name="warga_binaan_vote"
              displayEmpty
            >
              <MenuItem value={1}>Memilih</MenuItem>
              <MenuItem value={2}>Tidak Memilih</MenuItem>
              <MenuItem value={3}>Ragu-ragu</MenuItem>
            </Select>
            <small className="text-danger">
              {errorField.warga_binaan_vote}
            </small>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default EditWargaBinaan;
