/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
} from "recharts";
import { Grid, Card } from "@mui/material";
import SelectItem from "app/components/select/SelectItem";
import moment from "moment";
import "moment/locale/id";
import { shorterMonth } from "helpers/shorterMonth";

const ChartStatisticRelawan = ({
  selectedFilter,
  onSelectFilter,
  onSelectYear,
  dataGrafikRelawanDaily,
  dataGrafikRelawanMonthly,
  getDataGrafik,
}) => {
  const [year, setYear] = useState("");
  const [filter, setFilter] = useState("");
  const [availableYears, setAvailableYears] = useState([]);

  const getAvailableYears = () => {
    const currentYear = moment().year();
    const years = Array.from({ length: 5 }, (_, index) => ({
      label: (currentYear - index).toString(),
      value: currentYear - index,
    }));
    return years;
  };

  useEffect(() => {
    setAvailableYears(getAvailableYears());
  }, []);

  const filters = [
    { label: "Hari", value: "daily" },
    { label: "Bulan", value: "monthly" },
  ];

  const handleYearChange = (event) => {
    const yearValue = event.target.value;
    onSelectYear(yearValue);
    setYear(yearValue);
  };

  const handleFilterChange = (event) => {
    const filterValue = event.target.value;
    onSelectFilter(filterValue);
    setFilter(filterValue);
  };

  useEffect(() => {
    if (year) {
      getDataGrafik("relawan");
    }
  }, [year, filter]);

  return (
    <Fragment>
      <Card style={{ padding: "38px", borderRadius: "10px" }}>
        <Grid
          container
          className="mb-4 flex justify-content-between align-items-center"
        >
          <Grid item>
            <h3 className="font-size-20 fw-bold">Statistik Relawan</h3>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} md={6} sm={6}>
                <SelectItem
                  onChange={handleYearChange}
                  label="Pilih Tahun"
                  listItem={availableYears}
                  value={year}
                />
              </Grid>
              <Grid item xs={12} lg={6} md={6} sm={6}>
                <SelectItem
                  onChange={handleFilterChange}
                  label="Pilih Filter"
                  listItem={filters}
                  value={filter}
                  disabled={!year}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div style={{ width: "100%", overflowX: "auto", marginTop: "40px" }}>
          <ResponsiveContainer minWidth={700} height={300}>
            <AreaChart
              data={
                selectedFilter === "daily"
                  ? dataGrafikRelawanDaily
                  : dataGrafikRelawanMonthly
              }
              margin={{ top: 0, right: 0, left: 0, bottom: 15 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#D0F6B8" stopOpacity={1} />
                  <stop offset="100%" stopColor="#D0F6B8" stopOpacity={0.1} />
                </linearGradient>
              </defs>
              <XAxis
                axisLine={false}
                tickLine={false}
                dataKey={selectedFilter === "daily" ? "day" : "month"}
                domain={[0, "dataMax"]}
                tickFormatter={(value) => {
                  if (filter === "daily" || selectedFilter === "day") {
                    return shorterMonth(value, "date")
                  }
                  return shorterMonth(value)
                }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={(value) => Math.round(value)}
              />
              <CartesianGrid vertical={false} strokeDasharray="0" />
              <Area
                type="monotone"
                dataKey="total"
                stroke="#71BE42"
                fillOpacity={1}
                fill="url(#colorUv)"
                activeDot={{
                  stroke: "#71BE42",
                  fill: "#fff",
                  strokeWidth: 2,
                  r: 6,
                }}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                }}
                label={false}
                formatter={(value, name, props) => {
                  const legendLabels = {
                    total: "Total",
                  };
                  return [value, legendLabels[name]];
                }}
                itemStyle={{ color: "#1C1C1C" }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </Card>
    </Fragment>
  );
};

export default ChartStatisticRelawan;
