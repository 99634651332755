import {
    GET_PEMBAYARAN,
    GET_DETAIL_PEMBAYARAN,
    GET_TOTAL_PEMBAYARAN
} from "../constant.js";

const initialState = {
    dataPembayaran: [],
    dataPembayaranPagination: {},
    detailPembayaran: {},
    totalPembayaran: {
        total_amount: 0,
    },
};

const PembayaranReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_PEMBAYARAN: {
            return {
                ...state,
                dataPembayaran: action.payload.data,
                dataPembayaranPagination: action.payload.pagination,
            };
        }

        case GET_DETAIL_PEMBAYARAN: {
            return {
                ...state,
                detailPembayaran: action.payload.data,
            };
        }

        case GET_TOTAL_PEMBAYARAN: {
            return {
                ...state,
                totalPembayaran: action.payload.data,
            };
        }

        default: {
            return state;
        }
    }
};

export default PembayaranReducer;
