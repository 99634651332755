import ProfileUser from './ProfileUser'

const ProfileRoutes = [
  {
    path: '/profile',
    exact: true,
    component: ProfileUser
  },
]

export default ProfileRoutes
