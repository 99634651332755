import { GET_UNIT } from "../constant.js";

const initialState = {
  unit: [],
};

const GlobalReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_UNIT: {
      return {
        ...state,
        unit: action?.payload || [],
      };
    }
    default: {
      return state;
    }
  }
};

export default GlobalReducer;
