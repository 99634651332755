import {
  GET_DETAIL_WARGA_BINAAN,
  GET_WARGA_BINAAN
} from "../constant.js";

const initialState = {
  dataWargaBinaan: [],
  detailWargaBinaan: {},
  dataWargaBinaanPagination: {},
};

const WargaBinaanReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_WARGA_BINAAN: {
      return {
        ...state,
        dataWargaBinaan: action.payload.data,
        dataWargaBinaanPagination: action?.payload.pagination || [],
      };
    }
    case GET_DETAIL_WARGA_BINAAN: {
      return {
        ...state,
        detailWargaBinaan: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default WargaBinaanReducer;
