import React from "react";
import ReactEcharts from "echarts-for-react";
import { withStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";

const DoughnutChart = ({ height, color = [], theme, data = {} }) => {
  const option = {
    legend: {
      show: false,
      itemGap: 20,
      icon: "circle",
      bottom: 0,
      textStyle: {
        color: theme.palette.text.secondary,
        fontSize: 13,
        fontFamily: "Inter",
      },
    },
    tooltip: {
      show: false,
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    xAxis: [
      {
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
    ],

    series: [
      {
        name: "Traffic Rate",
        type: "pie",
        radius: ["30%", "72.55%"],
        center: ["50%", "50%"],
        avoidLabelOverlap: false,
        hoverOffset: 5,
        stillShowZeroSum: false,
        label: {
          normal: {
            show: false,
            position: "center", // shows the description data to center, turn off to show in right side
            textStyle: {
              color: theme.palette.text.secondary,
              fontSize: 13,
              fontFamily: "Inter",
            },
            formatter: "{a}",
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: "14",
              fontWeight: "normal",
              // color: "rgba(15, 21, 77, 1)"
            },
            formatter: "{b} \n{c} ({d}%)",
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        data: data,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  return (
    <Grid
      container
      spacing={3}
      className="px-2 d-flex justify-content-center align-items-center"
    >
      <Grid item lg={6} md={6} sm={6} xs={12} className="p-0">
        <ReactEcharts
          style={{ height: height }}
          option={{
            ...option,
            color: [...color],
          }}
        />
        <p className="text-grey text-center">Total DPT {data[0].value} Orang</p>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12} className="p-0">
        {data.map((item, index) => (
          <>
            <div className="d-flex align-items-center" key={item.label}>
              <FiberManualRecord htmlColor={color[index]} />
              <h5 className="fs-14 ms-2 mb-0">{item.label}</h5>
            </div>
            <h4 className="ml-33 mt-1 fs-20 fw-600">{item.value}</h4>
          </>
        ))}
      </Grid>
    </Grid>
  );
};

export default withStyles({}, { withTheme: true })(DoughnutChart);
