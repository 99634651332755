/* eslint-disable react-hooks/exhaustive-deps */
import {
    Card,
    Grid,
    Button,
    Paper,
    Hidden,
    Box,
    Typography,
    IconButton
} from "@material-ui/core";
import React, { useState, useEffect, Fragment } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import {
    ArrowCircleLeft,
} from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import shortid from "shortid";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getDetailPembayaran } from "app/redux/actions/PembayaranActions";
import { ContentCopy } from "@mui/icons-material";
import { formatCurrency } from "helpers/formatCurrency";
import { formatBankNumber } from "helpers/formatBankNumber";
import { Email, WhatsApp } from "@material-ui/icons";

const DetailBankPembayaran = ({

}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { bank_code } = useParams();

    const { detailPembayaran } = useSelector(
        (state) => state.pembayaran
    );

    const [state, setState] = useState({
        loading: true,
    });

    const getData = () => {
        dispatch(getDetailPembayaran(bank_code));
    };

    useEffect(() => {
        getData();
    }, []);

    console.log(detailPembayaran)

    const handleCopyToClipboard = (textToCopy) => {
        console.log(textToCopy)
        if (textToCopy === undefined) {
            Swal.fire({
                icon: "error",
                title: "Gagal",
                text: "Tidak ada kode yang di salin",
                showConfirmButton: false,
                timer: 2000,
            });

        } else {
            navigator.clipboard.writeText(textToCopy);
            Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Kode telah di salin",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    };
    return (
        <div className="p-40">
            <div className="mb-sm-30 flex justify-content-between align-items-center">
                <div className="flex gap-2 align-items-center">
                    <Link to="/pembayaran" className="back-button">
                        <ArrowCircleLeft size={30} />
                    </Link>
                    <h1 className="h1-title">BANK {detailPembayaran?.bank_name}</h1>
                </div>
                <Hidden xsDown>
                    <Button
                        variant="contained"
                        className="p-10 bg-green"
                        onClick={() => history.push("/pembayaran")}
                        style={{
                            textTransform: "none",
                            color: "#FFFFFF",
                        }}
                    >
                        <span className="">Kembali</span>
                    </Button>
                </Hidden>
            </div>
            <Card className="w-100 border-1 overflow-auto p-24">
                <h2 className="fw-700 fw-bold mb-sm-30">Detail Pembayaran</h2>
                <Paper elevation={3} square>
                    <Grid container spacing={0} className="p-4" >
                        <Grid item lg={6} md={6} >
                            <Card className="p-12">
                                <Box className="p-2 d-flex flex-column">
                                    <Typography variant="p" style={{ fontSize: 16 }}>
                                        Nomor Rekening:
                                    </Typography>
                                    <Typography className="fs-500 mb-2" variant="p" style={{ fontSize: 24, color: "#2d3142" }}>
                                        {formatBankNumber(`${detailPembayaran?.bank_rekening_number}` || '-')}
                                        <IconButton onClick={() => handleCopyToClipboard(detailPembayaran?.bank_rekening_number)} aria-label="copy">
                                            <ContentCopy />
                                        </IconButton>
                                    </Typography>

                                    <Typography variant="p" style={{ fontSize: 16 }}>
                                        Nama Pemilik:
                                    </Typography>
                                    <Typography className="fs-500 mb-2" variant="p" style={{ fontSize: 24, color: "#2d3142" }}>
                                        {detailPembayaran?.bank_user_fullname}
                                    </Typography>

                                    <Typography variant="p" style={{ fontSize: 16 }}>
                                        Total pembayaran:
                                    </Typography>
                                    <Typography className="fs-500 mb-2" variant="p" style={{ fontSize: 24, color: "#2d3142" }}>
                                        {formatCurrency(detailPembayaran?.bank_total_amount)}
                                        <IconButton onClick={() => handleCopyToClipboard(detailPembayaran?.bank_total_amount)} aria-label="copy">
                                            <ContentCopy />
                                        </IconButton>
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item lg={6} md={6}>
                            <Card className="p-12 ">
                                <Box
                                    component="div"
                                    className="d-flex flex-column justify-content-center align-items-center py-4"
                                    style={{ height: '100%' }}
                                >
                                    <img src={detailPembayaran?.bank_image} alt={detailPembayaran?.bank_name} height={65} />
                                    <div className="mt-4 d-flex flex-column">
                                        <Typography variant="p" className="mb-2" style={{ fontSize: 16 }}>
                                            Silahkan lakukan pembayaran melalui informasi Bank dengan nominal yang sudah tertera di samping.
                                        </Typography>
                                    </div>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Paper>
            </Card>

            <Card className="w-100 border-1 overflow-auto p-24">
                <Typography className="fs-500" variant="h6" style={{ fontSize: 18, marginBottom: "15px" }}>
                    Konfirmasi Pembayaran:
                </Typography>
                {/* Desktop */}
                <Hidden xsDown>
                    <Paper elevation={3} square>
                        <Grid container spacing={0} className="p-4" >
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Card className="p-8">
                                    <Box
                                        component="div"
                                        className="d-flex flex-column"
                                        style={{ height: '100%' }}
                                    >
                                        <div className="d-flex flex-column">
                                            <Typography variant="p" className="mb-2" style={{ fontSize: 16 }}>
                                                Silahkan lakukan pembayaran terlebih dahulu.
                                            </Typography>

                                            <Typography variant="p" className="mb-2" style={{ fontSize: 16 }}>
                                                Konfirmasi Pembayaran Melalui Kontak Berikut:
                                            </Typography>

                                            <div className="d-flex mb-2 align-items-center">
                                                <Email fontSize="large" style={{ color: "#00ACEE", marginRight: "8px" }} />
                                                <Typography className="fs-500" variant="p" style={{ fontSize: 18, color: "#2d3142" }}>
                                                    {detailPembayaran?.bank_admin_email}
                                                </Typography>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <WhatsApp fontSize="large" style={{ color: "#00ACEE", marginRight: "8px" }} />
                                                <Typography className="fs-500" variant="p" style={{ fontSize: 18, color: "#2d3142" }}>
                                                    +{detailPembayaran?.bank_admin_phone}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                    </Paper>
                </Hidden>
                {/* Mobile */}
                <Hidden smUp>
                    <Paper elevation={3} square>
                        <Grid container spacing={0} className="py-4" >
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Card className="p-8">
                                    <Box
                                        component="div"
                                        className="d-flex flex-column"
                                        style={{ height: '100%' }}
                                    >
                                        <div className="d-flex flex-column">
                                            <Typography variant="p" className="mb-2" style={{ fontSize: 16 }}>
                                                Silahkan lakukan pembayaran terlebih dahulu.
                                            </Typography>

                                            <Typography variant="p" className="mb-2" style={{ fontSize: 16 }}>
                                                Konfirmasi Pembayaran Melalui Kontak Berikut:
                                            </Typography>

                                            <div className="d-flex mb-2 align-items-center">
                                                <Email fontSize="large" style={{ color: "#00ACEE", marginRight: "8px" }} />
                                                <Typography className="fs-500" variant="p" style={{ fontSize: 18, color: "#2d3142" }}>
                                                    {detailPembayaran?.bank_admin_email}
                                                </Typography>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <WhatsApp fontSize="large" style={{ color: "#00ACEE", marginRight: "8px" }} />
                                                <Typography className="fs-500" variant="p" style={{ fontSize: 18, color: "#2d3142" }}>
                                                    {detailPembayaran?.bank_admin_phone}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                    </Paper>
                </Hidden>
            </Card>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        detailPembayaran: state.pembayaran.detailPembayaran,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDetailPembayaran: ({ bank_code }) => dispatch(getDetailPembayaran(bank_code)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailBankPembayaran);
