import {
  Clock,
  Users,
  UsersThree,
  Gear,
  Package,
  ClipboardText,
  PresentationChart,
  MapTrifold,
  ChartLineUp,
  Gauge,
  ArrowsLeftRight,
  CurrencyCircleDollar,
  Wallet
} from "@phosphor-icons/react";
import React from "react";
import { authRoles } from "./auth/authRoles";

export const navigations = [
  {
    name: "Dashboard",
    path: "/dashboard",
    auth: authRoles.all,
    icon: <Gauge style={{ marginBottom: "15px" }} />,
  },
  {
    name: "Statistik",
    path: "/statistik",
    auth: authRoles.admin,
    icon: <ChartLineUp style={{ marginBottom: "15px" }} />,
  },
  {
    name: "Pemilu Insight",
    path: "/pemilu_terakhir",
    auth: authRoles.admin,
    icon: <PresentationChart style={{ marginBottom: "15px" }} />,
  },
  {
    name: "Relawan",
    path: "/tim_sukses",
    auth: authRoles.admin,
    icon: <UsersThree style={{ marginBottom: "15px" }} />,
  },
  {
    name: "Calon Pemilih",
    path: "/calon_pemilih",
    auth: authRoles.all,
    icon: <Users style={{ marginBottom: "15px" }} />,
  },
  {
    name: "Arus Kas",
    path: "/arus_kas",
    auth: authRoles.admin,
    icon: <ArrowsLeftRight style={{ marginBottom: "15px" }} />,
  },
  {
    name: "Logistik",
    path: "/logistik",
    auth: authRoles.all,
    icon: <Package style={{ marginBottom: "15px" }} />,
  },

  // {
  // 	name: "Survey",
  // 	path: "/survey",
  // auth: authRoles.admin,
  // 	icon: <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  // 		<path d="M9.16665 7.61019H13.3333M13.3333 10.9435H9.16665M13.3333 14.2769H9.16665M6.66665 7.61019H6.66748M6.66665 10.9435H6.66748M6.66665 14.2769H6.66748M4.99998 17.6102H15C15.442 17.6102 15.8659 17.4346 16.1785 17.122C16.4911 16.8095 16.6666 16.3855 16.6666 15.9435V5.94352C16.6666 5.50149 16.4911 5.07757 16.1785 4.76501C15.8659 4.45245 15.442 4.27686 15 4.27686H4.99998C4.55795 4.27686 4.13403 4.45245 3.82147 4.76501C3.50891 5.07757 3.33331 5.50149 3.33331 5.94352V15.9435C3.33331 16.3855 3.50891 16.8095 3.82147 17.122C4.13403 17.4346 4.55795 17.6102 4.99998 17.6102Z" stroke="#9E9E9E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  // 	</svg>
  // 	,
  // },
  {
    name: "Laporan",
    path: "/laporan",
    auth: authRoles.admin,
    icon: <ClipboardText style={{ marginBottom: "15px" }} />,
    // badge: {
    //   color: "danger",
    //   value: "coming soon",
    // },
    // disabled: true,
  },
  {
    name: "Hitung Cepat",
    path: "/hitung_cepat",
    auth: authRoles.admin,
    icon: <Clock style={{ marginBottom: "15px" }} />,
    // badge: {
    //   color: "danger",
    //   value: "coming soon",
    // },
    // disabled: true,
  },

  {
    name: "Pengaturan",
    path: "/pengaturan",
    auth: authRoles.admin,
    icon: <Gear style={{ marginBottom: "15px" }} />,
  },
  // {
  //   name: "Sebaran Suara",
  //   path: "/sebaran_suara",
  //   auth: authRoles.admin,
  //   icon: <MapTrifold style={{marginBottom: "15px"}} />,
  // },
  {
    name: "Pembayaran",
    path: "/pembayaran",
    auth: authRoles.guest,
    icon: <Wallet style={{ marginBottom: "15px" }} />,
  },
];
