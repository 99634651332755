import * as React from 'react'
import { Card, Grid } from '@material-ui/core'
import GeneralButton from '../GeneralButton'

const CardRangkumanPartai = ({
  nama = '',
  image = '',
  suara_caleg = 0,
  suara_partai = 0,
  dataKota = [],
  dataCaleg = [],
  onClick = ''
}) => {
  return (
    <Card
      className='w-100 overflow-auto p-24'
      elevation={6}
      style={{
        boxShadow: '0px 2px 25px 0px rgba(0, 0, 0, 0.05)',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column'
      }}
    >
      <Grid
        container
        spacing={3}
        className='mb-4 px-2'
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid item lg={4} md={4} className='ps-0 text-center'>
          <img src={image} alt='Partai' width={60} height={60} />
        </Grid>
        <Grid item lg={8} md={8} className='ps-1'>
          <h2 className='fs-20 fw-600'>{nama}</h2>
        </Grid>
        <Grid container className='flex justify-content-center gap-2'>
          <Grid item className='ps-1'>
            <h2 className='fs-16 fw-600 text-center'>{suara_caleg}</h2>
            <p className='fs-14 fw-400 text-center'>Suara Caleg</p>
          </Grid>
          <Grid item className='ps-1'>
            <h2 className='fs-16 fw-600 text-center'>{suara_partai}</h2>
            <p className='fs-14 fw-400 text-center'>Suara Partai</p>
          </Grid>
        </Grid>

        <Grid item lg={12} md={12} xs={12} className='ps-0 pb-0'>
          <h2 className='fs-14 fw-400 text-grey'>Kota</h2>
          <hr />
        </Grid>

        {dataKota.map(item => (
          <Grid container className='flex justify-content-between'>
            <Grid item key={item.kota_kab}>
              <h2 className='fs-16 fw-400 capitalize'>{item.kota_kab}</h2>
            </Grid>
            <Grid item key={item.total}>
              <h2 className='fs-16 fw-600 text-right'>{item.suara_partai}</h2>
            </Grid>
          </Grid>
        ))}

        <Grid item lg={12} md={12} xs={12} className='ps-0 pb-0'>
          <h2 className='fs-14 fw-400 text-grey'>Suara Caleg Terbanyak</h2>
          <hr />
        </Grid>
        {dataCaleg.map(item => (
          <Grid
            container
            className='flex justify-content-between align-items-center'
          >
            <Grid item lg={9} md={9} sm={9} xs={9} key={item.nama_caleg}>
              <h2 className='fs-16 fw-400 capitalize'>{item.nama_caleg}</h2>
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={3} key={item.suara_caleg}>
              <h2 className='fs-16 fw-600 text-right'>{item.suara_caleg}</h2>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <GeneralButton
        variant='outlined'
        color='primary'
        text='Lihat Data'
        otherClass='w-full radius-10'
        onClick={onClick}
        border='1px solid #2EC5FF'
        textColor='#2EC5FF'
      />
    </Card>
  )
}

export default CardRangkumanPartai
