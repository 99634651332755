/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Grid,
  TextField,
  InputAdornment,
  Icon,
  Hidden,
  Box,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GeneralButton from "app/components/GeneralButton";
import {
  getGrafikRekapSuara,
  getRangkumanPartai,
} from "app/redux/actions/PemiluTerakhirActions";
import DoughnutChart from "./chart/DoughnutChart";
import { CardRangkumanPartai } from "app/components";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ArrowCircleLeft } from "@phosphor-icons/react";
import CircularProgress from "@mui/material/CircularProgress";
import GreenButton from "app/components/GreenButton";

const RekapSuara = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [state, setState] = useState({
    loading: true,
    search: "",
  });
  const [suaraPartaiTerbanyak, setSuaraPartaiTerbanyak] = useState([]);
  const [loadingDataCaleg, setLoadingDataCaleg] = useState(true);
  const [loadingDataPartai, setLoadingDataPartai] = useState(true);
  const [loadingRangkumanPartai, setLoadingRangkumanPartai] = useState(true);

  const getData = () => {
    dispatch(getGrafikRekapSuara(id));
    dispatch(getRangkumanPartai(id));
  };

  useEffect(() => {
    getData();
  }, []);

  const {
    detailRekapSuara,
    suara_caleg_terbanyak,
    suara_partai_terbanyak,
    rangkumanPartai,
  } = useSelector((state) => state.pemilu_terakhir);

  // console.log('detailRekapSuara', detailRekapSuara)
  // console.log('rangkumanPartai', rangkumanPartai)
  // console.log('suara_caleg_terbanyak', suara_caleg_terbanyak)

  useEffect(() => {
    const suaraPartaiList = rangkumanPartai.sort(
      (a, b) => b.suara_partai - a.suara_partai
    );
    const suaraPartai = suaraPartaiList.slice(0, 3);
    const filterSuaraPartai = suaraPartai.map((item) => ({
      ...item,
      name: item.partai,
      jumlah_suara: item.suara_partai,
      dapil: item.dapil,
      value: item.suara_partai,
    }));

    if (suara_caleg_terbanyak.length > 0) {
      setLoadingDataCaleg(false);
    }

    setSuaraPartaiTerbanyak(filterSuaraPartai);
    if (rangkumanPartai.length > 0) {
      setLoadingDataPartai(false);
      setLoadingRangkumanPartai(false);
    }
  }, [rangkumanPartai, suara_caleg_terbanyak]);

  console.log(suara_caleg_terbanyak);

  return (
    <div className="p-40">
      <Grid
        container
        spacing={3}
        className="mb-2 d-flex justify-content-center align-items-center"
      >
        <Grid item lg={6} md={6} xs={12} sm={6}>
          <h2 className="font-size-32 fw-700 fw-bold">
            Rekap Suara {detailRekapSuara?.dapil_nama}
          </h2>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          sm={6}
          spacing={2}
          container
          justify="flex-end"
        >
          <Grid item>
            <GreenButton
              text="Kembali"
              onClick={() => history.push("/pemilu_terakhir")}
              variant="contained"
            />
          </Grid>
        </Grid>
      </Grid>

      <Card className="w-100 overflow-auto p-38 pb-0 radius-10" elevation={6}>
        <Grid
          container
          spacing={3}
          className="mb-2 px-2 d-flex justify-content-between align-items-center"
        >
          <Grid item lg={12} md={12} sm={12} className="ps-0">
            <h2 className="fs-24 fw-600">
              Grafik suara terbanyak caleg dan partai
            </h2>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} className="ps-0">
            <Card
              className="w-100 overflow-auto p-24"
              elevation={6}
              style={{ boxShadow: "0px 2px 25px 0px rgba(0, 0, 0, 0.05)" }}
            >
              <div>
                <p className="text-grey">Statistic</p>
                <h2 className="fs-20 fw-600">Suara Caleg Terbanyak</h2>
              </div>
              <hr className="my-4" />
              {loadingDataCaleg ? (
                <div
                  style={{
                    position: "relative",
                    minHeight: "250px",
                  }}
                >
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "45%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                </div>
              ) : suara_caleg_terbanyak.length === 0 ? (
                <div
                  style={{
                    position: "relative",
                    minHeight: "250px",
                  }}
                >
                  <h2 className="fs-20 text-center">
                    Data Caleg tidak tersedia.
                  </h2>
                </div>
              ) : (
                <DoughnutChart
                  height="250px"
                  color={["#2EC5FF", "#BBFF92", "#B7EBFF"]}
                  data={suara_caleg_terbanyak}
                />
              )}
              <GeneralButton
                variant="contained"
                color="primary"
                text="Lihat Data"
                otherClass="w-full radius-10"
                onClick={() =>
                  // history.push(
                  //   "/pemilu_terakhir/suara_caleg/" + detailRekapSuara.dapil
                  // )
                  history.push({
                    pathname:
                      "/pemilu_terakhir/suara_caleg/" + detailRekapSuara.dapil,
                    state: { suara_caleg_terbanyak, detailRekapSuara },
                  })
                }
              />
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} className="ps-0">
            <Card
              className="w-100 overflow-auto p-24"
              elevation={6}
              style={{ boxShadow: "0px 2px 25px 0px rgba(0, 0, 0, 0.05)" }}
            >
              <div>
                <p className="text-grey">Statistic</p>
                <h2 className="fs-20 fw-600">Suara Partai Terbanyak</h2>
              </div>
              <hr className="my-4" />
              {loadingDataPartai ? (
                <div
                  style={{
                    position: "relative",
                    minHeight: "250px",
                  }}
                >
                  <CircularProgress
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "45%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                </div>
              ) : suaraPartaiTerbanyak.length === 0 ? (
                <div
                  style={{
                    position: "relative",
                    minHeight: "250px",
                  }}
                >
                  <h2 className="fs-20 text-center">
                    Data Partai tidak tersedia.
                  </h2>
                </div>
              ) : (
                <DoughnutChart
                  height="250px"
                  color={["#BBFF92", "#2EC5FF", "#B7EBFF"]}
                  data={suaraPartaiTerbanyak}
                />
              )}
              <GeneralButton
                variant="contained"
                color="primary"
                text="Lihat Data"
                otherClass="w-full radius-10"
                onClick={() =>
                  // history.push(
                  //   '/pemilu_terakhir/suara_partai/' + detailRekapSuara.dapil
                  // )
                  history.push({
                    pathname:
                      "/pemilu_terakhir/suara_partai/" + detailRekapSuara.dapil,
                    state: { suaraPartaiTerbanyak, detailRekapSuara },
                  })
                }
              />
            </Card>
          </Grid>
        </Grid>
      </Card>
      <Card
        className="w-100 overflow-auto p-38 pb-0 mt-5 radius-10"
        elevation={6}
      >
        <Grid
          container
          spacing={3}
          className="mb-2 px-2 d-flex justify-content-between align-items-center"
        >
          <Grid item lg={8} md={8} sm={8} xs={12} className="ps-0">
            <h2 className="fs-24 fw-600">Rangkuman Partai</h2>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12} className="ps-0">
            <TextField
              size="small"
              variant="outlined"
              color="primary"
              className="items-center background-white w-full"
              placeholder={"Cari"}
              onChange={(e) => setState({ ...state, search: e.target.value })}
              value={state.search}
              //   onKeyDown={submitSearch}
              name="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon color="disabled">search</Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid container spacing={2} className="mt-4" alignItems="stretch">
            {loadingRangkumanPartai ? (
              <Box mx="auto">
                <CircularProgress />
              </Box>
            ) : rangkumanPartai.length === 0 ? (
              <div>
                <h2 className="fs-20 text-center">
                  Data Partai tidak tersedia.
                </h2>
              </div>
            ) : (
              rangkumanPartai
                .filter((item) =>
                  item?.partai
                    ?.toLowerCase()
                    .includes(state.search.toLowerCase())
                )
                .map((item) => (
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    className="ps-0"
                    key={item}
                    style={{ display: "flex" }}
                  >
                    <CardRangkumanPartai
                      image={item.partai_img}
                      nama={item.partai}
                      suara_caleg={item.suara_caleg}
                      suara_partai={item.suara_partai}
                      dataKota={item.kota}
                      dataCaleg={item.data_suara_caleg}
                      onClick={() =>
                        history.push(
                          "/pemilu_terakhir/rangkuman_partai/" +
                            item.code +
                            "/" +
                            item.dapil
                        )
                      }
                    />
                  </Grid>
                ))
            )}
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default RekapSuara;
