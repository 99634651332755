/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useHistory, useParams, Link } from "react-router-dom";
import GeneralButton from "app/components/GeneralButton";
import InputFileImg from "app/components/Input/InputFileImg";
import {
  getDetailPengguna,
  updatePengguna,
} from "app/redux/actions/PengaturanActions";
import { ArrowCircleLeft } from "@phosphor-icons/react";
import GreenButton from "app/components/GreenButton";
import { showError, showSuccess } from "app/components/sweetalertService";

const EditPengguna = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const [state, setState] = useState({
    users_code: "",
    name: "",
    usersname: "",
    email: "",
    phone: "",
    akses_akun: "",
    password: "",
    foto_profile_preview: "",
    foto_profile: "",
    users_type: "",
    updated: false,
  });

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [errorField, setErrorField] = useState({});

  const handleSubmit = () => {
    updatePengguna(state)
      .then((res) => {
        showSuccess("Data berhasil disimpan");
        history.push("/pengaturan?tab=pengguna");
      })
      .catch((err) => {
        showError("Data gagal disimpan!");
        setErrorField(err.response.data.data);
      });
    // .then((res) => {
    //   Swal.fire({
    //     title:
    //       '<div class="custom-swal-title">Pengguna berhasil ditambahkan</div>',
    //     icon: "success",
    //     buttonsStyling: false,
    //     customClass: {
    //       confirmButton: "custom-success-button",
    //     },
    //   });
    //   history.push("/pengaturan?tab=pengguna");
    // })
    // .catch((err) => {
    //   Swal.fire({
    //     title:
    //       '<div class="custom-swal-title">Pengguna gagal ditambahkan!</div>',
    //     icon: "error",
    //     buttonsStyling: false,
    //     customClass: {
    //       confirmButton: "custom-error-button",
    //     },
    //   });
    //   setErrorField(err.response.data.data);
    // });
  };

  const handleChangeFoto = (file, path) => {
    setState((prev) => ({
      ...prev,
      foto_profile: file,
      foto_profile_preview: path,
    }));
  };

  useEffect(() => {
    dispatch(getDetailPengguna(id));
  }, []);

  const { detailPengguna } = useSelector((state) => state.pengaturan);

  useEffect(() => {
    setState({
      users_code: detailPengguna.users_code,
      name: detailPengguna.name,
      usersname: detailPengguna.usersname,
      email: detailPengguna.email,
      phone: detailPengguna.phone,
      akses_akun: detailPengguna.akses_akun,
      foto_profile_preview: detailPengguna.foto_profile,
      users_type: detailPengguna.users_type,
      updated: true,
    });
  }, [detailPengguna]);

  // console.log(detailPengguna);

  return (
    <div className="analytics m-sm-30 mt-7">
      <Grid
        container
        spacing={3}
        className="mb-2 d-flex justify-content-center align-items-center"
      >
        <Grid item lg={6} md={6} xs={12} sm={6}>
          <h2 className="font-size-32 fw-700 fw-bold">Tambah Team</h2>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          sm={6}
          spacing={2}
          container
          justify="flex-end"
        >
          <Grid item>
            <GreenButton
              text="Kembali"
              onClick={() => history.push("/pengaturan?tab=pengguna")}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <GreenButton
              text="Simpan Data"
              onClick={handleSubmit}
              variant="contained"
            />
          </Grid>
        </Grid>
      </Grid>

      <Card style={{ padding: "20px", marginBottom: "20px" }}>
        <Grid container className="mt-2 p-2 " spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h3 className="fw-bold fs-24">Pengguna Detail</h3>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="image">
              <h5 className="font-size-14">Foto Profile</h5>
              <InputFileImg
                noPadding={true}
                name="foto_profile"
                uploadFoto={handleChangeFoto}
                preview={state.foto_profile_preview}
                acceptFile="image"
              />
            </InputLabel>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            {/*  */}
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="nama">
              <h5 className="font-size-14">Nama</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.name}
              name="name"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Nama Lengkap"
              variant="outlined"
              onChange={handleChange}
              type="text"
              error={errorField?.name !== ""}
              helperText={errorField?.name}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="usersname">
              <h5 className="font-size-14">Username</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.usersname}
              name="usersname"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Username"
              variant="outlined"
              onChange={handleChange}
              type="text"
              error={errorField?.usersname !== ""}
              helperText={errorField?.usersname}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="email">
              <h5 className="font-size-14">Email</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.email}
              name="email"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Email"
              variant="outlined"
              onChange={handleChange}
              type="text"
              error={errorField?.email !== ""}
              helperText={errorField?.email}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="phone">
              <h5 className="font-size-14">Nomor Ponsel</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.phone}
              name="phone"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="No Ponsel"
              variant="outlined"
              onChange={handleChange}
              type="number"
              error={errorField?.phone !== ""}
              helperText={errorField?.phone}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="password">
              <h5 className="font-size-14">Password</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.password}
              name="password"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Password"
              variant="outlined"
              onChange={handleChange}
              type="password"
              error={errorField?.password !== ""}
              helperText={errorField?.password}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="users_type">
              <h5 className="font-size-14">Tingkat</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="users_type"
              value={state.users_type}
              onChange={handleChange}
              variant="outlined"
              className="w-100"
              name="users_type"
              displayEmpty
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="relawan">Relawan</MenuItem>
            </Select>
            <small className="text-danger">{errorField.users_type}</small>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default EditPengguna;
