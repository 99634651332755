import {
  Card,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import Swal from "sweetalert2";
import InputFileImg from "app/components/Input/InputFileImg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CalendarIcon } from "app/components/Icons";
import { ArrowCircleLeft, CheckCircle } from "@phosphor-icons/react";
import {
  addArusKas,
  getKategoriArusKas,
} from "app/redux/actions/ArusKasActions";
import { useHistory, Link } from "react-router-dom";
import { useEffect } from "react";
import { formatDate } from "helpers/formatDate";
import InputAdornment from "@material-ui/core/InputAdornment";
import GeneralButton from "app/components/GeneralButton";
import { updateProfile } from "app/redux/actions/AuthAction";
import apiAuthService from "app/services/apiAuthService";

const ProfileUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [state, setState] = useState({
    name: "",
    usersname: "",
    email: "",
    phone: "",
    akses_akun: "",
    password: "",
    foto_profile_preview: "",
    foto_profile: "",
  });

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    updateProfile(state)
      .then((res) => {
        Swal.fire({
          title:
            '<div class="custom-swal-title">Profil berhasil diperbarui!</div>',
          buttonsStyling: false,
          icon: "success",
          customClass: {
            confirmButton: "custom-success-button",
          },
        });

        const token = localStorage.getItem("jwt_token");
        apiAuthService.loginWithToken(token);
      })
      .catch((err) => {
        Swal.fire({
          title:
            '<div class="custom-swal-title">Profil gagal diperbarui!</div>',
          buttonsStyling: false,
          icon: "error",
          customClass: {
            confirmButton: "custom-error-button",
          },
        });
      });
  };

  const handleChangeFoto = (file, path) => {
    setState((prev) => ({
      ...prev,
      foto_profile: file,
      foto_profile_preview: path,
    }));
  };

  const user = useSelector((state) => state.user);

  useEffect(() => {
    // console.log(user);
    let newState = {
      name: user.name,
      usersname: user.usersname,
      email: user.email,
      phone: user.phone,
      akses_akun:
        user.role.charAt(0).toUpperCase() + user.role.slice(1).toLowerCase(),
      foto_profile_preview: user.foto_profile,
      foto_profile: "",
    };
    setState({
      ...newState,
    });
  }, [user]);

  const [errorField, setErrorField] = useState({});

  return (
    <div className="p-40">
      <h1 className="fs-2 fw-700 mb-3">Profile</h1>

      <Card style={{ padding: "20px", marginBottom: "20px" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <h3 className="fw-bold fs-22">Ubah Profile</h3>
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6} className="flex flex-end">
            <GeneralButton
              variant="contained"
              color="primary"
              text="Simpan Data"
              icon={<CheckCircle size={28} />}
              onClick={handleSubmit}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="image">
              <h5 className="font-size-14">Foto Profile</h5>
              <InputFileImg
                noPadding={true}
                name="foto_profile"
                uploadFoto={handleChangeFoto}
                preview={state.foto_profile_preview}
                acceptFile="image"
              />
            </InputLabel>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            {/*  */}
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="nama">
              <h5 className="font-size-14">Nama</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.name}
              name="name"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Nama Lengkap"
              variant="outlined"
              onChange={handleChange}
              type="text"
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="usersname">
              <h5 className="font-size-14">Username</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.usersname}
              name="usersname"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Username"
              variant="outlined"
              onChange={handleChange}
              type="text"
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="email">
              <h5 className="font-size-14">Email</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.email}
              name="email"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Email"
              variant="outlined"
              onChange={handleChange}
              type="text"
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="no_telp">
              <h5 className="font-size-14">Nomor Ponsel</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.phone}
              name="phone"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="No Ponsel"
              variant="outlined"
              onChange={handleChange}
              type="number"
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="akses_akun">
              <h5 className="font-size-14">Akses Akun</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.akses_akun}
              name="akses_akun"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Akses Akun"
              variant="outlined"
              onChange={handleChange}
              type="text"
              disabled
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="password">
              <h5 className="font-size-14">Password</h5>
            </InputLabel>
            <TextField
              // required={true}
              disabled={true}
              size="small"
              value="*********"
              name="password"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              placeholder="Password"
              variant="outlined"
              onChange={handleChange}
              type="password"
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    kategori: state.arusKas.kategori,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getKategoriArusKas: () => dispatch(getKategoriArusKas()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUser);
