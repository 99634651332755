import {
    GET_PROVINCE
} from "../constant.js";

const initialState = {
    dataProvince: [],
};

const ProvinceReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_PROVINCE: {
            return {
                ...state,
                dataProvince: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default ProvinceReducer;
