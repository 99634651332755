import {
  GET_SURVEY
} from "../constant.js";

const initialState = {
  dataSurvey: [
    {
      judul: "Survey Elektabilitas Suara",
      catatan: "Elektabilitas",
      status: "Aktif",
    },
    {
      judul: "Survey Elektabilitas Suara",
      catatan: "Elektabilitas",
      status: "Nonaktif",
    },
    {
      judul: "Survey Elektabilitas Suara",
      catatan: "Elektabilitas",
      status: "Nonaktif",
    },
    {
      judul: "Survey Elektabilitas Suara",
      catatan: "Elektabilitas",
      status: "Aktif",
    },
    {
      judul: "Survey Elektabilitas Suara",
      catatan: "Elektabilitas",
      status: "Aktif",
    },
    {
      judul: "Survey Elektabilitas Suara",
      catatan: "Elektabilitas",
      status: "Nonaktif",
    },
    {
      judul: "Survey Elektabilitas Suara",
      catatan: "Elektabilitas",
      status: "Nonaktif",
    },
    {
      judul: "Survey Elektabilitas Suara",
      catatan: "Elektabilitas",
      status: "Aktif",
    },
    
  ],
};

const SurveyReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_SURVEY: {
      return {
        ...state,
        // dataSurvey: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default SurveyReducer;
