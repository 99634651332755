import { API, setAuthToken } from "config/API.js";
import {
    GET_PEMBAYARAN,
    GET_DETAIL_PEMBAYARAN,
    GET_TOTAL_PEMBAYARAN
} from "../constant.js";

export const getDataPembayaran = (params = "") => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/user/pembayaran/list?${new URLSearchParams(params)}`)
            .then((res) => {
                dispatch({
                    type: GET_PEMBAYARAN,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_PEMBAYARAN,
                    payload: [],
                });
            });
    };
};

export const getDetailPembayaran = (code) => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/user/pembayaran/detail/${code}`)
            .then((res) => {
                dispatch({
                    type: GET_DETAIL_PEMBAYARAN,
                    payload: res.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_DETAIL_PEMBAYARAN,
                    payload: [],
                });
            });
    };
};
export const getTotalPembayaran = () => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/user/pembayaran/total`)
            .then((res) => {
                dispatch({
                    type: GET_TOTAL_PEMBAYARAN,
                    payload: res.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_TOTAL_PEMBAYARAN,
                    payload: [],
                });
            });
    };
};
