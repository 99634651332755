import { GET_LAPORAN, GET_DETAIL_LAPORAN } from "../constant.js";

const initialState = {
  dataLaporan: [
    // {
    //   laporan_code: "LAP202401",
    //   laporan_tanggal: "2020-01-01",
    //   laporan_waktu: "10:00",
    //   laporan_nama: "Laporan Subjek",
    //   laporan_status: "selesai",
    //   laporan_jumlah_relawan: 7500,
    // },
    // {
    //   laporan_code: "LAP202402",
    //   laporan_tanggal: "2020-01-01",
    //   laporan_waktu: "10:00",
    //   laporan_nama: "Laporan Subjek",
    //   laporan_status: "proses",
    //   laporan_jumlah_relawan: 7500,
    // },
    // {
    //   laporan_code: "LAP202403",
    //   laporan_tanggal: "2020-01-01",
    //   laporan_waktu: "10:00",
    //   laporan_nama: "Laporan Subjek",
    //   laporan_status: "baru",
    //   laporan_jumlah_relawan: 7500,
    // },
  ],
  dataDetailLaporan: {
    // laporan_code: "LAP202403",
    // laporan_tanggal: "2020-01-01",
    // laporan_waktu: "10:00",
    // laporan_nama: "Laporan Subjek",
    // laporan_status: "baru",
    // laporan_jumlah_relawan: 7500,
    // laporan_deskripsi: "Deskripsi Laporan",
  },
  dataLaporanPagination: {
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 3,
  },
};

const LaporanReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_LAPORAN: {
      return {
        ...state,
        dataLaporan: action?.payload.data || [],
        dataLaporanPagination: action?.payload.pagination || [],
      };
    }
    case GET_DETAIL_LAPORAN: {
      return {
        ...state,
        dataDetailLaporan: action?.payload.data || {},
      };
    }
    default: {
      return state;
    }
  }
};

export default LaporanReducer;
