import ArusKas from './ArusKas'
import AddArusKas from './AddArusKas'
import EditArusKas from './EditArusKas'
import { Edit } from '@material-ui/icons'
import DetailArusKas from './DetailArusKas'
import { authRoles } from 'app/auth/authRoles'

const ArusKasRoutes = [
  {
    path: '/arus_kas',
    exact: true,
    component: ArusKas,
    auth: authRoles.admin,
  },
  {
    path: '/arus_kas/tambah',
    exact: true,
    component: AddArusKas,
    auth: authRoles.admin,
  },
  {
    path: '/arus_kas/edit/:arus_kas_code',
    exact: true,
    component: EditArusKas,
    auth: authRoles.admin,
  },
  {
    path: '/arus_kas/:arus_kas_code',
    exact: true,
    component: DetailArusKas,
    auth: authRoles.admin,
  }
]

export default ArusKasRoutes
