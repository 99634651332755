import {
    GET_LOGISTIK_SATUAN_UNIT,
} from '../constant.js'

const initialState = {
    dataLogistikSatuanUnit: [],
}

const LogistikSatuanUnitReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_LOGISTIK_SATUAN_UNIT: {
            return {
                ...state,
                dataLogistikSatuanUnit: action?.payload,
            }
        }
        default: {
            return state
        }
    }
}

export default LogistikSatuanUnitReducer
