import { API, setAuthToken } from "config/API.js";
import {
  GET_TPS
} from "../constant.js";

export const getTPS = (params = "") => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`/user/kategori/nomortps` + params)
      .then((res) => {
        dispatch({
          type: GET_TPS,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_TPS,
          payload: [],
        });
      });
  };
};

export const addTPS = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/kategori/nomortps/tambah", body);
};

export const updateTPS = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/kategori/nomortps/update", body);
};

export const deleteTPS = (body) => {
  const token = localStorage.getItem("jwt_token");
  setAuthToken(token);
  return API.post("/user/kategori/nomortps/delete", body);
};