import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'

function createQueryString (queryObject = {}) {
  let queryString = Object.keys(queryObject)
    .filter(
      key =>
        queryObject[key] &&
        !(Array.isArray(queryObject[key]) && !queryObject[key].length)
    )
    .map(key => {
      return Array.isArray(queryObject[key])
        ? queryObject[key]
            .map(
              item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`
            )
            .join('&')
        : `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`
    })
    .join('&')
  return queryString ? `?${queryString}` : ''
}

function queryStringToObject (queryString = '', options = {}) {
  let queryObject = {}
  queryString &&
    decodeURIComponent(queryString.replace('?', ''))
      .split('&')
      .map(itemString => {
        let [itemKey, itemValue] = itemString.split('=')
        if (options.hasOwnProperty(itemKey)) {
          if (!queryObject[itemKey] && Array.isArray(options[itemKey])) {
            queryObject[itemKey] = []
          }
          Array.isArray(options[itemKey])
            ? queryObject[itemKey].push(itemValue)
            : (queryObject[itemKey] =
                typeof options[itemKey] === 'number'
                  ? parseInt(itemValue)
                  : itemValue)
        }
      })
  return queryObject
}

function useQueryParams (options, defaultValues = {}) {
  const { search } = useLocation()
  const history = useHistory()

  const queryParams = React.useMemo(() => {
    const queryObj = queryStringToObject(search, options)
    return { ...defaultValues, ...queryObj }
  }, [search])

  function setQueryParams (queryObj) {
    history.push({
      search: createQueryString(queryObj)
    })
  }

  return { queryParams, setQueryParams }
}

export { useQueryParams }
