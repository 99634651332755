/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Dialog, Grid, Select, MenuItem, InputLabel } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getDistrict } from "app/redux/actions/DistrictActions";
import { getVillages } from "app/redux/actions/VillagesActions";
import { getCity } from "app/redux/actions/CityActions";
import { useState } from "react";
import { useEffect } from "react";
import GreenButton from "app/components/GreenButton";
import InputFileImg from "app/components/Input/InputFileImg";
import { getDataDapil } from "app/redux/actions/PengaturanActions";
import GeneralButton from "app/components/GeneralButton";

const ModalImport = ({ open, handleClose, handleSubmit }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    provinsi: "",
    kota_kab: "",
    kecamatan: "",
    kelurahan: "",
    file: "",
    file_preview: "",
    dapilValue: "",
  });
  const [selectedTab, setSelectedTab] = useState(0);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const getData = () => {
    dispatch(getDataDapil());
  };

  useEffect(() => {
    getData();
    setLoadingSubmit(false);
  }, []);

  const handleChangeProvince = (e) => {
    e.persist();
    let value = e.target.value;
    value = value.split("|");
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: value[0],
      dapil: value[1],
      dapilValue: e.target.value,
    }));
    dispatch(getCity({ province_code: value[0] }));
  };

  const handleChangeKotaKab = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    dispatch(getDistrict({ city_code: e.target.value }));
  };

  const handleChangeDistrict = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    dispatch(getVillages({ districts_code: e.target.value }));
  };

  const handleChangeFile = (file, path, state) => {
    setState((prev) => ({
      ...prev,
      [state]: file,
      [`${state}_preview`]: path,
    }));
  };

  const handleUnggah = async () => {
    await setLoadingSubmit(true);
    const params = {
      ...state,
      type: selectedTab === 0 ? "TPS" : "Suara Partai",
    };
    await handleSubmit(params);
    // await setLoadingSubmit(false);
  };

  // const { dataProvince } = useSelector((state) => state.province);
  const { dataCity } = useSelector((state) => state.city);
  const { dataDistrict } = useSelector((state) => state.district);
  const { dataVillages } = useSelector((state) => state.villages);
  const { dataDapil } = useSelector((state) => state.pengaturan);

  return (
    <Dialog open={open} maxWidth={"md"} onClose={handleClose}>
      <div className="p-4">
        <Grid className="mb-16" container spacing={2}>
          {/* <Grid item lg={6} md={6} xs={6} sm={6}>
            <TabButton
              selected={selectedTab === 0}
              onClick={() => setSelectedTab(0)}
              title='Data TPS'
              otherClass='w-full'
              icon=' '
            />
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <TabButton
              selected={selectedTab === 1}
              onClick={() => setSelectedTab(1)}
              title='Data Suara Partai'
              otherClass='w-full'
              icon=' '
            />
          </Grid> */}

          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h2 className="fs-24 mt-20 fw-600">
              Import {selectedTab ? " Suara Partai" : " TPS"}
            </h2>
          </Grid>

          <Grid item lg={3} md={3} xs={6} sm={6}>
            <InputLabel htmlFor="provinsi">
              <h5 className="fs-12 text-muted fw-400">Dapil</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="provinsi"
              value={state.dapilValue}
              onChange={handleChangeProvince}
              variant="outlined"
              className="w-100"
              name="provinsi"
              displayEmpty
            >
              <MenuItem key={"x"} value={""}>
                Pilih Dapil
              </MenuItem>
              {dataDapil.map((item) => (
                <MenuItem
                  key={item.dapil_code}
                  value={item.province_code + "|" + item.dapil_code}
                >
                  {item.dapil_nama}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item lg={3} md={3} xs={6} sm={6}>
            <InputLabel htmlFor="kota_kab">
              <h5 className="fs-12 text-muted fw-400">Kab/Kota</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="kota_kab"
              value={state.kota_kab}
              onChange={handleChangeKotaKab}
              variant="outlined"
              className="w-100"
              name="kota_kab"
              displayEmpty
            >
              <MenuItem key={"x"} value={""}>
                Pilih Kab/Kota
              </MenuItem>
              {dataCity.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item lg={3} md={3} xs={6} sm={6}>
            <InputLabel htmlFor="kecamatan">
              <h5 className="fs-12 text-muted fw-400">Kecamatan</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="kecamatan"
              value={state.kecamatan}
              onChange={handleChangeDistrict}
              variant="outlined"
              className="w-100"
              name="kecamatan"
              displayEmpty
            >
              <MenuItem key={"x"} value={""}>
                Pilih Kecamatan
              </MenuItem>
              {dataDistrict.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item lg={3} md={3} xs={6} sm={6}>
            <InputLabel htmlFor="kelurahan">
              <h5 className="fs-12 text-muted fw-400">Kelurahan/Desa</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="kelurahan"
              value={state.kelurahan}
              onChange={handleChange}
              variant="outlined"
              className="w-100"
              name="kelurahan"
              displayEmpty
            >
              <MenuItem key={"x"} value={""}>
                Pilih Kelurahan
              </MenuItem>
              {dataVillages.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item lg={12} md={12} xs={12} sm={12}>
            <small className="fs-10 fw-500">
              *Silahkan pilih daerah terlebih dahulu untuk melakukan import
            </small>
            <InputFileImg
              noPadding={true}
              name="file"
              uploadFoto={(file, path) => handleChangeFile(file, path, "file")}
              acceptFile="excel"
            />
            <small className="fs-10 fw-600">
              *Harap pilih jenis data yang ingin di import, data TPS atau data
              Suara Partai <br />
              *Harap pilih daerah dengan sesuai <br />
              *Format file excel harus sesuai dengan template yang disediakan,
              harap unduh contoh data TPS{" "}
              <a
                href="https://docs.google.com/spreadsheets/d/1QUPJgyUy3k0yZn4iuQaSYM3s5BB4WZhlR0OAx89lL5M/edit?usp=sharing"
                target="_blank"
                without
                rel="noopener noreferrer"
              >
                Disini
              </a>{" "}
              atau contoh data Suara Caleg{" "}
              <a
                href="https://docs.google.com/spreadsheets/d/1WC8BUAdpnSZGb7iHDoZCtDOJKMDDdCNYcWPZVkleKHM/edit?usp=sharing"
                target="_blank"
                without
                rel="noopener noreferrer"
              >
                Disini
              </a>{" "}
              <br />
              *Data yang diimport akan me-replace data sebelumnya jika terdapat
              duplikat
            </small>
          </Grid>

          <Grid container spacing={2} className="mt-2">
            <Grid item lg={6} md={6} xs={6} sm={6} className="mt-4">
              <GreenButton
                variant="outlined"
                color="secondary"
                text="Batal"
                otherClass="w-full text-green"
                onClick={handleClose}
                border="#4cb050"
              />
            </Grid>
            <Grid item lg={6} md={6} xs={6} sm={6} className="mt-4">
              <GeneralButton
                variant="contained"
                color="secondary"
                text={loadingSubmit ? "Mengunggah..." : "Unggah"}
                otherClass="w-full"
                onClick={() => handleUnggah()}
                disabled={loadingSubmit || state.file === ""}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

export default ModalImport;
