import {
  GET_CATEGORY_LOGISTIK,
  GET_LOGISTIK,
  GET_PEMAKAIAN,
  GET_PESANAN,
} from "../constant.js";

const initialState = {
  dataLogistik: [],
  dataLogistikPagination: {},
  dataPemakaian: [],
  kategori: [],
  dataPemakaianPagination: {},
  dataPesanan: [
    // { tanggal: "06/03/2023", atribut: 'Atribut Bendera', jumlah: "800 buah", total_harga: 'Rp. 6.400.000', kategori: "Kaos", status: 'Dipesan' },
    // { tanggal: "06/03/2023", atribut: 'Atribut Bendera', jumlah: "800 buah", total_harga: 'Rp. 6.400.000', kategori: "Kaos", status: 'Dikirim' },
    // { tanggal: "06/03/2023", atribut: 'Atribut Bendera', jumlah: "800 buah", total_harga: 'Rp. 6.400.000', kategori: "Kaos", status: 'Diterima' },
    // { tanggal: "06/03/2023", atribut: 'Atribut Bendera', jumlah: "800 buah", total_harga: 'Rp. 6.400.000', kategori: "Kaos", status: 'Dipesan' },
  ],
  dataPesananPagination: {},
};

const LogistikReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_LOGISTIK: {
      return {
        ...state,
        dataLogistik: action?.payload.data || [],
        dataLogistikPagination: action?.payload.pagination || [],
      };
    }
    case GET_PEMAKAIAN: {
      return {
        ...state,
        dataPemakaian: action?.payload.data || [],
        dataPemakaianPagination: action?.payload.pagination || {},
      };
    }
    case GET_PESANAN: {
      return {
        ...state,
        dataPesanan: action?.payload.data || [],
        dataPesananPagination: action?.payload.pagination || {},
      };
    }
    case GET_CATEGORY_LOGISTIK: {
      return {
        ...state,
        kategori: action?.payload || [],
      };
    }
    default: {
      return state;
    }
  }
};

export default LogistikReducer;
