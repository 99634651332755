import Pembayaran from './Pembayaran'
import DetailBankPembayaran from './DetailBankPembayaran'
import { authRoles } from 'app/auth/authRoles'

const PembayaranRoutes = [
    {
        path: '/pembayaran',
        exact: true,
        component: Pembayaran,
        auth: authRoles.guest
    },
    {
        path: '/pembayaran/detail/:bank_code',
        exact: true,
        component: DetailBankPembayaran,
        auth: authRoles.guest
    }
]

export default PembayaranRoutes
