import Swal from "sweetalert2";

export const showConfirmation = async (title, text) => {
  const result = await Swal.fire({
    title,
    text,
    showCancelButton: true,
    confirmButtonText: "Yakin",
    cancelButtonText: "Batal",
    icon: "warning",
    buttonsStyling: false,
    reverseButtons: true,
    customClass: {
      confirmButton: "custom-success-button",
      cancelButton: "custom-error-button",
    },
  });

  return result.isConfirmed;
};

export const showSuccess = (text) => {
  return Swal.fire({
    title: `<div class="custom-swal-title">${text}</div>`,
    icon: "success",
    buttonsStyling: false,
    customClass: {
      confirmButton: "custom-success-button",
    },
  });
};

export const showError = (text) => {
  return Swal.fire({
    title: `<div class="custom-swal-title">${text}</div>`,
    buttonsStyling: false,
    icon: "error",
    customClass: {
      confirmButton: "custom-error-button",
    },
  });
};
