/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Dialog,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getDistrict } from "app/redux/actions/DistrictActions";
import { getVillages } from "app/redux/actions/VillagesActions";
import { getCity } from "app/redux/actions/CityActions";
import { getProvince } from "app/redux/actions/ProvinceActions";
import { useEffect } from "react";
import GreenButton from "app/components/GreenButton";

const ModalFilter = ({
  open,
  handleClose,
  state,
  handleSubmit,
  handleSearchChange,
  value,
  handleSearch,
}) => {
  const dispatch = useDispatch();

  const getData = () => {
    dispatch(getProvince());
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChangeProvince = (e) => {
    const updatedSearch = {
      ...value,
      provinsi: e.target.value,
      kota: "",
      kecamatan: "",
      desa: "",
    };

    handleSearch(updatedSearch);

    dispatch(getCity({ province_code: e.target.value }));
  };

  const handleChangeKotaKab = (e) => {
    const updatedSearch = {
      ...value,
      kota_kab: e.target.value,
      kecamatan: "",
      desa: "",
    };

    handleSearch(updatedSearch);

    dispatch(getDistrict({ city_code: e.target.value }));
  };

  const handleChangeDistrict = (e) => {
    const updatedSearch = {
      ...value,
      kecamatan: e.target.value,
      desa: "",
    };

    handleSearch(updatedSearch);

    dispatch(getVillages({ districts_code: e.target.value }));
  };

  // useEffect(() => {
  //   console.log(state)
  // }, [state])

  const { dataProvince } = useSelector((state) => state.province);
  const { dataCity } = useSelector((state) => state.city);
  const { dataDistrict } = useSelector((state) => state.district);
  const { dataVillages } = useSelector((state) => state.villages);

  return (
    <Dialog open={open} maxWidth={"xs"} onClose={handleClose}>
      <div className="p-4">
        <h2 className="fs-24 mb-20 fw-600">Silahkan Pilih Filter</h2>
        <Grid className="mb-16" container spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h2 className="fs-16 fw-500">Daerah</h2>
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <InputLabel htmlFor="provinsi">
              <h5 className="fs-12 text-muted fw-400">Provinsi</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="provinsi"
              value={value.provinsi}
              onChange={handleChangeProvince}
              variant="outlined"
              className="w-100"
              name="provinsi"
              displayEmpty
            >
              <MenuItem key={"x"} value={""}>
                Pilih Provinsi
              </MenuItem>
              {dataProvince.map((province) => (
                <MenuItem key={province.id} value={province.id}>
                  {province.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item lg={6} md={6} xs={6} sm={6}>
            <InputLabel htmlFor="kota_kab">
              <h5 className="fs-12 text-muted fw-400">Kab/Kota</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="kota_kab"
              value={value.kota_kab}
              onChange={handleChangeKotaKab}
              variant="outlined"
              className="w-100"
              name="kota_kab"
              displayEmpty
            >
              <MenuItem key={"x"} value={""}>
                Pilih Kab/Kota
              </MenuItem>
              {dataCity.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item lg={6} md={6} xs={6} sm={6}>
            <InputLabel htmlFor="kecamatan">
              <h5 className="fs-12 text-muted fw-400">Kecamatan</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="kecamatan"
              value={value.kecamatan}
              onChange={handleChangeDistrict}
              variant="outlined"
              className="w-100"
              name="kecamatan"
              displayEmpty
            >
              <MenuItem key={"x"} value={""}>
                Pilih Kecamatan
              </MenuItem>
              {dataDistrict.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item lg={6} md={6} xs={6} sm={6}>
            <InputLabel htmlFor="desa">
              <h5 className="fs-12 text-muted fw-400">Kelurahan/Desa</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="desa"
              value={value.desa}
              onChange={handleSearchChange}
              variant="outlined"
              className="w-100"
              name="desa"
              displayEmpty
            >
              <MenuItem key={"x"} value={""}>
                Pilih Kelurahan
              </MenuItem>
              {dataVillages.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <Grid className="mb-16" container spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h2 className="fs-16 fw-500">Status</h2>
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <InputLabel htmlFor="vote">
              <h5 className="fs-12 text-muted fw-400">Status</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                },
              }}
              size="small"
              labelid="vote"
              value={value.vote}
              onChange={handleSearchChange}
              variant="outlined"
              className="w-100"
              name="vote"
              displayEmpty
            >
              <MenuItem value="">Pilih Status</MenuItem>
              <MenuItem value={1}>Memilih</MenuItem>
              <MenuItem value={2}>Tidak Memilih</MenuItem>
              <MenuItem value={3}>Ragu-ragu</MenuItem>
            </Select>
          </Grid>
        </Grid>

        <Grid className="mb-16" container spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h2 className="fs-16 fw-500">Umur</h2>
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <InputLabel htmlFor="min">
              <h5 className="fs-12 text-muted fw-400">Min</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={value.min}
              name="min"
              className={`w-100`}
              inputProps={{
                style: {
                  borderRadius: 5,
                },
                min: 0,
              }}
              placeholder="0"
              variant="outlined"
              onChange={handleSearchChange}
              type="number"
            />
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <InputLabel htmlFor="max">
              <h5 className="fs-12 text-muted fw-400">Max</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={value.max}
              name="max"
              className={`w-100`}
              inputProps={{
                style: {
                  borderRadius: 5,
                },
                min: 0,
              }}
              placeholder="0"
              variant="outlined"
              onChange={handleSearchChange}
              type="number"
            />
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6} className="mt-4">
            <GreenButton
              variant="outlined"
              color="secondary"
              text="Batal"
              otherClass="w-full text-green"
              onClick={handleClose}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6} className="mt-4">
            <GreenButton
              variant="contained"
              color="secondary"
              text="Terapkan"
              otherClass="w-full"
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

export default ModalFilter;
