import React from "react";
import { Grid, Card, CardContent, Box, Typography } from "@mui/material";

const CardKandidat = ({ dashboardKandidat }) => {
  return (
    <>
      <Grid item sm={12} xs={12} md={4} lg={4}>
        <Card
          className="flex flex-column h-100"
          style={{
            padding: "15px",
            borderRadius: "10px",
          }}
        >
          <CardContent>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 24,
              }}
            >
              <img
                src={dashboardKandidat.kandidat_images}
                alt={dashboardKandidat.kandidat_nama}
                style={{
                  width: "96px",
                  height: "96px",
                  borderRadius: "50%",
                  objectPosition: "top",
                  objectFit: "cover",
                }}
              />
              <Typography
                gutterBottom
                component="div"
                style={{
                  fontWeight: "bold",
                  marginTop: "8px",
                  fontSize: "24px",
                }}
              >
                {dashboardKandidat.kandidat_nama}
              </Typography>
            </Box>
            <Grid
              container
              spacing={3}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item lg={6}>
                <div
                  style={{
                    padding: "10px",
                    height: "44px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#E5F8FF",
                  }}
                >
                  <span
                    style={{
                      marginRight: "5px",
                    }}
                  >
                    <img
                      src="assets/images/icons/profile.svg"
                      alt="profile icon"
                      width={24}
                      height={24}
                    />
                  </span>
                  <span className="fs-6 fw-600 text-primary-50">
                    {dashboardKandidat.tingkat_calon_tetap_nama}
                  </span>
                </div>
              </Grid>
              <Grid item lg={6}>
                <div
                  style={{
                    padding: "10px",
                    height: "44px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#F6FFF1",
                  }}
                >
                  <span className="fs-6 fw-600 text-green-50">
                    {dashboardKandidat.partai_nama || ""}
                  </span>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default CardKandidat;
