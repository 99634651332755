import {
  Box,
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
  createStyles,
  Hidden,
  InputLabel,
  InputAdornment,
  IconButton,
  Select,
  MenuItem
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
// import CardAkunRegister from "./components/CardAkunRegister";
// import CardCalegRegister from "./components/CardCalegRegister";
import { CustomButton } from "app/components";
import { register } from "app/redux/actions/AuthAction";
import { getDataPartaiPublic, getDataDapilPublic, getDataCalonTetapSebagaiPublic } from "app/redux/actions/PengaturanActions";
import { showError, showSuccess } from "app/components/sweetalertService";

const useStyles = makeStyles(({ palette, ...theme }) =>
  createStyles({
    logo: {
      maxWidth: 188,
      marginBottom: 20,
      marginTop: 10,
    },
    containerForm: {
      padding: "1rem",
      [theme.breakpoints.up("md")]: {
        padding: "1rem 3rem",
      },
    },
    bgPage: {
      backgroundImage: "url('/assets/images/illustrations/bg-page.webp')",
      backgroundRepeat: "repeat",
    },
  })
);

const Register = ({ }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [registerMode, setRegisterMode] = React.useState("akun");
  const [loading, setLoading] = React.useState(false);
  const [errorField, setErrorField] = useState({});
  const [state, setState] = useState({
    email: "",
    password: "",
    fullname: "",
    usersname: "",
    phone: "",
    kandidatname: "",
    alamatkandidat: "",
    dapil: "",
    tingkat: "",
    partai: "",
    nourut: ""
  });

  const { dataDapil, dataCalonTetap, partaiSekarang } = useSelector(
    (state) => state.pengaturan
  );

  const getData = () => {
    dispatch(getDataDapilPublic());
    dispatch(getDataCalonTetapSebagaiPublic());
    dispatch(getDataPartaiPublic());
  };

  useEffect(() => {
    getData()
  }, [dispatch]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeNoUrut = (e) => {
    e.persist();
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(e.target.value)) {
      setState((prevState) => ({
        ...prevState,
        nourut: e.target.value,
      }));
    }
  };

  const handleChangeNoPhone = (e) => {
    e.persist();
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(e.target.value)) {
      setState((prevState) => ({
        ...prevState,
        phone: e.target.value,
      }));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleSubmit = () => {
    register(state)
      .then((res) => {
        showSuccess("Berhasil mendaftar");
        history.push("/login");
      })
      .catch((err) => {
        showError("Pendaftaran gagal!");
        console.log('res error', err)
        setErrorField(err.response.data.data);
      });
  };

  const handleCalegMode = () => {
    setRegisterMode('caleg')
  };

  const classes = useStyles();
  // console.log('dapil', dataDapil)
  // console.log('calon tetap', dataCalonTetap)
  // console.log('partai', partaiSekarang)

  return (
    <Grid container justifyContent="center" className="flex flex-column" alignItems="center">
      <Grid item sm={12} xs={12} lg={12} md={12}>
        {/* Desktop Mode */}
        <Hidden xsDown>
          <Card
            className="px-24 py-8 mt-3 rounded-1 border flex flex-column"
            justifyContent="center"
            alignItems="center"
            style={{
              width: "535px",
              overflow: "auto"
            }}
          >
            <Grid container justifyContent="center" className="flex flex-column mt-2" alignItems="center">
              <Grid item className="mb-4">
                <Link to="/">
                  <img
                    src="/assets/images/logos/logo_pemilu_now.png"
                    alt="Pemilu Now"
                    className={classes.logo}
                    style={{ margin: "0 auto" }}
                  />
                </Link>
              </Grid>
              <Grid item className="mb-2 flex flex-row" spacing={2}>
                <div className="cursor-pointer"
                  style={{
                    color: "white",
                    backgroundColor: "#71BE42",
                    border: "1px solid #71BE42",
                    padding: "0 6px",
                    borderRadius: "50%",
                    marginRight: "7px",
                  }}
                  onClick={() => setRegisterMode("akun")}
                >
                  <p>1</p>
                </div>
                <div onClick={() => setRegisterMode("akun")} style={{ marginRight: "5px" }}>
                  <p style={{ color: "#71BE42" }}>Data Akun</p>
                </div>

                <div onClick={() => setRegisterMode("caleg")} style={{ marginRight: "5px" }}>
                  <p>&gt;</p>
                </div>

                <div className="cursor-pointer"
                  style={registerMode === "caleg" ? {
                    color: "white",
                    backgroundColor: "#71BE42",
                    border: "1px solid ##C2C2C2",
                    padding: "0 5px",
                    borderRadius: "50%",
                    marginRight: "6px",
                  } : {
                    color: "#C2C2C2",
                    border: "1px solid #C2C2C2",
                    padding: "0 5px",
                    borderRadius: "50%",
                    marginRight: "5px",
                  }}
                  onClick={() => setRegisterMode("caleg")}
                >
                  <p>2</p>
                </div>
                <div onClick={() => setRegisterMode("caleg")}>
                  <p style={registerMode === "caleg" ? { color: "#71BE42" } : { color: "#C2C2C2" }}
                  >Data Caleg</p>
                </div>
              </Grid>
            </Grid>

            {/* Register Data */}
            {registerMode === "akun" && (
              <Grid item >
                <h2 style={{ fontWeight: "bold", fontSize: "24px" }}>Data Akun</h2>
                <Box>
                  <FormControl className="w-full mb-1" style={{ width: "100%" }}>
                    <label
                      htmlFor="fullname"
                      className="mb-2 fw-bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Nama Lengkap
                    </label>
                    <TextField
                      fullWidth
                      hiddenLabel
                      type="text"
                      id="fullname"
                      variant="outlined"
                      size="small"
                      placeholder="Masukan nama lengkap"
                      name="fullname"
                      value={state.fullname}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      error={errorField?.fullname !== ""}
                      helperText={errorField?.fullname}
                    />
                  </FormControl>
                  <FormControl className="w-full mb-1" style={{ width: "100%" }}>
                    <label
                      htmlFor="email"
                      className="mb-2 fw-bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Email
                    </label>
                    <TextField
                      fullWidth
                      hiddenLabel
                      type="email"
                      id="email"
                      variant="outlined"
                      size="small"
                      placeholder="Masukan email"
                      name="email"
                      value={state.email}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      error={errorField?.email !== ""}
                      helperText={errorField?.email}
                    />
                  </FormControl>
                  <FormControl className="w-full mb-1" style={{ width: "100%" }}>
                    <label
                      htmlFor="outlined-basic"
                      className="mb-2 fw-bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Username
                    </label>
                    <TextField
                      fullWidth
                      hiddenLabel
                      type="text"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Masukan username"
                      name="usersname"
                      value={state.usersname}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      error={errorField?.usersname !== ""}
                      helperText={errorField?.usersname}
                    />
                  </FormControl>
                  <FormControl className="w-full" style={{ width: "100%" }}>
                    <label
                      htmlFor="password"
                      className="mb-2 fw-bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Password
                    </label>
                    <TextField
                      fullWidth
                      hiddenLabel
                      id="password"
                      variant="outlined"
                      size="small"
                      placeholder="Masukan password"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={state.password}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff htmlColor="#4D5B7C" />
                              ) : (
                                <Visibility htmlColor="#4D5B7C" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={errorField?.password !== ""}
                      helperText={errorField?.password}
                    />
                  </FormControl>
                  <FormControl className="w-full mb-1" style={{ width: "100%" }}>
                    <label
                      htmlFor="outlined-basic"
                      className="mb-2 fw-bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Nomor Ponsel
                    </label>
                    <TextField
                      fullWidth
                      hiddenLabel
                      type="text"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Masukan no ponsel"
                      name="phone"
                      value={state.phone}
                      onChange={handleChangeNoPhone}
                      onKeyDown={handleKeyDown}
                      error={errorField?.phone !== ""}
                      helperText={errorField?.phone}
                    />
                  </FormControl>
                  <Grid container className="mt-2">
                    <CustomButton
                      text={loading ? <CircularProgress /> : "Selanjutnya"}
                      fw={true}
                      onClick={handleCalegMode}
                      disabled={loading}
                      bgColor="#00ACEE"
                      color="#FFFFFF"
                    />
                  </Grid>
                  <p className="text-center mt-3 mb-2" style={{ fontWeight: "bold" }}>
                    Sudah punya akun?{" "}
                    <Link to="/login" >
                      <span style={{ color: "#00ACEE", textDecoration: "none" }}>
                        Masuk disini
                      </span>
                    </Link>
                  </p>
                </Box>
              </Grid>
            )}
            {registerMode === "caleg" && (
              <Grid item >
                <h2 style={{ fontWeight: "bold", fontSize: "24px" }}>Data Caleg</h2>
                <Box component="form" autoComplete="off">
                  <FormControl className="w-full mb-1" style={{ width: "100%" }}>
                    <label
                      htmlFor="outlined-basic"
                      className="mb-2 fw-bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Nama Lengkap Caleg
                    </label>
                    <TextField
                      fullWidth
                      hiddenLabel
                      type="text"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Masukan nama lengkap caleg"
                      name="kandidatname"
                      value={state.kandidatname}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      error={errorField?.kandidatname !== ""}
                      helperText={errorField?.kandidatname}
                    />
                  </FormControl>
                  <FormControl className="w-full mb-1" style={{ width: "100%" }}>
                    <label
                      htmlFor="outlined-basic"
                      className="mb-2 fw-bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Alamat
                    </label>
                    <TextField
                      fullWidth
                      hiddenLabel
                      type="text"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Masukan alamat"
                      name="alamatkandidat"
                      value={state.alamatkandidat}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      error={errorField?.alamatkandidat !== ""}
                      helperText={errorField?.alamatkandidat}
                    />
                  </FormControl>
                  <Grid container spacing={1}>
                    <Grid item md={6} lg={6}>
                      <FormControl className="w-full mb-1" style={{ width: "100%" }}>
                        <label
                          htmlFor="dapil"
                          className="mb-2 fw-bold"
                          style={{ fontWeight: "bold" }}
                        >
                          Daerah Pemilihan
                        </label>
                        <Select
                          SelectDisplayProps={{
                            style: {
                              paddingTop: 10,
                              paddingBottom: 10,
                              borderRadius: 5,
                            },
                          }}
                          size="small"
                          labelid="dapil"
                          value={state.dapil}
                          onChange={handleChange}
                          variant="outlined"
                          className="w-100"
                          name="dapil"
                          displayEmpty
                        >
                          <MenuItem key={"x"} value={""}>
                            Pilih Dapil
                          </MenuItem>
                          {dataDapil?.map((item) => (
                            <MenuItem key={item.dapil_code} value={item.dapil_code}>
                              {item.dapil_nama}
                            </MenuItem>
                          ))}
                        </Select>
                        <small className="text-danger">{errorField.dapil}</small>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} lg={6}>
                      <FormControl className="w-full mb-1" style={{ width: "100%" }}>
                        <label
                          htmlFor="tingkat"
                          className="mb-2 fw-bold"
                          style={{ fontWeight: "bold" }}
                        >
                          Caleg Tingkat
                        </label>
                        <Select
                          SelectDisplayProps={{
                            style: {
                              paddingTop: 10,
                              paddingBottom: 10,
                              borderRadius: 5,
                            },
                          }}
                          size="small"
                          labelid="tingkat"
                          value={state.tingkat}
                          onChange={handleChange}
                          variant="outlined"
                          className="w-100"
                          name="tingkat"
                          displayEmpty
                        >
                          <MenuItem key={"x"} value={""}>
                            Pilih Tingkat
                          </MenuItem>
                          {dataCalonTetap?.map((item) => (
                            <MenuItem key={item.tingkat_calon_tetap_code} value={item.tingkat_calon_tetap_code}>
                              {item.tingkat_calon_tetap_nama}
                            </MenuItem>
                          ))}
                        </Select>
                        <small className="text-danger">{errorField.tingkat}</small>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <FormControl className="w-full mb-1" style={{ width: "100%" }}>
                    <label
                      htmlFor="outlined-basic"
                      className="mb-2 fw-bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Partai
                    </label>
                    <Select
                      SelectDisplayProps={{
                        style: {
                          paddingTop: 10,
                          paddingBottom: 10,
                          borderRadius: 5,
                        },
                      }}
                      size="small"
                      labelid="partai"
                      value={state.partai}
                      onChange={handleChange}
                      variant="outlined"
                      className="w-100"
                      name="partai"
                      displayEmpty
                    >
                      <MenuItem key={"x"} value={""}>
                        Pilih Partai
                      </MenuItem>
                      {partaiSekarang?.map((item) => (
                        <MenuItem key={item.partai_code} value={item.partai_code}>
                          {item.partai_nama}
                        </MenuItem>
                      ))}
                    </Select>
                    <small className="text-danger">{errorField.partai}</small>
                  </FormControl>
                  <FormControl className="w-full mb-1" style={{ width: "100%" }}>
                    <label
                      htmlFor="outlined-basic"
                      className="mb-2 fw-bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Nomor Urut
                    </label>
                    <TextField
                      fullWidth
                      hiddenLabel
                      type="text"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Masukan no urut"
                      name="nourut"
                      value={state.nourut}
                      onChange={handleChangeNoUrut}
                      onKeyDown={handleKeyDown}
                      error={errorField?.nourut !== ""}
                      helperText={errorField?.nourut}
                    />
                  </FormControl>
                  <Grid container className="mt-2">
                    <CustomButton
                      text={loading ? <CircularProgress /> : "Daftar"}
                      fw={true}
                      onClick={handleSubmit}
                      disabled={loading}
                      bgColor="#00ACEE"
                      color="#FFFFFF"
                    />
                  </Grid>
                  <p className="text-center mt-3 mb-2" style={{ fontWeight: "bold" }}>
                    Sudah punya akun?{" "}
                    <Link to="/login" >
                      <span style={{ color: "#00ACEE", textDecoration: "none" }}>
                        Masuk disini
                      </span>
                    </Link>
                  </p>
                </Box>
              </Grid>
            )}
          </Card>
        </Hidden>


        {/* Mobile Mode */}
        <Hidden smUp>
          <Card
            className="p-5 rounded-1 border flex flex-column"
            justifyContent="center"
            alignItems="center"
            style={{
              width: "100%",
              overflow: "auto",
              height: "100vh"
            }}
          >
            <Grid container justifyContent="center" className="flex flex-column mt-2" alignItems="center">
              <Grid item className="mb-4">
                <Link to="/">
                  <img
                    src="/assets/images/logos/logo_pemilu_now.png"
                    alt="Pemilu Now"
                    className={classes.logo}
                    style={{ margin: "0 auto" }}
                  />
                </Link>
              </Grid>
              <Grid item className="mb-4 flex flex-row">
                <div className="cursor-pointer"
                  style={{
                    color: "white",
                    backgroundColor: "#71BE42",
                    border: "1px solid #71BE42",
                    padding: "0 6px",
                    borderRadius: "50%",
                    marginRight: "7px",
                  }}
                  onClick={() => setRegisterMode("akun")}
                >
                  <p>1</p>
                </div>
                <div onClick={() => setRegisterMode("akun")} style={{ marginRight: "5px" }}>
                  <p style={{ color: "#71BE42" }}>Data Akun</p>
                </div>

                <div onClick={() => setRegisterMode("caleg")} style={{ marginRight: "5px" }}>
                  <p>&gt;</p>
                </div>

                <div className="cursor-pointer"
                  style={registerMode === "caleg" ? {
                    color: "white",
                    backgroundColor: "#71BE42",
                    border: "1px solid #71BE42",
                    padding: "0 5px",
                    borderRadius: "50%",
                    marginRight: "6px",
                  } : {
                    color: "#C2C2C2",
                    border: "1px solid #C2C2C2",
                    padding: "0 5px",
                    borderRadius: "50%",
                    marginRight: "5px",
                  }}
                  onClick={() => setRegisterMode("caleg")}
                >
                  <p>2</p>
                </div>
                <div onClick={() => setRegisterMode("caleg")}>
                  <p style={registerMode === "caleg" ? { color: "#71BE42" } : { color: "#C2C2C2" }}
                  >Data Caleg</p>
                </div>
              </Grid>
            </Grid>

            {/* Register Data */}
            {registerMode === "akun" && (
              <Grid item >
                <h2 style={{ fontWeight: "bold", fontSize: "24px" }}>Data Akun</h2>
                <Box >
                  <InputLabel htmlFor="fullname">
                    <h5 className="font-size-14">Nama Lengkap</h5>
                  </InputLabel>
                  <TextField
                    required={true}
                    size="small"
                    labelid="fullname"
                    value={state.fullname}
                    name="fullname"
                    className={`w-100`}
                    InputProps={{
                      style: {
                        borderRadius: 5,
                      },
                    }}
                    placeholder="Nama Lengkap"
                    variant="outlined"
                    onChange={handleChange}
                    type="text"
                  // error={errorField?.fullname !== ""}
                  // helperText={errorField?.fullname}
                  />
                  <FormControl className="w-full mb-1" style={{ width: "100%" }}>
                    <label
                      htmlFor="email"
                      className="mb-2 fw-bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Email
                    </label>
                    <TextField
                      fullWidth
                      hiddenLabel
                      type="email"
                      id="email"
                      variant="outlined"
                      size="small"
                      placeholder="Masukan email"
                      name="email"
                      value={state.email}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                  </FormControl>
                  <FormControl className="w-full mb-1" style={{ width: "100%" }}>
                    <label
                      htmlFor="outlined-basic"
                      className="mb-2 fw-bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Username
                    </label>
                    <TextField
                      fullWidth
                      hiddenLabel
                      type="text"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Masukan username"
                      name="usersname"
                      value={state.usersname}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                  </FormControl>
                  <FormControl className="w-full mb-1" style={{ width: "100%" }}>
                    <label
                      htmlFor="outlined-basic"
                      className="mb-2 fw-bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Nomor Ponsel
                    </label>
                    <TextField
                      fullWidth
                      hiddenLabel
                      type="text"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Masukan no ponsel"
                      name="phone"
                      value={state.phone}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                  </FormControl>
                  <Grid container className="mt-2">
                    <CustomButton
                      text={loading ? <CircularProgress /> : "Selanjutnya"}
                      fw={true}
                      onClick={handleCalegMode}
                      disabled={loading}
                      bgColor="#00ACEE"
                      color="#FFFFFF"
                    />
                  </Grid>
                  <p className="text-center mt-3 mb-2" style={{ fontWeight: "bold" }}>
                    Sudah punya akun?{" "}
                    <Link to="/login" >
                      <span style={{ color: "#00ACEE", textDecoration: "none" }}>
                        Masuk disini
                      </span>
                    </Link>
                  </p>
                </Box>
              </Grid>
            )}
            {registerMode === "caleg" && (
              <Grid item >
                <h2 style={{ fontWeight: "bold", fontSize: "24px" }}>Data Caleg</h2>
                <Box component="form" autoComplete="off">
                  <FormControl className="w-full mb-1" style={{ width: "100%" }}>
                    <label
                      htmlFor="outlined-basic"
                      className="mb-2 fw-bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Nama Lengkap Caleg
                    </label>
                    <TextField
                      fullWidth
                      hiddenLabel
                      type="text"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Masukan nama lengkap caleg"
                      name="kandidatname"
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                  </FormControl>
                  <FormControl className="w-full mb-1" style={{ width: "100%" }}>
                    <label
                      htmlFor="outlined-basic"
                      className="mb-2 fw-bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Alamat
                    </label>
                    <TextField
                      fullWidth
                      hiddenLabel
                      type="text"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Masukan alamat"
                      name="alamatkandidat"
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                  </FormControl>
                  <FormControl className="w-full mb-1" style={{ width: "100%" }}>
                    <label
                      htmlFor="outlined-basic"
                      className="mb-2 fw-bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Daerah Pemilihan
                    </label>
                    <TextField
                      fullWidth
                      hiddenLabel
                      type="text"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Masukan daerah pemilihan"
                      name="daerah"
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                  </FormControl>
                  <FormControl className="w-full mb-1" style={{ width: "100%" }}>
                    <label
                      htmlFor="outlined-basic"
                      className="mb-2 fw-bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Partai
                    </label>
                    <TextField
                      fullWidth
                      hiddenLabel
                      type="text"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Masukan partai"
                      name="partai"
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                  </FormControl>
                  <FormControl className="w-full mb-1" style={{ width: "100%" }}>
                    <label
                      htmlFor="outlined-basic"
                      className="mb-2 fw-bold"
                      style={{ fontWeight: "bold" }}
                    >
                      Nomor Urut
                    </label>
                    <TextField
                      fullWidth
                      hiddenLabel
                      type="text"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Masukan no urut"
                      name="no_urut"
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                  </FormControl>
                  <Grid container className="mt-2">
                    <CustomButton
                      text={loading ? <CircularProgress /> : "Daftar"}
                      fw={true}
                      onClick={handleSubmit}
                      disabled={loading}
                      bgColor="#00ACEE"
                      color="#FFFFFF"
                    />
                  </Grid>
                  <p className="text-center mt-3 mb-2" style={{ fontWeight: "bold" }}>
                    Sudah punya akun?{" "}
                    <Link to="/login" >
                      <span style={{ color: "#00ACEE", textDecoration: "none" }}>
                        Masuk disini
                      </span>
                    </Link>
                  </p>
                </Box>
              </Grid>
            )}
          </Card>
        </Hidden>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    dataDapil: state.pengaturan.dataDapil,
    dataCalonTetap: state.pengaturan.dataCalonTetap,
    partaiSekarang: state.pengaturan.partaiSekarang,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDataDapilPublic: () => dispatch(getDataDapilPublic()),
    getDataPartaiPublic: () => dispatch(getDataPartaiPublic()),
    getDataCalonTetapSebagaiPublic: () => dispatch(getDataCalonTetapSebagaiPublic()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);