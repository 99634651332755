import { authRoles } from "app/auth/authRoles";
import DetailTPS from "./DetailTPS";
import PemiluTerakhir from "./PemiluTerakhir";
import RangkumanPartai from "./RangkumanPartai";
import RekapSuara from "./RekapSuara";
import SuaraCaleg from "./suara/SuaraCaleg";
import SuaraPartai from "./suara/SuaraPartai";

const PemiluTerakhirRoutes = [
  {
    path: "/pemilu_terakhir",
    exact: true,
    component: PemiluTerakhir,
    auth: authRoles.admin,
  },
  {
    path: "/pemilu_terakhir/detail_tps/:id",
    exact: true,
    component: DetailTPS,
    auth: authRoles.admin,
  },
  {
    path: "/pemilu_terakhir/rekap_suara/:id",
    exact: true,
    component: RekapSuara,
    auth: authRoles.admin,
  },
  {
    path: "/pemilu_terakhir/rangkuman_partai/:id_partai/:id_dapil",
    exact: true,
    component: RangkumanPartai,
    auth: authRoles.admin,
  },
  {
    path: "/pemilu_terakhir/suara_caleg/:id_dapil",
    exact: true,
    component: SuaraCaleg,
    auth: authRoles.admin,
  },
  {
    path: "/pemilu_terakhir/suara_partai/:id_dapil",
    exact: true,
    component: SuaraPartai,
    auth: authRoles.admin,
  },
];

export default PemiluTerakhirRoutes;
