/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getDataArusKasDetail,
  getKategoriArusKas,
  updateArusKas,
} from "app/redux/actions/ArusKasActions";
import InputFileImg from "app/components/Input/InputFileImg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CalendarIcon } from "app/components/Icons";
import dayjs from "dayjs";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useHistory } from "react-router-dom";
import { showError, showSuccess } from "app/components/sweetalertService";
import GreenButton from "app/components/GreenButton";

const DetailArus = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const arus_kas_code = match.params.arus_kas_code;

  const [state, setState] = useState({});
  const [editedData, setEditedData] = useState({
    // arus_kas_foto: '',
    arus_kas_detail: "",
    arus_kas_catatan: "",
    arus_kas_total: 0,
    arus_kas_kategori_arus_code: "",
  });
  const [imagePreview, setImagePreview] = useState("");

  const getData = () => {
    dispatch(getDataArusKasDetail({ arus_kas_code }));
    dispatch(getKategoriArusKas());
  };

  const { kategori, dataDetailArusKas } = useSelector((state) => state.arusKas);

  const defaultKategori = kategori.find(
    (item) =>
      item.kategori_arus_kas_code ===
      dataDetailArusKas.arus_kas_kategori_arus_code
  );

  const handleChangeFoto = (file, path) => {
    setEditedData((prev) => ({
      ...prev,
      arus_kas_foto: file,
    }));
    setImagePreview(path);
  };
  // console.log("path", editedData.arus_kas_foto_old);

  useEffect(() => {
    getData();
  }, [arus_kas_code]);

  useEffect(() => {
    setEditedData(dataDetailArusKas);
  }, [dataDetailArusKas]);

  useEffect(() => {
    setImagePreview(dataDetailArusKas.arus_kas_foto || "");
  }, [dataDetailArusKas]);

  const handleChange = (e) => {
    e.persist();
    setEditedData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleTotalChange = (event) => {
    const input = event.target.value;
    const formattedTotal = input.replace(/\D/g, "");

    setEditedData((prevState) => ({
      ...prevState,
      arus_kas_total: formattedTotal,
    }));
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD HH:mm:ss");

    setEditedData((prevState) => ({
      ...prevState,
      arus_kas_tanggal: formattedDate,
    }));
  };

  const [errorField, setErrorField] = useState({});

  const handleSubmit = () => {
    const updatedData = {
      ...editedData,
    };

    if (updatedData.arus_kas_foto === dataDetailArusKas.arus_kas_foto) {
      delete updatedData.arus_kas_foto;
    }

    updateArusKas(updatedData)
      .then((res) => {
        showSuccess("Data berhasil disimpan");
        history.push("/arus_kas");
      })
      .catch((err) => {
        showError("Data gagal disimpan");
        setErrorField(err.response.data.data);
      });
  };

  return (
    <div className="analytics m-sm-30 mt-7">
      <Grid
        container
        spacing={3}
        className="mb-2 d-flex justify-content-center align-items-center"
      >
        <Grid item lg={6} md={6} xs={12} sm={6}>
          <h2 className="font-size-32 fw-700 fw-bold">Edit Arus Kas</h2>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          sm={6}
          spacing={2}
          container
          justify="flex-end"
        >
          <Grid item>
            <GreenButton
              text="Kembali"
              onClick={() => history.push("/arus_kas")}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <GreenButton
              text="Simpan Data"
              onClick={handleSubmit}
              variant="contained"
            />
          </Grid>
        </Grid>
      </Grid>

      <Card style={{ padding: "20px", marginBottom: "20px" }}>
        <Grid container className="mt-2 p-2 " spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h3 className="fw-bold font-size-40">Detail Arus Kas</h3>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="arus_kas_foto">
              <h5 className="font-size-14">Foto Kuitansi</h5>
              {dataDetailArusKas.arus_kas_foto && (
                <InputFileImg
                  noPadding={true}
                  fileName={editedData.arus_kas_foto}
                  preview={editedData.arus_kas_foto}
                  name="arus_kas_foto"
                  uploadFoto={handleChangeFoto}
                  acceptFile="image"
                />
              )}
            </InputLabel>
            <small className="text-danger">{errorField?.arus_kas_foto}</small>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}></Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="arus_kas_kategori_arus_code">
              <h5 className="font-size-14">Kategori</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                  backgroundColor: "#FBFBFB",
                },
              }}
              size="small"
              labelId="arus_kas_kategori_arus_code"
              value={state.kategori_arus_kas_code}
              onChange={handleChange}
              variant="outlined"
              className="w-100"
              name="arus_kas_kategori_arus_code"
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <span>
                      {defaultKategori
                        ? defaultKategori.kategori_arus_kas_nama
                        : ""}
                    </span>
                  );
                }
                const selectedCategory = kategori.find(
                  (item) => item.kategori_arus_kas_code === selected
                );
                return selectedCategory
                  ? selectedCategory.kategori_arus_kas_nama
                  : "";
              }}
            >
              {kategori.map((item, index) => (
                <MenuItem key={index} value={item.kategori_arus_kas_code}>
                  {item.kategori_arus_kas_nama}
                </MenuItem>
              ))}
            </Select>
            <small className="text-danger">
              {errorField?.kategori_arus_kas_nama}
            </small>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="provinsi">
              <h5 className="font-size-14" style={{ marginBottom: "-5px" }}>
                Tanggal
              </h5>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker"]}
                style={{ marginTop: "50px" }}
              >
                <DatePicker
                  label={dayjs(editedData.arus_kas_tanggal).format(
                    "DD/MM/YYYY"
                  )}
                  inputFormat="DD/MM/YYYY"
                  className={`w-100`}
                  size="small"
                  required={true}
                  InputProps={{
                    style: {
                      borderRadius: 5,
                    },
                  }}
                  variant="outlined"
                  onChange={handleDateChange}
                  components={{
                    OpenPickerIcon: CalendarIcon,
                  }}
                  views={["year", "month", "day"]}
                  renderInput={(params) => <TextField {...params} />}
                  format="DD/MM/YYYY"
                />
              </DemoContainer>
            </LocalizationProvider>
            <small className="text-danger">
              {errorField?.arus_kas_tanggal}
            </small>
          </Grid>

          {editedData.arus_kas_tanggal2}
          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="arus_kas_total">
              <h5 className="font-size-14">Total</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              name="arus_kas_total"
              value={editedData.arus_kas_total ?? " "}
              onChange={handleTotalChange}
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
                startAdornment: (
                  <InputAdornment position="start" style={{ fontSize: 16 }}>
                    Rp
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              placeholder="0"
              error={errorField?.arus_kas_total !== ""}
              helperText={errorField?.arus_kas_total}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={false} sm={false}></Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="arus_kas_detail">
              <h5 className="font-size-14">Detail Arus Kas</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={editedData.arus_kas_detail ?? " "}
              onChange={handleChange}
              name="arus_kas_detail"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              multiline
              rows={3}
              variant="outlined"
              type="text"
              error={errorField?.arus_kas_detail !== ""}
              helperText={errorField?.arus_kas_detail}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="arus_kas_catatan">
              <h5 className="font-size-14">Catatan</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={editedData.arus_kas_catatan ?? " "}
              onChange={handleChange}
              name="arus_kas_catatan"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              multiline
              rows={3}
              variant="outlined"
              type="text"
              error={errorField?.arus_kas_catatan !== ""}
              helperText={errorField?.arus_kas_catatan}
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataDetailArusKas: state.arusKas.dataArusKas,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDataArusKas: () => dispatch(getDataArusKasDetail()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailArus);
