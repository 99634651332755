/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  Legend,
} from "recharts";
import { Card, Grid } from "@mui/material";
import SelectItem from "app/components/select/SelectItem";
import { useState } from "react";
import moment from "moment";
import "moment/locale/id";
import { shorterMonth } from "helpers/shorterMonth";

const ChartStatistic = ({
  selectedFilter,
  onSelectFilter,
  onSelectYear,
  dataGrafikPemilihDaily,
  dataGrafikPemilihMonthly,
  getDataGrafik,
}) => {
  const [year, setYear] = useState("");
  const [filter, setFilter] = useState("");
  const [availableYears, setAvailableYears] = useState([]);

  const monthly =
    dataGrafikPemilihMonthly && dataGrafikPemilihMonthly.length > 0
      ? dataGrafikPemilihMonthly.map((entry) => ({
          label: entry.month,
          ...entry.total_by_status,
        }))
      : [];

  const daily =
    dataGrafikPemilihDaily && dataGrafikPemilihDaily.length > 0
      ? dataGrafikPemilihDaily.map((entry) => ({
          label: entry.day,
          ...entry.total_by_status,
        }))
      : [];

  const getAvailableYears = () => {
    const currentYear = moment().year();
    const years = Array.from({ length: 5 }, (_, index) => ({
      label: (currentYear - index).toString(),
      value: currentYear - index,
    }));
    return years;
  };

  useEffect(() => {
    setAvailableYears(getAvailableYears());
  }, []);

  const filters = [
    { label: "Hari", value: "daily" },
    { label: "Bulan", value: "monthly" },
  ];

  const handleYearChange = (event) => {
    const yearValue = event.target.value;
    onSelectYear(yearValue);
    setYear(yearValue);
  };

  const handleFilterChange = (event) => {
    const filterValue = event.target.value;
    onSelectFilter(filterValue);
    setFilter(filterValue);
  };

  useEffect(() => {
    if (year) {
      getDataGrafik("calon_pemilih");
    }
  }, [year, filter]);

  return (
    <Fragment>
      <Card style={{ padding: "38px", borderRadius: "10px" }}>
        <Grid
          container
          className="mb-4 flex justify-content-between align-items-center"
        >
          <Grid item>
            <h3 className="font-size-20 fw-bold">Statistik Calon Pemilih</h3>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} md={6} sm={6}>
                <SelectItem
                  onChange={handleYearChange}
                  label="Pilih Tahun"
                  listItem={availableYears}
                  value={year}
                />
              </Grid>
              <Grid item xs={12} lg={6} md={6} sm={6}>
                <SelectItem
                  onChange={handleFilterChange}
                  label="Pilih Filter"
                  listItem={filters}
                  value={filter}
                  disabled={!year}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div className="w-100 overflow-x-auto mt-5">
          <ResponsiveContainer minWidth={700} height={300}>
            <AreaChart
              data={filter === "daily" ? daily : monthly}
              margin={{ top: 0, right: 0, left: 0, bottom: 15 }}
            >
              <defs>
                <linearGradient
                  id="colorPastiMilih"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="0%" stopColor="#D0F6B8" stopOpacity={1} />
                  <stop offset="100%" stopColor="#D0F6B8" stopOpacity={0.1} />
                </linearGradient>
                <linearGradient id="colorRagu" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#B7EBFF" stopOpacity={1} />
                  <stop offset="100%" stopColor="#B7EBFF" stopOpacity={0.1} />
                </linearGradient>
                <linearGradient
                  id="colorTidakMemilih"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="0%" stopColor="#FFA2A2" stopOpacity={1} />
                  <stop offset="100%" stopColor="#FFA2A2" stopOpacity={0.1} />
                </linearGradient>
              </defs>
              <XAxis
                axisLine={false}
                tickLine={false}
                dataKey="label"
                domain={[0, "dataMax"]}
                tick={{ fontSize: 12 }}
                tickFormatter={(value) => {
                  if (filter === "daily") {
                    return shorterMonth(value, "date");
                  }
                  return shorterMonth(value);
                }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={(value) => {
                  if (value === Math.round(value)) {
                    return Math.round(value).toString();
                  } else {
                    return "";
                  }
                }}
                tick={{ fontSize: 12 }}
              />
              <CartesianGrid vertical={false} strokeDasharray="0" />
              <Area
                type="monotone"
                dataKey="Memilih"
                stroke="#71BE42"
                fillOpacity={1}
                fill="url(#colorPastiMilih)"
                activeDot={{
                  stroke: "#71BE42",
                  fill: "#fff",
                  strokeWidth: 2,
                  r: 6,
                }}
              />
              <Area
                type="monotone"
                dataKey="Ragu"
                stroke="#00ACEE"
                fillOpacity={1}
                fill="url(#colorRagu)"
                activeDot={{
                  stroke: "#00ACEE",
                  fill: "#fff",
                  strokeWidth: 2,
                  r: 6,
                }}
              />
              <Area
                type="monotone"
                dataKey="Tidak Memilih"
                stroke="#E34242"
                fillOpacity={1}
                fill="url(#colorTidakMemilih)"
                activeDot={{
                  stroke: "#E34242",
                  fill: "#fff",
                  strokeWidth: 2,
                  r: 6,
                }}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                  fontSize: "12px",
                }}
                label={false}
                formatter={(value, name, props) => {
                  const legendLabels = {
                    Memilih: "Pasti Memilih",
                    Ragu: "Ragu - Ragu",
                    "Tidak Memilih": "Tidak Memilih",
                  };
                  return [value, legendLabels[name]];
                }}
                itemStyle={{ color: "#1C1C1C" }}
              />
              <Legend
                content={(props) => {
                  const { payload } = props;
                  const legendLabels = {
                    Memilih: "Pasti Memilih",
                    Ragu: "Ragu - Ragu",
                    "Tidak Memilih": "Tidak Memilih",
                  };

                  return (
                    <ul
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        listStyle: "none",
                        padding: 0,
                      }}
                    >
                      {payload.map((entry, index) => (
                        <li
                          key={`item-${index}`}
                          style={{
                            marginRight: "10px",
                            fontSize: "12px",
                          }}
                        >
                          <svg width={10} height={10}>
                            <circle cx={5} cy={5} r={5} fill={entry.color} />
                          </svg>
                          <span className="legend-label">
                            {legendLabels[entry.value]}
                          </span>
                        </li>
                      ))}
                    </ul>
                  );
                }}
                align="center"
                verticalAlign="bottom"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </Card>
    </Fragment>
  );
};

export default ChartStatistic;
