import React, { Fragment } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const SelectItem = ({ value, onChange, label, listItem, disabled }) => {
    return (
        <Fragment>
            <FormControl style={{ marginRight: "5px", width: '155px', height: '40px' }}>
                <InputLabel htmlFor="select">{label}</InputLabel>
                <Select
                    value={value}
                    onChange={onChange}
                    displayEmpty
                    disabled={disabled}
                >
                    <MenuItem value={label} disabled>
                        {label}
                    </MenuItem>
                    {listItem.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

        </Fragment>
    );
};

export default SelectItem;
