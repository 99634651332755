import React from "react";
import { Dialog, Grid } from "@material-ui/core";
import { useState } from "react";
import GeneralButton from "app/components/GeneralButton";
import InputFileImg from "app/components/Input/InputFileImg";
import GreenButton from "app/components/GreenButton";

const ModalImport = ({ open, handleClose, handleSubmit }) => {
  const [state, setState] = useState({
    file: "",
    file_preview: "",
  });
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleChangeFile = (file, path, state) => {
    setState((prev) => ({
      ...prev,
      [state]: file,
      [`${state}_preview`]: path,
    }));
  };

  const handleUnggah = async () => {
    await setLoadingSubmit(true);
    await handleSubmit(state);
    await setLoadingSubmit(false);

    setState({
      file: "",
      file_preview: "",
    });
  };

  return (
    <Dialog open={open} minWidth={"md"} fullWidth onClose={handleClose}>
      <div className="p-4">
        <h2 className="fs-24 mb-20 fw-600">Import Calon Pemilih</h2>
        <Grid className="mb-16" container spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12} className="mt-4">
            <InputFileImg
              noPadding={true}
              name="file"
              uploadFoto={(file, path) => handleChangeFile(file, path, "file")}
              acceptFile="excel"
            />

            <small className="fs-10 fw-600">
              *Harap pilih jenis data yang ingin di import <br />
              *Format file excel harus sesuai dengan template yang disediakan,
              harap unduh contoh data calon pemilih{" "}
              <a
                href="https://docs.google.com/spreadsheets/d/1QUPJgyUy3k0yZn4iuQaSYM3s5BB4WZhlR0OAx89lL5M/edit?usp=sharing"
                target="_blank"
                without
                rel="noopener noreferrer"
              >
                Disini
              </a>{" "}
              <br />
              *Data yang diimport akan me-replace data sebelumnya jika terdapat
              duplikat
            </small>
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6} className="mt-4">
            <GreenButton
              variant="outlined"
              color="secondary"
              text="Batal"
              otherClass="w-full text-green"
              onClick={handleClose}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6} className="mt-4">
            <GeneralButton
              variant="contained"
              color="secondary"
              text="Unggah"
              otherClass="w-full"
              onClick={() => handleUnggah()}
              disabled={loadingSubmit || state.file === ""}
            />
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

export default ModalImport;
