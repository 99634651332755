export const formatBankNumber = (number) => {
    const cleanNumber = number?.replace(/\D/g, '');

    if (!cleanNumber || isNaN(cleanNumber)) {
        return "Nomor tidak valid";
    }

    const formattedNumber = cleanNumber.replace(/(\d{4})/g, '$1-');

    return formattedNumber.replace(/-$/, '');
};