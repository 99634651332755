// import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";
import Dashboard from "./Dashboard";

const dashboardRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    auth: authRoles.all,
    // exact: true,
  },
];

export default dashboardRoutes;
