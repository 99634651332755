import {
    GET_DISTRICT
} from "../constant.js";

const initialState = {
    dataDistrict: [],
};

const DistrictReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_DISTRICT: {
            return {
                ...state,
                dataDistrict: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default DistrictReducer;
