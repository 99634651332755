import WargaBinaan from "./WargaBinaan";
import AddWargaBinaan from "./AddWargaBinaan";
import EditWargaBinaan from "./EditWargaBinaan";
import DetailWargaBinaan from "./DetailWargaBinaan";

const WargaBinaanRoutes = [
  {
    path: "/calon_pemilih",
    exact: true,
    component: WargaBinaan
  },
  {
    path: "/calon_pemilih/tambah",
    exact: true,
    component: AddWargaBinaan
  },
  {
    path: "/calon_pemilih/edit/:id",
    exact: true,
    component: EditWargaBinaan
  },
  {
    path: "/calon_pemilih/:id",
    exact: true,
    component: DetailWargaBinaan
  },
];

export default WargaBinaanRoutes;
