import { API, setAuthToken } from 'config/API.js'
import { GET_ARUS_KAS } from '../constant.js'
export const GET_DETAIL_ARUS_KAS = 'GET_DETAIL_ARUS_KAS'
export const ADD_ARUS_KAS = 'ADD_ARUS_KAS'
export const GET_KATEGORI_ARUS_KAS = 'GET_KATEGORI_ARUS_KAS'
export const SET_ARUS_KAS_FILTER = 'SET_ARUS_KAS_FILTER'
export const CLEAR_ARUS_KAS_FILTER = 'CLEAR_ARUS_KAS_FILTER'

export const getDataArusKas = (params = '') => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)

    return API.get(`user/aruskas?${new URLSearchParams(params)}`)
      .then(res => {
        dispatch({
          type: GET_ARUS_KAS,
          payload: res.data.data || []
        })
        return res
      })
      .catch(() => {
        dispatch({
          type: GET_ARUS_KAS,
          payload: []
        })
      })
  }
}

export const getDataArusKasDetail = ({ arus_kas_code }) => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`user/aruskas/${arus_kas_code}`)
      .then(res => {
        dispatch({
          type: GET_DETAIL_ARUS_KAS,
          payload: res.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_DETAIL_ARUS_KAS,
          payload: []
        })
      })
  }
}

export const getKategoriArusKas = (params = '') => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`user/kategori/aruskas` + params)
      .then(res => {
        dispatch({
          type: GET_KATEGORI_ARUS_KAS,
          payload: res.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_KATEGORI_ARUS_KAS,
          payload: []
        })
      })
  }
}

export const addArusKas = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/user/aruskas/add', body)
}

export const deleteArusKas = code => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)

  const requestBody = { arus_kas_code: code }

  return API.post('/user/aruskas/delete', requestBody)
}

export const updateArusKas = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)

  return API.post(`/user/aruskas/update`, body)
}
