import { authRoles } from "app/auth/authRoles";
import { MatxLoadable } from "matx";

const HitungCepat = MatxLoadable({
  loader: () => import("./HitungCepat"),
});

const AddHitungCepat = MatxLoadable({
  loader: () => import("./AddHitungCepat"),
});

const DetailHitungCepat = MatxLoadable({
  loader: () => import("./DetailHitungCepat"),
});

const EditHitungCepat = MatxLoadable({
  loader: () => import("./EditHitungCepat"),
});

const HitungCepatRoutes = [
  {
    path: "/hitung_cepat",
    exact: true,
    auth: authRoles.admin,
    component: HitungCepat,
  },
  {
    path: "/hitung_cepat/tambah",
    exact: true,
    auth: authRoles.admin,
    component: AddHitungCepat,
  },
  {
    path: "/hitung_cepat/detail/:hitung_cepat_code",
    exact: true,
    auth: authRoles.admin,
    component: DetailHitungCepat,
  },
  {
    path: "/hitung_cepat/edit/:hitung_cepat_code",
    exact: true,
    auth: authRoles.admin,
    component: EditHitungCepat,
  },
];

export default HitungCepatRoutes;
