import {
    Box,
    Button,
    Card,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    TextField,
    createStyles,
    Hidden
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton } from "../../components";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { checkTokenReset, confirmPassword } from "app/redux/actions/AuthAction";
import { showError, showSuccess } from "app/components/sweetalertService";

const useStyles = makeStyles(({ palette, ...theme }) =>
    createStyles({
        logo: {
            maxWidth: 188,
            marginBottom: 30,
        },
        containerForm: {
            padding: "1rem",
            [theme.breakpoints.up("md")]: {
                padding: "1rem 3rem",
            },
        },
        bgPage: {
            backgroundImage: "url('/assets/images/illustrations/bg-page.webp')",
            backgroundRepeat: "repeat",
        },
    })
);

const ConfirmPassword = () => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [passwordErrorText, setPasswordErrorText] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [params, setParams] = useState({
        password: "",
        confirm_password: "",
    });
    const [validToken, setValidToken] = React.useState("");
    const location = useLocation();
    const history = useHistory();
    let queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        const tokenReset = queryParams.get('token_reset')
        checkTokenReset({ token: tokenReset }).then((response) => {
            const tokenVerified = response.data.code ;
                if(tokenVerified === 0) {
                    // console.log(response.data)
                    setValidToken(tokenReset)
                } 
            }).catch((err) => {
                showError("Token anda tidak valid atau sudah kadaluarsa");
                history.push("/login");
            });
    }, []);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChange = (e) => {
        setParams({
            ...params,
            [e.target.name]: e.target.value,
        });
        if (!params.password === params.confirm_password) {
            setPasswordErrorText("Password tidak sesuai")
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSubmit(event);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        await setLoading(true);
        console.log('submited')
        if (!params.password === params.confirm_password) {
            setPasswordErrorText("Password tidak sesuai")
        } else {
            setPasswordErrorText("")
        }
        if(params.password) {
            const body = {
                password: params.password,
                password_confirmation: params.confirm_password
            }
            confirmPassword(validToken, body)
              .then((res) => {
                showSuccess(res.data.message)
                setLoading(false);
                history.push("/login");
              })
              .catch((error) => {
                showError(error.response.data.message);
                setLoading(false);
              });
          }
        console.log(params)
        console.log(passwordErrorText)
    };

    const classes = useStyles();
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                margin: "0 auto",
            }}
        >
            <Hidden xsDown>
                <Card className="m-5" style={{ maxWidth: "535px", maxHeight: "531px", padding: "40px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Link to="/">
                        <img
                            src="/assets/images/logos/logo_pemilu_now.png"
                            alt="Pemilu Now"
                            className={classes.logo}
                        />
                    </Link>
                    <h4 className="mb-4" style={{ fontSize: "16px", margin: "0" }}>Silahkan atur password baru anda disini untuk masuk ke situs Pemilu Now.</h4>

                    <Grid container spacing={0} justifyContent="center">
                        <Grid item sm={12} xs={12} lg={12} md={12}>
                            <Box component="form" autoComplete="off">
                                <FormControl className="w-full mb-4" style={{ width: "100%" }}>
                                    <label
                                        htmlFor="password"
                                        className="mb-2 fw-bold"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Masukan Password Baru
                                    </label>
                                    <TextField
                                        fullWidth
                                        hiddenLabel
                                        id="password"
                                        variant="outlined"
                                        size="small"
                                        placeholder="Masukan password"
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        onChange={handleChange}
                                        onKeyDown={handleKeyDown}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOff htmlColor="#4D5B7C" />
                                                        ) : (
                                                            <Visibility htmlColor="#4D5B7C" />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <FormHelperText
                                        id="component-error-text"
                                        className="text-danger"
                                    >
                                        {passwordErrorText}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl className="w-full mb-4" style={{ width: "100%" }}>
                                    <label
                                        htmlFor="confirm_password"
                                        className="mb-2 fw-bold"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Ulangi Password Baru
                                    </label>
                                    <TextField
                                        fullWidth
                                        hiddenLabel
                                        id="confirm_password"
                                        variant="outlined"
                                        size="small"
                                        placeholder="Ulangi password Baru"
                                        type={showPassword ? "text" : "password"}
                                        name="confirm_password"
                                        onChange={handleChange}
                                        onKeyDown={handleKeyDown}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOff htmlColor="#4D5B7C" />
                                                        ) : (
                                                            <Visibility htmlColor="#4D5B7C" />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <FormHelperText
                                        id="component-error-text"
                                        className="text-danger"
                                    >
                                        {passwordErrorText}
                                    </FormHelperText>
                                </FormControl>
                                <Grid container>
                                    <CustomButton
                                        text={loading ? <CircularProgress /> : "Submit"}
                                        fw={true}
                                        onClick={handleSubmit}
                                        disabled={loading}
                                        bgColor="#00ACEE"
                                        color="#FFFFFF"

                                    />
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
            </Hidden>

            <Hidden smUp>
                <Card style={{ maxWidth: "535px", height: "100vh", padding: "40px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Link to="/">
                        <img
                            src="/assets/images/logos/logo_pemilu_now.png"
                            alt="Pemilu Now"
                            className={classes.logo}
                        />
                    </Link>
                    <h4 className="mb-4" style={{ fontSize: "16px", margin: "0" }}>Silahkan atur password baru anda disini untuk masuk ke situs Pemilu Now.</h4>

                    <Grid container spacing={0} justifyContent="center">
                        <Grid item sm={12} xs={12} lg={12} md={12}>
                            <Box component="form" autoComplete="off">
                                <FormControl className="w-full mb-4" style={{ width: "100%" }}>
                                    <label
                                        htmlFor="password"
                                        className="mb-2 fw-bold"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Masukan Password Baru
                                    </label>
                                    <TextField
                                        fullWidth
                                        hiddenLabel
                                        id="password"
                                        variant="outlined"
                                        size="small"
                                        placeholder="Masukan password Baru"
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        onChange={handleChange}
                                        onKeyDown={handleKeyDown}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOff htmlColor="#4D5B7C" />
                                                        ) : (
                                                            <Visibility htmlColor="#4D5B7C" />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <FormHelperText
                                        id="component-error-text"
                                        className="text-danger"
                                    >
                                        {passwordErrorText}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl className="w-full mb-4" style={{ width: "100%" }}>
                                    <label
                                        htmlFor="confirm_password"
                                        className="mb-2 fw-bold"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Ulangi Password Baru
                                    </label>
                                    <TextField
                                        fullWidth
                                        hiddenLabel
                                        id="confirm_password"
                                        variant="outlined"
                                        size="small"
                                        placeholder="Ulangi password Baru"
                                        type={showPassword ? "text" : "password"}
                                        name="confirm_password"
                                        onChange={handleChange}
                                        onKeyDown={handleKeyDown}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOff htmlColor="#4D5B7C" />
                                                        ) : (
                                                            <Visibility htmlColor="#4D5B7C" />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <FormHelperText
                                        id="component-error-text"
                                        className="text-danger"
                                    >
                                        {passwordErrorText}
                                    </FormHelperText>
                                </FormControl>
                                <Grid container>
                                    <CustomButton
                                        text={loading ? <CircularProgress /> : "Submit"}
                                        fw={true}
                                        onClick={handleSubmit}
                                        disabled={loading}
                                        bgColor="#00ACEE"
                                        color="#FFFFFF"

                                    />
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
            </Hidden>
        </div>
    );
};

export default ConfirmPassword;