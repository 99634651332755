import React, { useState, useEffect } from "react";
import BarChart from "./BarChart";

const ChartStatisticLogistik = ({ color, dataY }) => {
  const [filterDataY, setFilterDataY] = useState([]);
  useEffect(() => {
    const filteredData = dataY.filter((item) => item.value !== 0);
    // console.log("Filtered data", filteredData);
    setFilterDataY(filteredData);
  }, [dataY]);
  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <div style={{ minWidth: 700, marginBottom: "20px" }}>
        <BarChart
          height="350px"
          color={color}
          dataY={filterDataY}
          dataX={filterDataY}
        />
      </div>
    </div>
  );
};

export default ChartStatisticLogistik;
