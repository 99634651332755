import Pengaturan from "./Pengaturan";
import AddKanddidatLain from "./KandidatLain/AddKandidatLain";
import EditKandidatLain from "./KandidatLain/EditKandidatLain";
import AddPengguna from "./Pengguna/AddPengguna";
import EditPengguna from "./Pengguna/EditPengguna";
import { authRoles } from "app/auth/authRoles";

const PengaturanRoutes = [
  {
    path: "/pengaturan",
    component: Pengaturan,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: "/pengaturan/pengguna/tambah",
    component: AddPengguna,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: "/pengaturan/pengguna/edit/:id",
    component: EditPengguna,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: "/pengaturan/kandidat_lain/tambah",
    component: AddKanddidatLain,
    auth: authRoles.admin,
    exact: true,
  },
  {
    path: "/pengaturan/kandidat_lain/edit/:id",
    component: EditKandidatLain,
    auth: authRoles.admin,
    exact: true,
  },
];

export default PengaturanRoutes;
