import {
  GET_TEAM_MANAGEMENT,
  GET_TEAM_MANAGEMENT_LIST,
  GET_DETAIL_TEAM_MANAGEMENT,
} from "../constant.js";

const initialState = {
  dataTeam: [],
  dataTeamList: [

  ],
  dataTeamPagination: {},
  detailTeamManagement: {},
};

const TeamManagementReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_TEAM_MANAGEMENT: {
      return {
        ...state,
        dataTeam: action?.payload.data || [],
        dataTeamPagination: action?.payload.pagination || {}
      };
    }
    case GET_TEAM_MANAGEMENT_LIST: {
      return {
        ...state,
        dataTeamList: action?.payload.data || [],
      };
    }
    case GET_DETAIL_TEAM_MANAGEMENT: {
      return {
        ...state,
        detailTeamManagement: action?.payload.data || {}
      };
    }
    default: {
      return state;
    }
  }
};

export default TeamManagementReducer;
