import {
  GET_HITUNG_CEPAT,
  GET_DETAIL_HITUNG_CEPAT,
  GET_HITUNG_CEPAT_GRAFIK,
} from "../constant.js";

const initialState = {
  dataHitungCepat: [],
  detailHitungCepat: {},
  dataHitungCepatPagination: {},
  dataGrafikHitungCepat: [],
};

const HitungCepatReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_HITUNG_CEPAT: {
      return {
        ...state,
        dataHitungCepat: action?.payload.data || [],
        dataHitungCepatPagination: action?.payload.pagination || [],
      };
    }
    case GET_DETAIL_HITUNG_CEPAT: {
      return {
        ...state,
        detailHitungCepat: action?.payload.data || {},
      };
    }
    case GET_HITUNG_CEPAT_GRAFIK: {
      return {
        ...state,
        dataGrafikHitungCepat: action?.payload || [],
      };
    }
    default: {
      return state;
    }
  }
};

export default HitungCepatReducer;
