import { API, setAuthToken } from 'config/API.js'
import {
  GET_KABUPATEN_PT,
  GET_KECAMATAN_PT,
  GET_KELURAHAN_PT,
  GET_PEMILU_TERAKHIR,
  GET_PROVINSI_PT,
  GET_DETAIL_TPS,
  GET_GRAFIK_REKAP_SUARA,
  GET_RANGKUMAN_PARTAI,
  GET_RANGKUMAN_CALEG,
  GET_GRAFIK_RANGKUMAN_CALEG,
  GET_RANGKUMAN_SUARA_PARTAI,
  GET_GRAFIK_RANGKUMAN_SUARA_PARTAI,
  GET_GRAFIK_RANGKUMAN_PARTAI,
  GET_DETAIL_RANGKUMAN_PARTAI,
  GET_LIST_CALEG_PERPARTAI,
  GET_SUARA_CALEG_PERPARTAI
} from '../constant.js'

export const getDataPemiluTerakhir = (params = '') => {
  return dispatch => {
    dispatch({
      type: GET_PEMILU_TERAKHIR,
      payload: {
        data: [],
        pagination: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
          total: 1
        }
      }
    })

    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    return API.get(`/user/pemilu_terakhir?${new URLSearchParams(params)}`)
      .then(res => {
        dispatch({
          type: GET_PEMILU_TERAKHIR,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_PEMILU_TERAKHIR,
          payload: []
        })
      })
  }
}

export const getDetailTPS = (code, params) => {
  return dispatch => {
    dispatch({
      type: GET_DETAIL_TPS,
      payload: {
        data: [],
        pagination: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
          total: 1
        }
      }
    })

    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`/user/pemilu_terakhir/tps/${code}?${new URLSearchParams(params)}`)
      .then(res => {
        dispatch({
          type: GET_DETAIL_TPS,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_DETAIL_TPS,
          payload: []
        })
      })
  }
}

export const getProvinsi = (params = '') => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`/user/pemilu_terakhir/provinsi?${new URLSearchParams(params)}`)
      .then(res => {
        dispatch({
          type: GET_PROVINSI_PT,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_PROVINSI_PT,
          payload: []
        })
      })
  }
}

export const getKabupaten = (params = '') => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`/user/pemilu_terakhir/kabupaten?${new URLSearchParams(params)}`)
      .then(res => {
        dispatch({
          type: GET_KABUPATEN_PT,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_KABUPATEN_PT,
          payload: []
        })
      })
  }
}

export const getKecamatan = (params = '') => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`/user/pemilu_terakhir/kecamatan?${new URLSearchParams(params)}`)
      .then(res => {
        dispatch({
          type: GET_KECAMATAN_PT,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_KECAMATAN_PT,
          payload: []
        })
      })
  }
}

export const getKelurahan = (params = '') => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`/user/pemilu_terakhir/kelurahan?${new URLSearchParams(params)}`)
      .then(res => {
        dispatch({
          type: GET_KELURAHAN_PT,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_KELURAHAN_PT,
          payload: []
        })
      })
  }
}

export const resetKabupaten = () => {
  return dispatch => {
    dispatch({
      type: GET_KABUPATEN_PT,
      payload: []
    })
  }
}

export const resetKecamatan = () => {
  return dispatch => {
    dispatch({
      type: GET_KECAMATAN_PT,
      payload: []
    })
  }
}

export const resetKelurahan = () => {
  return dispatch => {
    dispatch({
      type: GET_KELURAHAN_PT,
      payload: []
    })
  }
}

export const importPemiluTerakhir = body => {
  const token = localStorage.getItem('jwt_token')
  setAuthToken(token)
  return API.post('/user/pemilu_terakhir/import', body)
}

export const getGrafikRekapSuara = code => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`/user/pemilu_terakhir/dashboard/${code}`)
      .then(res => {
        dispatch({
          type: GET_GRAFIK_REKAP_SUARA,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_GRAFIK_REKAP_SUARA,
          payload: []
        })
      })
  }
}

export const getRangkumanPartai = code => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`/user/pemilu_terakhir/rangkuman_partai/${code}`)
      .then(res => {
        dispatch({
          type: GET_RANGKUMAN_PARTAI,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_RANGKUMAN_PARTAI,
          payload: []
        })
      })
  }
}

export const getRangkumanCaleg = params => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(
      `/user/pemilu_terakhir/rangkuman_caleg/?${new URLSearchParams(params)}`
    )
      .then(res => {
        dispatch({
          type: GET_RANGKUMAN_CALEG,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_RANGKUMAN_CALEG,
          payload: []
        })
      })
  }
}

export const getGrafikRangkumanCaleg = params => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(
      `/user/pemilu_terakhir/rangkuman_caleg/grafik/?${new URLSearchParams(
        params
      )}`
    )
      .then(res => {
        dispatch({
          type: GET_GRAFIK_RANGKUMAN_CALEG,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_GRAFIK_RANGKUMAN_CALEG,
          payload: []
        })
      })
  }
}

export const getRangkumanSuaraPartai = params => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(
      `/user/pemilu_terakhir/rangkuman_suara_partai/?${new URLSearchParams(
        params
      )}`
    )
      .then(res => {
        dispatch({
          type: GET_RANGKUMAN_SUARA_PARTAI,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_RANGKUMAN_SUARA_PARTAI,
          payload: []
        })
      })
  }
}

export const getGrafikRangkumanSuaraPartai = params => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(
      `/user/pemilu_terakhir/rangkuman_suara_partai/grafik?${new URLSearchParams(
        params
      )}`
    )
      .then(res => {
        dispatch({
          type: GET_GRAFIK_RANGKUMAN_SUARA_PARTAI,
          payload: res.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_GRAFIK_RANGKUMAN_SUARA_PARTAI,
          payload: []
        })
      })
  }
}

export const getGrafikRangkumanPartai = params => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(
      `/user/pemilu_terakhir/rangkuman_partai/grafik?${new URLSearchParams(
        params
      )}`
    )
      .then(res => {
        dispatch({
          type: GET_GRAFIK_RANGKUMAN_PARTAI,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_GRAFIK_RANGKUMAN_PARTAI,
          payload: []
        })
      })
  }
}

export const getDetailRangkumanPartai = params => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(
      `/user/pemilu_terakhir/rangkuman_partai/detail?${new URLSearchParams(
        params
      )}`
    )
      .then(res => {
        dispatch({
          type: GET_DETAIL_RANGKUMAN_PARTAI,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_DETAIL_RANGKUMAN_PARTAI,
          payload: []
        })
      })
  }
}

export const getCalegPerPartai = code => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`/user/pemilu_terakhir/list_caleg/${code}`)
      .then(res => {
        dispatch({
          type: GET_LIST_CALEG_PERPARTAI,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_LIST_CALEG_PERPARTAI,
          payload: []
        })
      })
  }
}

export const getSuaraCalegPerPartai = params => {
  return dispatch => {
    const token = localStorage.getItem('jwt_token')
    setAuthToken(token)
    API.get(`/user/pemilu_terakhir/suara_caleg?${new URLSearchParams(params)}`)
      .then(res => {
        dispatch({
          type: GET_SUARA_CALEG_PERPARTAI,
          payload: res.data.data || []
        })
      })
      .catch(() => {
        dispatch({
          type: GET_SUARA_CALEG_PERPARTAI,
          payload: []
        })
      })
  }
}
