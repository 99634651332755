import { Grid } from "@material-ui/core";
import GreenButton from "app/components/GreenButton";
import React from "react";

const Header = ({ backAction, saveAction, title }) => {
  return (
    <Grid
      container
      spacing={3}
      className="mb-3 d-flex justify-content-center align-items-center"
    >
      <Grid item lg={6} md={6} xs={12} sm={6}>
        <h2 className="font-size-32 fw-700 fw-bold">{title}</h2>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        xs={12}
        sm={6}
        spacing={2}
        container
        justify="flex-end"
      >
        {saveAction && (
          <>
            <Grid item>
              <GreenButton
                text="Kembali"
                onClick={backAction}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <GreenButton
                text="Simpan Data"
                onClick={saveAction}
                variant="contained"
              />
            </Grid>
          </>
        )}

        {!saveAction && (
          <Grid item>
            <GreenButton
              text="kembali"
              onClick={backAction}
              variant="contained"
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Header;
