import {
    GET_VILLAGES
} from "../constant.js";

const initialState = {
    dataVillages: [],
};

const VillagesReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_VILLAGES: {
            return {
                ...state,
                dataVillages: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default VillagesReducer;
