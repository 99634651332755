import React from "react";
import ReactEcharts from "echarts-for-react";
import { Grid, withStyles } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";

const BarChart = ({ color = [], dataX = [], dataY = [] }) => {
  const yAxisData = dataY.map((item) => ({
    value: item.name,
    textStyle: {
      // color: item.itemStyle.color,
      color: "#00000",
    },
  }));

  const option = {
    grid: {
      top: "0%",
      bottom: "10%",
      left: "1%",
      right: "5%",
      containLabel: true,
    },
    legend: {
      show: true,
    },
    yAxis: {
      type: "category",
      data: yAxisData,
      // show: true,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        inside: false,
        show: true,
        position: "left",
        formatter: (value) => {
          const item = dataY.find((item) => item.name === value);
          return `${item.name}`;
        },
      },
      nameLocation: "start",
    },
    xAxis: {
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: color,
          opacity: 0.15,
        },
      },
      axisLabel: {
        color: color,
        fontSize: 13,
        fontFamily: "Inter",
      },
    },
    series: [
      {
        data: dataX,
        type: "bar",
        label: {
          show: false,
          color: "#00000",
          position: "right",
          formatter: (params) => {
            return params.value + " Item";
          },
          textStyle: {
            color: "#000",
          },
        },
      },
    ],
    tooltip: {
      show: true,
      trigger: "item",
      // formatter: "{b}: {c} suara",
      formatter: (params) => {
        const item = dataY.find((element) => element.name === params.name);
        return `${item.value} Item`;
      },
    },
  };

  return (
    <>
      <ReactEcharts
        style={{ height: 100 * dataY.length }}
        option={{
          ...option,
          color: [...color],
        }}
      />
      <hr />
      <div>
        {[...dataY].map((item) => (
          <Grid container key={item.name}>
            <Grid
              item
              xs={3}
              md={3}
              lg={3}
              sm={3}
              className="d-flex align-items-center"
            >
              <FiberManualRecord htmlColor={item.itemStyle.color} />
              <h5 className="fs-14 me-2 mb-0">{item.name}</h5>
            </Grid>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <h5 className="fs-14 me-2 mb-0">: {item.value} Item</h5>
            </Grid>
          </Grid>
        ))}
      </div>
    </>
  );
};

export default withStyles({}, { withTheme: true })(BarChart);
