import { API, setAuthToken } from "config/API.js";
import {
    GET_DASHBOARD,
    GET_DASHBOARD_GRAFIK_DAILY,
    GET_DASHBOARD_GRAFIK_MONTHLY
} from "../constant.js";

export const getDataDashboard = (params = "") => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/user/dashboard` + params)
            .then((res) => {
                dispatch({
                    type: GET_DASHBOARD,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_DASHBOARD,
                    payload: [],
                });
            });
    };
};

export const getGrafikdDashboardPemilihDaily = (params = "") => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/user/dashboard/grafik/daily` + params)
            .then((res) => {
                dispatch({
                    type: GET_DASHBOARD_GRAFIK_DAILY,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_DASHBOARD_GRAFIK_DAILY,
                    payload: [],
                });
            });
    };
};

export const getGrafikDashboardPemilihMonthly = (params = "") => {
    return (dispatch) => {
        const token = localStorage.getItem("jwt_token");
        setAuthToken(token);
        API.get(`/user/dashboard/grafik/monthly` + params)
            .then((res) => {
                dispatch({
                    type: GET_DASHBOARD_GRAFIK_MONTHLY,
                    payload: res.data.data || [],
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_DASHBOARD_GRAFIK_MONTHLY,
                    payload: [],
                });
            });
    };
};