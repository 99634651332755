import { API, setAuthToken } from "config/API.js";
import { GET_PROVINCE } from "../constant.js";

export const getProvince = (params = "") => {
  return (dispatch) => {
    const token = localStorage.getItem("jwt_token");
    setAuthToken(token);
    API.get(`/provinsi/list` + params)
      .then((res) => {
        dispatch({
          type: GET_PROVINCE,
          payload: res.data.data || [],
        });
      })
      .catch(() => {
        dispatch({
          type: GET_PROVINCE,
          payload: [],
        });
      });
  };
};
