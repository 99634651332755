/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import CardTotalRelawan from "app/components/card/CardTotalRelawan";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getGrafikStatistikRelawanDaily,
  getGrafikStatistikRelawanMonthly,
  getStatisticRelawan,
} from "app/redux/actions/StatisticActions";
import ChartStatisticRelawan from "./components/ChartStatisticRelawan";
import { UsersThree } from "@phosphor-icons/react";
import { useStatistikRelawan } from "./hooks/useStatistik";

const StatistikRelawan = ({
  selectedFilter,
  setSelectedFilter,
  setSelectedYear,
  filterDataGrafik,
}) => {
  const { relawanStatistik, dataGrafikRelawanDaily, dataGrafikRelawanMonthly } =
    useStatistikRelawan();

  // const dispatch = useDispatch();

  // const tabTexts = {
  //   title: "Relawan",
  //   card_title_1: "Total Relawan",
  //   card_title_2: "Relawan Aktif",
  //   card_title_3: "Relawan Nonaktif",
  // };

  // const {
  //   dataStatisticRelawan,
  //   dataGrafikRelawanDaily,
  //   dataGrafikRelawanMonthly,
  // } = useSelector((state) => state.statistic);

  // const getData = () => {
  //   dispatch(getStatisticRelawan());
  //   dispatch(getGrafikStatistikRelawanDaily());
  //   dispatch(getGrafikStatistikRelawanMonthly());
  // };

  // useEffect(() => {
  //   getData();
  // }, []);

  // const relawanStatistik = [
  //   {
  //     label: "Total Relawan",
  //     value: dataStatisticRelawan.totalRelawan,
  //   },
  //   {
  //     label: "Relawan Aktif",
  //     value: dataStatisticRelawan.totalRelawanAktif,
  //   },
  //   {
  //     label: "Relawan Nonaktif",
  //     value: dataStatisticRelawan.totalRelawanNonAktif,
  //   },
  // ]

  return (
    <>
      <Grid container spacing={2} className="mb-3">
        {relawanStatistik.map((relawan) => (
          <Grid item xs={12} lg={4} md={4} sm={4}>
            <CardTotalRelawan
              cardTitle={relawan.label}
              totalJumlah={relawan.value}
              icon={relawan.icon}
              className={relawan.color ?? ""}
            />
          </Grid>
        ))}

        {/* <Grid item xs={12} lg={4} md={4} sm={4}>
          <CardTotalRelawan
            cardTitle={tabTexts.card_title_1}
            totalJumlah={dataStatisticRelawan.totalRelawan}
            icon={UsersThree}
          />
        </Grid>

        <Grid item xs={12} lg={4} md={4} sm={4}>
          <CardTotalRelawan
            cardTitle={tabTexts.card_title_2}
            totalJumlah={dataStatisticRelawan.totalRelawanAktif}
            className="text-green-50"
            icon={UsersThree}
          />
        </Grid>

        <Grid item xs={12} lg={4} md={4} sm={4}>
          <CardTotalRelawan
            cardTitle={tabTexts.card_title_3}
            totalJumlah={dataStatisticRelawan.totalRelawanNonAktif}
            className="text-red-50"
            icon={UsersThree}
          />
        </Grid> */}
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <ChartStatisticRelawan
            // chart={dataStatisticRelawan}
            dataGrafikRelawanDaily={dataGrafikRelawanDaily}
            dataGrafikRelawanMonthly={dataGrafikRelawanMonthly}
            selectedFilter={selectedFilter}
            onSelectFilter={setSelectedFilter}
            onSelectYear={setSelectedYear}
            getDataGrafik={filterDataGrafik}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default StatistikRelawan;
