import * as React from "react";
import { Button, CircularProgress } from "@material-ui/core";

const CustomButton = ({
  text = "button",
  variant = "contained",
  color = "black",
  bg = "primary",
  otherClass = "",
  fw = true,
  icon = "",
  onClick = "",
  disabled = false,
  size = "medium",
  loading = false,
  bgColor = "white"
}) => {
  return (
    <Button
      variant={variant}
      className={`rounded-pill shadow-none text-${color} ${otherClass}`}
      color={bg}
      fullWidth={fw}
      startIcon={icon}
      size={size}
      onClick={onClick || (() => (window.location.href = "/"))}
      disabled={disabled}
      style={{color: color, backgroundColor:bgColor}}
    >
      {loading ? <CircularProgress /> : text}
    </Button>
  );
};

export default CustomButton;
