import Mock from "../mock";
import * as _ from "lodash";

const SebaranSuaraDB = {
    dataSebaranSuara: [
        {
            "id": 11,
            "name": "ACEH",
            "partai": "PAN",
            "jumlah_suara": 1592,
            "calon_pemilih": 173
        },
        {
            "id": 12,
            "name": "SUMATERA UTARA",
            "partai": "PDIP",
            "jumlah_suara": 1675,
            "calon_pemilih": 143
        },
        {
            "id": 13,
            "name": "SUMATERA BARAT",
            "partai": "Demokrat",
            "jumlah_suara": 1900,
            "calon_pemilih": 144
        },
        {
            "id": 14,
            "name": "RIAU",
            "partai": "Berkarya",
            "jumlah_suara": 1161,
            "calon_pemilih": 176
        },
        {
            "id": 15,
            "name": "JAMBI",
            "partai": "Garuda",
            "jumlah_suara": 1955,
            "calon_pemilih": 131
        },
        {
            "id": 16,
            "name": "SUMATERA SELATAN",
            "partai": "Gerindra",
            "jumlah_suara": 1190,
            "calon_pemilih": 125
        },
        {
            "id": 17,
            "name": "BENGKULU",
            "partai": "Golkar",
            "jumlah_suara": 1469,
            "calon_pemilih": 115
        },
        {
            "id": 18,
            "name": "LAMPUNG",
            "partai": "Hanura",
            "jumlah_suara": 1286,
            "calon_pemilih": 141
        },
        {
            "id": 19,
            "name": "KEPULAUAN BANGKA BELITUNG",
            "partai": "Nasdem",
            "jumlah_suara": 1798,
            "calon_pemilih": 138
        },
        {
            "id": 21,
            "name": "KEPULAUAN RIAU",
            "partai": "PBB",
            "jumlah_suara": 1470,
            "calon_pemilih": 146
        },
        {
            "id": 31,
            "name": "DKI JAKARTA",
            "partai": "Perindo",
            "jumlah_suara": 1654,
            "calon_pemilih": 142
        },
        {
            "id": 32,
            "name": "JAWA BARAT",
            "partai": "PKB",
            "jumlah_suara": 1857,
            "calon_pemilih": 171
        },
        {
            "id": 33,
            "name": "JAWA TENGAH",
            "partai": "PKPI",
            "jumlah_suara": 1965,
            "calon_pemilih": 174
        },
        {
            "id": 34,
            "name": "DI YOGYAKARTA",
            "partai": "PPP",
            "jumlah_suara": 1034,
            "calon_pemilih": 178
        },
        {
            "id": 35,
            "name": "JAWA TIMUR",
            "partai": "PSI",
            "jumlah_suara": 1014,
            "calon_pemilih": 106
        },
        {
            "id": 36,
            "name": "BANTEN",
            "partai": "PKS",
            "jumlah_suara": 1152,
            "calon_pemilih": 198
        },
        {
            "id": 51,
            "name": "BALI",
            "partai": "PAN",
            "jumlah_suara": 1511,
            "calon_pemilih": 184
        },
        {
            "id": 52,
            "name": "NUSA TENGGARA BARAT",
            "partai": "PDIP",
            "jumlah_suara": 1242,
            "calon_pemilih": 152
        },
        {
            "id": 53,
            "name": "NUSA TENGGARA TIMUR",
            "partai": "Demokrat",
            "jumlah_suara": 1209,
            "calon_pemilih": 187
        },
        {
            "id": 61,
            "name": "KALIMANTAN BARAT",
            "partai": "Berkarya",
            "jumlah_suara": 1012,
            "calon_pemilih": 130
        },
        {
            "id": 62,
            "name": "KALIMANTAN TENGAH",
            "partai": "Garuda",
            "jumlah_suara": 1694,
            "calon_pemilih": 192
        },
        {
            "id": 63,
            "name": "KALIMANTAN SELATAN",
            "partai": "Gerindra",
            "jumlah_suara": 1237,
            "calon_pemilih": 200
        },
        {
            "id": 64,
            "name": "KALIMANTAN TIMUR",
            "partai": "Golkar",
            "jumlah_suara": 1351,
            "calon_pemilih": 119
        },
        {
            "id": 65,
            "name": "KALIMANTAN UTARA",
            "partai": "Hanura",
            "jumlah_suara": 1361,
            "calon_pemilih": 197
        },
        {
            "id": 71,
            "name": "SULAWESI UTARA",
            "partai": "Nasdem",
            "jumlah_suara": 1572,
            "calon_pemilih": 122
        },
        {
            "id": 72,
            "name": "SULAWESI TENGAH",
            "partai": "PBB",
            "jumlah_suara": 1699,
            "calon_pemilih": 176
        },
        {
            "id": 73,
            "name": "SULAWESI SELATAN",
            "partai": "Perindo",
            "jumlah_suara": 1161,
            "calon_pemilih": 184
        },
        {
            "id": 74,
            "name": "SULAWESI TENGGARA",
            "partai": "PKB",
            "jumlah_suara": 1451,
            "calon_pemilih": 138
        },
        {
            "id": 75,
            "name": "GORONTALO",
            "partai": "PKPI",
            "jumlah_suara": 1697,
            "calon_pemilih": 175
        },
        {
            "id": 76,
            "name": "SULAWESI BARAT",
            "partai": "PPP",
            "jumlah_suara": 1752,
            "calon_pemilih": 153
        },
        {
            "id": 81,
            "name": "MALUKU",
            "partai": "PSI",
            "jumlah_suara": 1551,
            "calon_pemilih": 133
        },
        {
            "id": 82,
            "name": "MALUKU UTARA",
            "partai": "PKS",
            "jumlah_suara": 1838,
            "calon_pemilih": 112
        },
        {
            "id": 91,
            "name": "PAPUA BARAT",
            "partai": "PAN",
            "jumlah_suara": 1668,
            "calon_pemilih": 105
        },
        {
            "id": 94,
            "name": "PAPUA",
            "partai": "PDIP",
            "jumlah_suara": 1055,
            "calon_pemilih": 194
        }
    ],
    dataSebaranSuaraJawaBarat: [
        {
            "id": 3201,
            "province_id": "32",
            "name": "BOGOR",
            "partai": "Demokrat",
            "jumlah_suara": 1511,
            "calon_pemilih": 155
        },
        {
            "id": 3202,
            "province_id": "32",
            "name": "SUKABUMI",
            "partai": "Berkarya",
            "jumlah_suara": 1138,
            "calon_pemilih": 177
        },
        {
            "id": 3203,
            "province_id": "32",
            "name": "CIANJUR",
            "partai": "Garuda",
            "jumlah_suara": 1279,
            "calon_pemilih": 123
        },
        {
            "id": 3204,
            "province_id": "32",
            "name": "BANDUNG",
            "partai": "Gerindra",
            "jumlah_suara": 1516,
            "calon_pemilih": 136
        },
        {
            "id": 3205,
            "province_id": "32",
            "name": "GARUT",
            "partai": "Golkar",
            "jumlah_suara": 1463,
            "calon_pemilih": 192
        },
        {
            "id": 3206,
            "province_id": "32",
            "name": "TASIKMALAYA",
            "partai": "Hanura",
            "jumlah_suara": 1587,
            "calon_pemilih": 172
        },
        {
            "id": 3207,
            "province_id": "32",
            "name": "CIAMIS",
            "partai": "Nasdem",
            "jumlah_suara": 1138,
            "calon_pemilih": 111
        },
        {
            "id": 3208,
            "province_id": "32",
            "name": "KUNINGAN",
            "partai": "PBB",
            "jumlah_suara": 1886,
            "calon_pemilih": 102
        },
        {
            "id": 3209,
            "province_id": "32",
            "name": "CIREBON",
            "partai": "Perindo",
            "jumlah_suara": 1392,
            "calon_pemilih": 106
        },
        {
            "id": 3210,
            "province_id": "32",
            "name": "MAJALENGKA",
            "partai": "PKB",
            "jumlah_suara": 1163,
            "calon_pemilih": 197
        },
        {
            "id": 3211,
            "province_id": "32",
            "name": "SUMEDANG",
            "partai": "PKPI",
            "jumlah_suara": 1214,
            "calon_pemilih": 150
        },
        {
            "id": 3212,
            "province_id": "32",
            "name": "INDRAMAYU",
            "partai": "PPP",
            "jumlah_suara": 1437,
            "calon_pemilih": 184
        },
        {
            "id": 3213,
            "province_id": "32",
            "name": "SUBANG",
            "partai": "PSI",
            "jumlah_suara": 1320,
            "calon_pemilih": 192
        },
        {
            "id": 3214,
            "province_id": "32",
            "name": "PURWAKARTA",
            "partai": "PKS",
            "jumlah_suara": 1858,
            "calon_pemilih": 134
        },
        {
            "id": 3215,
            "province_id": "32",
            "name": "KARAWANG",
            "partai": "PAN",
            "jumlah_suara": 1853,
            "calon_pemilih": 177
        },
        {
            "id": 3216,
            "province_id": "32",
            "name": "BEKASI",
            "partai": "PDIP",
            "jumlah_suara": 1819,
            "calon_pemilih": 185
        },
        {
            "id": 3217,
            "province_id": "32",
            "name": "BANDUNG BARAT",
            "partai": "Demokrat",
            "jumlah_suara": 1802,
            "calon_pemilih": 159
        },
        {
            "id": 3218,
            "province_id": "32",
            "name": "PANGANDARAN",
            "partai": "Berkarya",
            "jumlah_suara": 1248,
            "calon_pemilih": 138
        },
        {
            "id": 3271,
            "province_id": "32",
            "name": "KOTA BOGOR",
            "partai": "Garuda",
            "jumlah_suara": 1130,
            "calon_pemilih": 197
        },
        {
            "id": 3272,
            "province_id": "32",
            "name": "KOTA SUKABUMI",
            "partai": "Gerindra",
            "jumlah_suara": 1737,
            "calon_pemilih": 148
        },
        {
            "id": 3273,
            "province_id": "32",
            "name": "KOTA BANDUNG",
            "partai": "Golkar",
            "jumlah_suara": 1881,
            "calon_pemilih": 199
        },
        {
            "id": 3274,
            "province_id": "32",
            "name": "KOTA CIREBON",
            "partai": "Hanura",
            "jumlah_suara": 1188,
            "calon_pemilih": 191
        },
        {
            "id": 3275,
            "province_id": "32",
            "name": "KOTA BEKASI",
            "partai": "Nasdem",
            "jumlah_suara": 1444,
            "calon_pemilih": 118
        },
        {
            "id": 3276,
            "province_id": "32",
            "name": "KOTA DEPOK",
            "partai": "PBB",
            "jumlah_suara": 1700,
            "calon_pemilih": 140
        },
        {
            "id": 3277,
            "province_id": "32",
            "name": "KOTA CIMAHI",
            "partai": "Perindo",
            "jumlah_suara": 1495,
            "calon_pemilih": 122
        },
        {
            "id": 3278,
            "province_id": "32",
            "name": "KOTA TASIKMALAYA",
            "partai": "PKB",
            "jumlah_suara": 1990,
            "calon_pemilih": 175
        },
        {
            "id": 3279,
            "province_id": "32",
            "name": "KOTA BANJAR",
            "partai": "PKPI",
            "jumlah_suara": 1511,
            "calon_pemilih": 177
        }
    ],
    dataSebaranSuaraJawaTengah: [
        {
            "id": 3301,
            "province_id": "33",
            "name": "CILACAP",
            "partai": "Demokrat",
            "jumlah_suara": 1548,
            "calon_pemilih": 169
        },
        {
            "id": 3302,
            "province_id": "33",
            "name": "BANYUMAS",
            "partai": "Berkarya",
            "jumlah_suara": 1206,
            "calon_pemilih": 113
        },
        {
            "id": 3303,
            "province_id": "33",
            "name": "PURBALINGGA",
            "partai": "Garuda",
            "jumlah_suara": 1673,
            "calon_pemilih": 193
        },
        {
            "id": 3304,
            "province_id": "33",
            "name": "BANJARNEGARA",
            "partai": "Gerindra",
            "jumlah_suara": 1321,
            "calon_pemilih": 119
        },
        {
            "id": 3305,
            "province_id": "33",
            "name": "KEBUMEN",
            "partai": "Golkar",
            "jumlah_suara": 1593,
            "calon_pemilih": 188
        },
        {
            "id": 3306,
            "province_id": "33",
            "name": "PURWOREJO",
            "partai": "Hanura",
            "jumlah_suara": 1566,
            "calon_pemilih": 182
        },
        {
            "id": 3307,
            "province_id": "33",
            "name": "WONOSOBO",
            "partai": "Nasdem",
            "jumlah_suara": 1795,
            "calon_pemilih": 179
        },
        {
            "id": 3308,
            "province_id": "33",
            "name": "MAGELANG",
            "partai": "PBB",
            "jumlah_suara": 1377,
            "calon_pemilih": 169
        },
        {
            "id": 3309,
            "province_id": "33",
            "name": "BOYOLALI",
            "partai": "Perindo",
            "jumlah_suara": 1276,
            "calon_pemilih": 163
        },
        {
            "id": 3310,
            "province_id": "33",
            "name": "KLATEN",
            "partai": "PKB",
            "jumlah_suara": 1267,
            "calon_pemilih": 110
        },
        {
            "id": 3311,
            "province_id": "33",
            "name": "SUKOHARJO",
            "partai": "PKPI",
            "jumlah_suara": 1492,
            "calon_pemilih": 121
        },
        {
            "id": 3312,
            "province_id": "33",
            "name": "WONOGIRI",
            "partai": "PPP",
            "jumlah_suara": 1171,
            "calon_pemilih": 151
        },
        {
            "id": 3313,
            "province_id": "33",
            "name": "KARANGANYAR",
            "partai": "PSI",
            "jumlah_suara": 1019,
            "calon_pemilih": 153
        },
        {
            "id": 3314,
            "province_id": "33",
            "name": "SRAGEN",
            "partai": "PKS",
            "jumlah_suara": 1277,
            "calon_pemilih": 182
        },
        {
            "id": 3315,
            "province_id": "33",
            "name": "GROBOGAN",
            "partai": "PAN",
            "jumlah_suara": 1918,
            "calon_pemilih": 179
        },
        {
            "id": 3316,
            "province_id": "33",
            "name": "BLORA",
            "partai": "PDIP",
            "jumlah_suara": 1119,
            "calon_pemilih": 157
        },
        {
            "id": 3317,
            "province_id": "33",
            "name": "REMBANG",
            "partai": "Demokrat",
            "jumlah_suara": 1018,
            "calon_pemilih": 147
        },
        {
            "id": 3318,
            "province_id": "33",
            "name": "PATI",
            "partai": "Berkarya",
            "jumlah_suara": 1709,
            "calon_pemilih": 103
        },
        {
            "id": 3319,
            "province_id": "33",
            "name": "KUDUS",
            "partai": "Garuda",
            "jumlah_suara": 1772,
            "calon_pemilih": 110
        },
        {
            "id": 3320,
            "province_id": "33",
            "name": "JEPARA",
            "partai": "Gerindra",
            "jumlah_suara": 1050,
            "calon_pemilih": 160
        },
        {
            "id": 3321,
            "province_id": "33",
            "name": "DEMAK",
            "partai": "Golkar",
            "jumlah_suara": 1268,
            "calon_pemilih": 119
        },
        {
            "id": 3322,
            "province_id": "33",
            "name": "SEMARANG",
            "partai": "Hanura",
            "jumlah_suara": 1722,
            "calon_pemilih": 139
        },
        {
            "id": 3323,
            "province_id": "33",
            "name": "TEMANGGUNG",
            "partai": "Nasdem",
            "jumlah_suara": 1360,
            "calon_pemilih": 104
        },
        {
            "id": 3324,
            "province_id": "33",
            "name": "KENDAL",
            "partai": "PBB",
            "jumlah_suara": 1990,
            "calon_pemilih": 142
        },
        {
            "id": 3325,
            "province_id": "33",
            "name": "BATANG",
            "partai": "Perindo",
            "jumlah_suara": 1870,
            "calon_pemilih": 104
        },
        {
            "id": 3326,
            "province_id": "33",
            "name": "PEKALONGAN",
            "partai": "PKB",
            "jumlah_suara": 1968,
            "calon_pemilih": 140
        },
        {
            "id": 3327,
            "province_id": "33",
            "name": "PEMALANG",
            "partai": "PKPI",
            "jumlah_suara": 1453,
            "calon_pemilih": 161
        },
        {
            "id": 3328,
            "province_id": "33",
            "name": "TEGAL",
            "partai": "PPP",
            "jumlah_suara": 1883,
            "calon_pemilih": 137
        },
        {
            "id": 3329,
            "province_id": "33",
            "name": "BREBES",
            "partai": "PSI",
            "jumlah_suara": 1563,
            "calon_pemilih": 172
        },
        {
            "id": 3371,
            "province_id": "33",
            "name": "KOTA MAGELANG",
            "partai": "PKS",
            "jumlah_suara": 1593,
            "calon_pemilih": 128
        },
        {
            "id": 3372,
            "province_id": "33",
            "name": "KOTA SURAKARTA",
            "partai": "PAN",
            "jumlah_suara": 1729,
            "calon_pemilih": 187
        },
        {
            "id": 3373,
            "province_id": "33",
            "name": "KOTA SALATIGA",
            "partai": "PDIP",
            "jumlah_suara": 1438,
            "calon_pemilih": 142
        },
        {
            "id": 3374,
            "province_id": "33",
            "name": "KOTA SEMARANG",
            "partai": "Demokrat",
            "jumlah_suara": 1998,
            "calon_pemilih": 123
        },
        {
            "id": 3375,
            "province_id": "33",
            "name": "KOTA PEKALONGAN",
            "partai": "Berkarya",
            "jumlah_suara": 1292,
            "calon_pemilih": 162
        },
        {
            "id": 3376,
            "province_id": "33",
            "name": "KOTA TEGAL",
            "partai": "Garuda",
            "jumlah_suara": 1900,
            "calon_pemilih": 146
        }
    ],
    dataSebaranSuaraJawaTimur: [
        {
            "id": 3501,
            "province_id": "35",
            "name": "PACITAN",
            "partai": "Demokrat",
            "jumlah_suara": 1578,
            "calon_pemilih": 195
        },
        {
            "id": 3502,
            "province_id": "35",
            "name": "PONOROGO",
            "partai": "Berkarya",
            "jumlah_suara": 1211,
            "calon_pemilih": 103
        },
        {
            "id": 3503,
            "province_id": "35",
            "name": "TRENGGALEK",
            "partai": "Garuda",
            "jumlah_suara": 1261,
            "calon_pemilih": 140
        },
        {
            "id": 3504,
            "province_id": "35",
            "name": "TULUNGAGUNG",
            "partai": "Gerindra",
            "jumlah_suara": 1127,
            "calon_pemilih": 190
        },
        {
            "id": 3505,
            "province_id": "35",
            "name": "BLITAR",
            "partai": "Golkar",
            "jumlah_suara": 1292,
            "calon_pemilih": 163
        },
        {
            "id": 3506,
            "province_id": "35",
            "name": "KEDIRI",
            "partai": "Hanura",
            "jumlah_suara": 1671,
            "calon_pemilih": 186
        },
        {
            "id": 3507,
            "province_id": "35",
            "name": "MALANG",
            "partai": "Nasdem",
            "jumlah_suara": 1898,
            "calon_pemilih": 141
        },
        {
            "id": 3508,
            "province_id": "35",
            "name": "LUMAJANG",
            "partai": "PBB",
            "jumlah_suara": 1595,
            "calon_pemilih": 157
        },
        {
            "id": 3509,
            "province_id": "35",
            "name": "JEMBER",
            "partai": "Perindo",
            "jumlah_suara": 1349,
            "calon_pemilih": 160
        },
        {
            "id": 3510,
            "province_id": "35",
            "name": "BANYUWANGI",
            "partai": "PKB",
            "jumlah_suara": 1724,
            "calon_pemilih": 128
        },
        {
            "id": 3511,
            "province_id": "35",
            "name": "BONDOWOSO",
            "partai": "PKPI",
            "jumlah_suara": 1471,
            "calon_pemilih": 123
        },
        {
            "id": 3512,
            "province_id": "35",
            "name": "SITUBONDO",
            "partai": "PPP",
            "jumlah_suara": 1639,
            "calon_pemilih": 145
        },
        {
            "id": 3513,
            "province_id": "35",
            "name": "PROBOLINGGO",
            "partai": "PSI",
            "jumlah_suara": 1906,
            "calon_pemilih": 166
        },
        {
            "id": 3514,
            "province_id": "35",
            "name": "PASURUAN",
            "partai": "PKS",
            "jumlah_suara": 1166,
            "calon_pemilih": 152
        },
        {
            "id": 3515,
            "province_id": "35",
            "name": "SIDOARJO",
            "partai": "PAN",
            "jumlah_suara": 1566,
            "calon_pemilih": 188
        },
        {
            "id": 3516,
            "province_id": "35",
            "name": "MOJOKERTO",
            "partai": "PDIP",
            "jumlah_suara": 1100,
            "calon_pemilih": 107
        },
        {
            "id": 3517,
            "province_id": "35",
            "name": "JOMBANG",
            "partai": "Demokrat",
            "jumlah_suara": 1780,
            "calon_pemilih": 125
        },
        {
            "id": 3518,
            "province_id": "35",
            "name": "NGANJUK",
            "partai": "Berkarya",
            "jumlah_suara": 1177,
            "calon_pemilih": 128
        },
        {
            "id": 3519,
            "province_id": "35",
            "name": "MADIUN",
            "partai": "Garuda",
            "jumlah_suara": 1968,
            "calon_pemilih": 115
        },
        {
            "id": 3520,
            "province_id": "35",
            "name": "MAGETAN",
            "partai": "Gerindra",
            "jumlah_suara": 1355,
            "calon_pemilih": 193
        },
        {
            "id": 3521,
            "province_id": "35",
            "name": "NGAWI",
            "partai": "Golkar",
            "jumlah_suara": 1651,
            "calon_pemilih": 152
        },
        {
            "id": 3522,
            "province_id": "35",
            "name": "BOJONEGORO",
            "partai": "Hanura",
            "jumlah_suara": 1090,
            "calon_pemilih": 136
        },
        {
            "id": 3523,
            "province_id": "35",
            "name": "TUBAN",
            "partai": "Nasdem",
            "jumlah_suara": 1963,
            "calon_pemilih": 179
        },
        {
            "id": 3524,
            "province_id": "35",
            "name": "LAMONGAN",
            "partai": "PBB",
            "jumlah_suara": 1116,
            "calon_pemilih": 135
        },
        {
            "id": 3525,
            "province_id": "35",
            "name": "GRESIK",
            "partai": "Perindo",
            "jumlah_suara": 1853,
            "calon_pemilih": 157
        },
        {
            "id": 3526,
            "province_id": "35",
            "name": "BANGKALAN",
            "partai": "PKB",
            "jumlah_suara": 1617,
            "calon_pemilih": 162
        },
        {
            "id": 3527,
            "province_id": "35",
            "name": "SAMPANG",
            "partai": "PKPI",
            "jumlah_suara": 1957,
            "calon_pemilih": 123
        },
        {
            "id": 3528,
            "province_id": "35",
            "name": "PAMEKASAN",
            "partai": "PPP",
            "jumlah_suara": 1085,
            "calon_pemilih": 133
        },
        {
            "id": 3529,
            "province_id": "35",
            "name": "SUMENEP",
            "partai": "PSI",
            "jumlah_suara": 1099,
            "calon_pemilih": 100
        },
        {
            "id": 3571,
            "province_id": "35",
            "name": "KOTA KEDIRI",
            "partai": "PKS",
            "jumlah_suara": 1391,
            "calon_pemilih": 102
        },
        {
            "id": 3572,
            "province_id": "35",
            "name": "KOTA BLITAR",
            "partai": "PAN",
            "jumlah_suara": 1346,
            "calon_pemilih": 170
        },
        {
            "id": 3573,
            "province_id": "35",
            "name": "KOTA MALANG",
            "partai": "PDIP",
            "jumlah_suara": 1376,
            "calon_pemilih": 184
        },
        {
            "id": 3574,
            "province_id": "35",
            "name": "KOTA PROBOLINGGO",
            "partai": "Demokrat",
            "jumlah_suara": 1808,
            "calon_pemilih": 100
        },
        {
            "id": 3575,
            "province_id": "35",
            "name": "KOTA PASURUAN",
            "partai": "Berkarya",
            "jumlah_suara": 1053,
            "calon_pemilih": 187
        },
        {
            "id": 3576,
            "province_id": "35",
            "name": "KOTA MOJOKERTO",
            "partai": "Garuda",
            "jumlah_suara": 1071,
            "calon_pemilih": 121
        },
        {
            "id": 3577,
            "province_id": "35",
            "name": "KOTA MADIUN",
            "partai": "Gerindra",
            "jumlah_suara": 1958,
            "calon_pemilih": 129
        },
        {
            "id": 3578,
            "province_id": "35",
            "name": "KOTA SURABAYA",
            "partai": "Golkar",
            "jumlah_suara": 1318,
            "calon_pemilih": 173
        },
        {
            "id": 3579,
            "province_id": "35",
            "name": "KOTA BATU",
            "partai": "Hanura",
            "jumlah_suara": 1039,
            "calon_pemilih": 103
        }
    ],
    dataSebaranSuaraBanten: [
        {
            "id": 3601,
            "province_id": "36",
            "name": "PANDEGLANG",
            "partai": "Demokrat",
            "jumlah_suara": 1278,
            "calon_pemilih": 173
        },
        {
            "id": 3602,
            "province_id": "36",
            "name": "LEBAK",
            "partai": "Berkarya",
            "jumlah_suara": 1541,
            "calon_pemilih": 108
        },
        {
            "id": 3603,
            "province_id": "36",
            "name": "TANGERANG",
            "partai": "Garuda",
            "jumlah_suara": 1461,
            "calon_pemilih": 109
        },
        {
            "id": 3604,
            "province_id": "36",
            "name": "SERANG",
            "partai": "Gerindra",
            "jumlah_suara": 1157,
            "calon_pemilih": 101
        },
        {
            "id": 3671,
            "province_id": "36",
            "name": "KOTA TANGERANG",
            "partai": "Golkar",
            "jumlah_suara": 1493,
            "calon_pemilih": 112
        },
        {
            "id": 3672,
            "province_id": "36",
            "name": "KOTA CILEGON",
            "partai": "Hanura",
            "jumlah_suara": 1471,
            "calon_pemilih": 182
        },
        {
            "id": 3673,
            "province_id": "36",
            "name": "KOTA SERANG",
            "partai": "Nasdem",
            "jumlah_suara": 1462,
            "calon_pemilih": 187
        },
        {
            "id": 3674,
            "province_id": "36",
            "name": "KOTA TANGERANG SELATAN",
            "partai": "PBB",
            "jumlah_suara": 1597,
            "calon_pemilih": 136
        }
    ],
    dataSebaranSuaraJakarta: [
        {
            "id": 3101,
            "province_id": "31",
            "name": "KEPULAUAN SERIBU",
            "partai": "Demokrat",
            "jumlah_suara": 1952,
            "calon_pemilih": 188
        },
        {
            "id": 3171,
            "province_id": "31",
            "name": "KOTA JAKARTA SELATAN",
            "partai": "Berkarya",
            "jumlah_suara": 1081,
            "calon_pemilih": 177
        },
        {
            "id": 3172,
            "province_id": "31",
            "name": "KOTA JAKARTA TIMUR",
            "partai": "Garuda",
            "jumlah_suara": 1771,
            "calon_pemilih": 126
        },
        {
            "id": 3173,
            "province_id": "31",
            "name": "KOTA JAKARTA PUSAT",
            "partai": "Gerindra",
            "jumlah_suara": 1100,
            "calon_pemilih": 169
        },
        {
            "id": 3174,
            "province_id": "31",
            "name": "KOTA JAKARTA BARAT",
            "partai": "Golkar",
            "jumlah_suara": 1801,
            "calon_pemilih": 149
        },
        {
            "id": 3175,
            "province_id": "31",
            "name": "KOTA JAKARTA UTARA",
            "partai": "Hanura",
            "jumlah_suara": 1269,
            "calon_pemilih": 186
        }
    ],
    dataSebaranSuaraYogyakarta: [
        {
            "id": 3401,
            "province_id": "34",
            "name": "KULON PROGO",
            "partai": "Demokrat",
            "jumlah_suara": 1638,
            "calon_pemilih": 138
        },
        {
            "id": 3402,
            "province_id": "34",
            "name": "BANTUL",
            "partai": "Berkarya",
            "jumlah_suara": 1462,
            "calon_pemilih": 148
        },
        {
            "id": 3403,
            "province_id": "34",
            "name": "GUNUNG KIDUL",
            "partai": "Garuda",
            "jumlah_suara": 1325,
            "calon_pemilih": 134
        },
        {
            "id": 3404,
            "province_id": "34",
            "name": "SLEMAN",
            "partai": "Gerindra",
            "jumlah_suara": 1613,
            "calon_pemilih": 150
        },
        {
            "id": 3471,
            "province_id": "34",
            "name": "KOTA YOGYAKARTA",
            "partai": "Golkar",
            "jumlah_suara": 1618,
            "calon_pemilih": 142
        }
    ],
    dataSebaranSuaraBali: [
        {
            "id": 5101,
            "province_id": "51",
            "name": "JEMBRANA",
            "partai": "Demokrat",
            "jumlah_suara": 1432,
            "calon_pemilih": 157
        },
        {
            "id": 5102,
            "province_id": "51",
            "name": "TABANAN",
            "partai": "Berkarya",
            "jumlah_suara": 1756,
            "calon_pemilih": 177
        },
        {
            "id": 5103,
            "province_id": "51",
            "name": "BADUNG",
            "partai": "Garuda",
            "jumlah_suara": 1840,
            "calon_pemilih": 172
        },
        {
            "id": 5104,
            "province_id": "51",
            "name": "GIANYAR",
            "partai": "Gerindra",
            "jumlah_suara": 1521,
            "calon_pemilih": 154
        },
        {
            "id": 5105,
            "province_id": "51",
            "name": "KLUNGKUNG",
            "partai": "Golkar",
            "jumlah_suara": 1955,
            "calon_pemilih": 192
        },
        {
            "id": 5106,
            "province_id": "51",
            "name": "BANGLI",
            "partai": "Hanura",
            "jumlah_suara": 1966,
            "calon_pemilih": 106
        },
        {
            "id": 5107,
            "province_id": "51",
            "name": "KARANG ASEM",
            "partai": "Nasdem",
            "jumlah_suara": 1470,
            "calon_pemilih": 127
        },
        {
            "id": 5108,
            "province_id": "51",
            "name": "BULELENG",
            "partai": "PBB",
            "jumlah_suara": 1759,
            "calon_pemilih": 100
        },
        {
            "id": 5171,
            "province_id": "51",
            "name": "KOTA DENPASAR",
            "partai": "Perindo",
            "jumlah_suara": 1260,
            "calon_pemilih": 144
        }
    ],
    dataSebaranSuaraNTB: [
        {
            "id": 5201,
            "province_id": "52",
            "name": "LOMBOK BARAT",
            "partai": "Demokrat",
            "jumlah_suara": 1563,
            "calon_pemilih": 179
        },
        {
            "id": 5202,
            "province_id": "52",
            "name": "LOMBOK TENGAH",
            "partai": "Berkarya",
            "jumlah_suara": 1069,
            "calon_pemilih": 152
        },
        {
            "id": 5203,
            "province_id": "52",
            "name": "LOMBOK TIMUR",
            "partai": "Garuda",
            "jumlah_suara": 1179,
            "calon_pemilih": 178
        },
        {
            "id": 5204,
            "province_id": "52",
            "name": "SUMBAWA",
            "partai": "Gerindra",
            "jumlah_suara": 1385,
            "calon_pemilih": 171
        },
        {
            "id": 5205,
            "province_id": "52",
            "name": "DOMPU",
            "partai": "Golkar",
            "jumlah_suara": 1105,
            "calon_pemilih": 194
        },
        {
            "id": 5206,
            "province_id": "52",
            "name": "BIMA",
            "partai": "Hanura",
            "jumlah_suara": 1755,
            "calon_pemilih": 155
        },
        {
            "id": 5207,
            "province_id": "52",
            "name": "SUMBAWA BARAT",
            "partai": "Nasdem",
            "jumlah_suara": 1422,
            "calon_pemilih": 157
        },
        {
            "id": 5208,
            "province_id": "52",
            "name": "LOMBOK UTARA",
            "partai": "PBB",
            "jumlah_suara": 1670,
            "calon_pemilih": 159
        },
        {
            "id": 5271,
            "province_id": "52",
            "name": "KOTA MATARAM",
            "partai": "Perindo",
            "jumlah_suara": 1194,
            "calon_pemilih": 111
        },
        {
            "id": 5272,
            "province_id": "52",
            "name": "KOTA BIMA",
            "partai": "PKB",
            "jumlah_suara": 1491,
            "calon_pemilih": 148
        }
    ],
    dataSebaranSuaraNTT: [
        {
            "id": 5301,
            "province_id": "53",
            "name": "SUMBA BARAT",
            "partai": "Demokrat",
            "jumlah_suara": 1204,
            "calon_pemilih": 196
        },
        {
            "id": 5302,
            "province_id": "53",
            "name": "SUMBA TIMUR",
            "partai": "Berkarya",
            "jumlah_suara": 1471,
            "calon_pemilih": 107
        },
        {
            "id": 5303,
            "province_id": "53",
            "name": "KUPANG",
            "partai": "Garuda",
            "jumlah_suara": 1898,
            "calon_pemilih": 200
        },
        {
            "id": 5304,
            "province_id": "53",
            "name": "TIMOR TENGAH SELATAN",
            "partai": "Gerindra",
            "jumlah_suara": 1535,
            "calon_pemilih": 146
        },
        {
            "id": 5305,
            "province_id": "53",
            "name": "TIMOR TENGAH UTARA",
            "partai": "Golkar",
            "jumlah_suara": 1455,
            "calon_pemilih": 194
        },
        {
            "id": 5306,
            "province_id": "53",
            "name": "BELU",
            "partai": "Hanura",
            "jumlah_suara": 1832,
            "calon_pemilih": 124
        },
        {
            "id": 5307,
            "province_id": "53",
            "name": "ALOR",
            "partai": "Nasdem",
            "jumlah_suara": 1826,
            "calon_pemilih": 175
        },
        {
            "id": 5308,
            "province_id": "53",
            "name": "LEMBATA",
            "partai": "PBB",
            "jumlah_suara": 1133,
            "calon_pemilih": 175
        },
        {
            "id": 5309,
            "province_id": "53",
            "name": "FLORES TIMUR",
            "partai": "Perindo",
            "jumlah_suara": 1949,
            "calon_pemilih": 199
        },
        {
            "id": 5310,
            "province_id": "53",
            "name": "SIKKA",
            "partai": "PKB",
            "jumlah_suara": 1458,
            "calon_pemilih": 122
        },
        {
            "id": 5311,
            "province_id": "53",
            "name": "ENDE",
            "partai": "PKPI",
            "jumlah_suara": 1921,
            "calon_pemilih": 155
        },
        {
            "id": 5312,
            "province_id": "53",
            "name": "NGADA",
            "partai": "PPP",
            "jumlah_suara": 1446,
            "calon_pemilih": 122
        },
        {
            "id": 5313,
            "province_id": "53",
            "name": "MANGGARAI",
            "partai": "PSI",
            "jumlah_suara": 1756,
            "calon_pemilih": 190
        },
        {
            "id": 5314,
            "province_id": "53",
            "name": "ROTE NDAO",
            "partai": "PKS",
            "jumlah_suara": 1974,
            "calon_pemilih": 146
        },
        {
            "id": 5315,
            "province_id": "53",
            "name": "MANGGARAI BARAT",
            "partai": "PAN",
            "jumlah_suara": 1406,
            "calon_pemilih": 139
        },
        {
            "id": 5316,
            "province_id": "53",
            "name": "SUMBA TENGAH",
            "partai": "PDIP",
            "jumlah_suara": 1417,
            "calon_pemilih": 173
        },
        {
            "id": 5317,
            "province_id": "53",
            "name": "SUMBA BARAT DAYA",
            "partai": "Demokrat",
            "jumlah_suara": 1375,
            "calon_pemilih": 166
        },
        {
            "id": 5318,
            "province_id": "53",
            "name": "NAGEKEO",
            "partai": "Berkarya",
            "jumlah_suara": 1874,
            "calon_pemilih": 111
        },
        {
            "id": 5319,
            "province_id": "53",
            "name": "MANGGARAI TIMUR",
            "partai": "Garuda",
            "jumlah_suara": 1188,
            "calon_pemilih": 174
        },
        {
            "id": 5320,
            "province_id": "53",
            "name": "SABU RAIJUA",
            "partai": "Gerindra",
            "jumlah_suara": 1484,
            "calon_pemilih": 163
        },
        {
            "id": 5321,
            "province_id": "53",
            "name": "MALAKA",
            "partai": "Golkar",
            "jumlah_suara": 1492,
            "calon_pemilih": 155
        },
        {
            "id": 5371,
            "province_id": "53",
            "name": "KOTA KUPANG",
            "partai": "Hanura",
            "jumlah_suara": 1674,
            "calon_pemilih": 104
        }
    ],
    dataSebaranSuaraKalimantanBarat: [
        {
            "id": 6101,
            "province_id": "61",
            "name": "SAMBAS",
            "partai": "Demokrat",
            "jumlah_suara": 1119,
            "calon_pemilih": 194
        },
        {
            "id": 6102,
            "province_id": "61",
            "name": "BENGKAYANG",
            "partai": "Berkarya",
            "jumlah_suara": 1184,
            "calon_pemilih": 173
        },
        {
            "id": 6103,
            "province_id": "61",
            "name": "LANDAK",
            "partai": "Garuda",
            "jumlah_suara": 1169,
            "calon_pemilih": 151
        },
        {
            "id": 6104,
            "province_id": "61",
            "name": "MEMPAWAH",
            "partai": "Gerindra",
            "jumlah_suara": 1597,
            "calon_pemilih": 137
        },
        {
            "id": 6105,
            "province_id": "61",
            "name": "SANGGAU",
            "partai": "Golkar",
            "jumlah_suara": 1494,
            "calon_pemilih": 130
        },
        {
            "id": 6106,
            "province_id": "61",
            "name": "KETAPANG",
            "partai": "Hanura",
            "jumlah_suara": 1996,
            "calon_pemilih": 134
        },
        {
            "id": 6107,
            "province_id": "61",
            "name": "SINTANG",
            "partai": "Nasdem",
            "jumlah_suara": 1968,
            "calon_pemilih": 166
        },
        {
            "id": 6108,
            "province_id": "61",
            "name": "KAPUAS HULU",
            "partai": "PBB",
            "jumlah_suara": 1790,
            "calon_pemilih": 155
        },
        {
            "id": 6109,
            "province_id": "61",
            "name": "SEKADAU",
            "partai": "Perindo",
            "jumlah_suara": 1031,
            "calon_pemilih": 193
        },
        {
            "id": 6110,
            "province_id": "61",
            "name": "MELAWI",
            "partai": "PKB",
            "jumlah_suara": 1513,
            "calon_pemilih": 127
        },
        {
            "id": 6111,
            "province_id": "61",
            "name": "KAYONG UTARA",
            "partai": "PKPI",
            "jumlah_suara": 1849,
            "calon_pemilih": 136
        },
        {
            "id": 6112,
            "province_id": "61",
            "name": "KUBU RAYA",
            "partai": "PPP",
            "jumlah_suara": 1585,
            "calon_pemilih": 200
        },
        {
            "id": 6171,
            "province_id": "61",
            "name": "KOTA PONTIANAK",
            "partai": "PSI",
            "jumlah_suara": 1954,
            "calon_pemilih": 182
        },
        {
            "id": 6172,
            "province_id": "61",
            "name": "KOTA SINGKAWANG",
            "partai": "PKS",
            "jumlah_suara": 1092,
            "calon_pemilih": 198
        }
    ],
    dataSebaranSuaraKalimantanTengah: [
        {
            "id": 6201,
            "province_id": "62",
            "name": "KOTAWARINGIN BARAT",
            "partai": "Demokrat",
            "jumlah_suara": 1561,
            "calon_pemilih": 122
        },
        {
            "id": 6202,
            "province_id": "62",
            "name": "KOTAWARINGIN TIMUR",
            "partai": "Berkarya",
            "jumlah_suara": 1149,
            "calon_pemilih": 136
        },
        {
            "id": 6203,
            "province_id": "62",
            "name": "KAPUAS",
            "partai": "Garuda",
            "jumlah_suara": 1745,
            "calon_pemilih": 191
        },
        {
            "id": 6204,
            "province_id": "62",
            "name": "BARITO SELATAN",
            "partai": "Gerindra",
            "jumlah_suara": 1192,
            "calon_pemilih": 167
        },
        {
            "id": 6205,
            "province_id": "62",
            "name": "BARITO UTARA",
            "partai": "Golkar",
            "jumlah_suara": 1729,
            "calon_pemilih": 102
        },
        {
            "id": 6206,
            "province_id": "62",
            "name": "SUKAMARA",
            "partai": "Hanura",
            "jumlah_suara": 1344,
            "calon_pemilih": 177
        },
        {
            "id": 6207,
            "province_id": "62",
            "name": "LAMANDAU",
            "partai": "Nasdem",
            "jumlah_suara": 1574,
            "calon_pemilih": 158
        },
        {
            "id": 6208,
            "province_id": "62",
            "name": "SERUYAN",
            "partai": "PBB",
            "jumlah_suara": 1495,
            "calon_pemilih": 200
        },
        {
            "id": 6209,
            "province_id": "62",
            "name": "KATINGAN",
            "partai": "Perindo",
            "jumlah_suara": 1377,
            "calon_pemilih": 111
        },
        {
            "id": 6210,
            "province_id": "62",
            "name": "PULANG PISAU",
            "partai": "PKB",
            "jumlah_suara": 1556,
            "calon_pemilih": 177
        },
        {
            "id": 6211,
            "province_id": "62",
            "name": "GUNUNG MAS",
            "partai": "PKPI",
            "jumlah_suara": 1098,
            "calon_pemilih": 187
        },
        {
            "id": 6212,
            "province_id": "62",
            "name": "BARITO TIMUR",
            "partai": "PPP",
            "jumlah_suara": 1399,
            "calon_pemilih": 188
        },
        {
            "id": 6213,
            "province_id": "62",
            "name": "MURUNG RAYA",
            "partai": "PSI",
            "jumlah_suara": 1218,
            "calon_pemilih": 127
        },
        {
            "id": 6271,
            "province_id": "62",
            "name": "KOTA PALANGKA RAYA",
            "partai": "PKS",
            "jumlah_suara": 1787,
            "calon_pemilih": 142
        }
    ],
    dataSebaranSuaraKalimantanSelatan: [
        {
            "id": 6301,
            "province_id": "63",
            "name": "TANAH LAUT",
            "partai": "Demokrat",
            "jumlah_suara": 1356,
            "calon_pemilih": 133
        },
        {
            "id": 6302,
            "province_id": "63",
            "name": "KOTA BARU",
            "partai": "Berkarya",
            "jumlah_suara": 1119,
            "calon_pemilih": 105
        },
        {
            "id": 6303,
            "province_id": "63",
            "name": "BANJAR",
            "partai": "Garuda",
            "jumlah_suara": 1329,
            "calon_pemilih": 120
        },
        {
            "id": 6304,
            "province_id": "63",
            "name": "BARITO KUALA",
            "partai": "Gerindra",
            "jumlah_suara": 1655,
            "calon_pemilih": 131
        },
        {
            "id": 6305,
            "province_id": "63",
            "name": "TAPIN",
            "partai": "Golkar",
            "jumlah_suara": 1773,
            "calon_pemilih": 160
        },
        {
            "id": 6306,
            "province_id": "63",
            "name": "HULU SUNGAI SELATAN",
            "partai": "Hanura",
            "jumlah_suara": 1913,
            "calon_pemilih": 172
        },
        {
            "id": 6307,
            "province_id": "63",
            "name": "HULU SUNGAI TENGAH",
            "partai": "Nasdem",
            "jumlah_suara": 1975,
            "calon_pemilih": 189
        },
        {
            "id": 6308,
            "province_id": "63",
            "name": "HULU SUNGAI UTARA",
            "partai": "PBB",
            "jumlah_suara": 1996,
            "calon_pemilih": 150
        },
        {
            "id": 6309,
            "province_id": "63",
            "name": "TABALONG",
            "partai": "Perindo",
            "jumlah_suara": 1070,
            "calon_pemilih": 188
        },
        {
            "id": 6310,
            "province_id": "63",
            "name": "TANAH BUMBU",
            "partai": "PKB",
            "jumlah_suara": 1366,
            "calon_pemilih": 113
        },
        {
            "id": 6311,
            "province_id": "63",
            "name": "BALANGAN",
            "partai": "PKPI",
            "jumlah_suara": 1679,
            "calon_pemilih": 172
        },
        {
            "id": 6371,
            "province_id": "63",
            "name": "KOTA BANJARMASIN",
            "partai": "PPP",
            "jumlah_suara": 1027,
            "calon_pemilih": 131
        },
        {
            "id": 6372,
            "province_id": "63",
            "name": "KOTA BANJAR BARU",
            "partai": "PSI",
            "jumlah_suara": 1503,
            "calon_pemilih": 174
        }
    ],
    dataSebaranSuaraKalimantanTimur: [
        {
            "id": 6401,
            "province_id": "64",
            "name": "PASER",
            "partai": "Demokrat",
            "jumlah_suara": 1827,
            "calon_pemilih": 162
        },
        {
            "id": 6402,
            "province_id": "64",
            "name": "KUTAI BARAT",
            "partai": "Berkarya",
            "jumlah_suara": 1850,
            "calon_pemilih": 155
        },
        {
            "id": 6403,
            "province_id": "64",
            "name": "KUTAI KARTANEGARA",
            "partai": "Garuda",
            "jumlah_suara": 1096,
            "calon_pemilih": 166
        },
        {
            "id": 6404,
            "province_id": "64",
            "name": "KUTAI TIMUR",
            "partai": "Gerindra",
            "jumlah_suara": 1094,
            "calon_pemilih": 191
        },
        {
            "id": 6405,
            "province_id": "64",
            "name": "BERAU",
            "partai": "Golkar",
            "jumlah_suara": 1452,
            "calon_pemilih": 199
        },
        {
            "id": 6409,
            "province_id": "64",
            "name": "PENAJAM PASER UTARA",
            "partai": "Hanura",
            "jumlah_suara": 1892,
            "calon_pemilih": 117
        },
        {
            "id": 6411,
            "province_id": "64",
            "name": "MAHAKAM HULU",
            "partai": "Nasdem",
            "jumlah_suara": 1698,
            "calon_pemilih": 188
        },
        {
            "id": 6471,
            "province_id": "64",
            "name": "KOTA BALIKPAPAN",
            "partai": "PBB",
            "jumlah_suara": 1232,
            "calon_pemilih": 162
        },
        {
            "id": 6472,
            "province_id": "64",
            "name": "KOTA SAMARINDA",
            "partai": "Perindo",
            "jumlah_suara": 1376,
            "calon_pemilih": 107
        },
        {
            "id": 6474,
            "province_id": "64",
            "name": "KOTA BONTANG",
            "partai": "PKB",
            "jumlah_suara": 1291,
            "calon_pemilih": 172
        }
    ],

}

Mock.onGet("/api/v1/user/sebaran_suara?wilayah=kota?provinsi=32").reply(config => {
  const response = SebaranSuaraDB.dataSebaranSuaraJawaBarat;
  return [200, response];
});
Mock.onGet("/api/v1/user/sebaran_suara?wilayah=kota?provinsi=33").reply(config => {
    const response = SebaranSuaraDB.dataSebaranSuaraJawaTengah;
    return [200, response];
  });
Mock.onGet("/api/v1/user/sebaran_suara?wilayah=kota?provinsi=35").reply(config => {
  const response = SebaranSuaraDB.dataSebaranSuaraJawaTimur;
  return [200, response];
});
Mock.onGet("/api/v1/user/sebaran_suara?wilayah=kota?provinsi=36").reply(config => {
  const response = SebaranSuaraDB.dataSebaranSuaraBanten;
  return [200, response];
});
Mock.onGet("/api/v1/user/sebaran_suara?wilayah=kota?provinsi=31").reply(config => {
  const response = SebaranSuaraDB.dataSebaranSuaraJakarta;
  return [200, response];
});
Mock.onGet("/api/v1/user/sebaran_suara?wilayah=kota?provinsi=34").reply(config => {
  const response = SebaranSuaraDB.dataSebaranSuaraYogyakarta;
  return [200, response];
});
Mock.onGet("/api/v1/user/sebaran_suara?wilayah=kota?provinsi=51").reply(config => {
  const response = SebaranSuaraDB.dataSebaranSuaraBali;
  return [200, response];
});
Mock.onGet("/api/v1/user/sebaran_suara?wilayah=kota?provinsi=52").reply(config => {
  const response = SebaranSuaraDB.dataSebaranSuaraNTB;
  return [200, response];
});
Mock.onGet("/api/v1/user/sebaran_suara?wilayah=kota?provinsi=53").reply(config => {
  const response = SebaranSuaraDB.dataSebaranSuaraNTT;
  return [200, response];
});
Mock.onGet("/api/v1/user/sebaran_suara?wilayah=kota?provinsi=61").reply(config => {
  const response = SebaranSuaraDB.dataSebaranSuaraKalimantanBarat;
  return [200, response];
});
Mock.onGet("/api/v1/user/sebaran_suara?wilayah=kota?provinsi=62").reply(config => {
  const response = SebaranSuaraDB.dataSebaranSuaraKalimantanTengah;
  return [200, response];
});
Mock.onGet("/api/v1/user/sebaran_suara?wilayah=kota?provinsi=63").reply(config => {
  const response = SebaranSuaraDB.dataSebaranSuaraKalimantanSelatan;
  return [200, response];
});
Mock.onGet("/api/v1/user/sebaran_suara?wilayah=kota?provinsi=64").reply(config => {
  const response = SebaranSuaraDB.dataSebaranSuaraKalimantanTimur;
  return [200, response];
});