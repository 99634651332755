/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import InputFileImg from "app/components/Input/InputFileImg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CalendarIcon } from "app/components/Icons";
import {
  addArusKas,
  getKategoriArusKas,
} from "app/redux/actions/ArusKasActions";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { formatDate } from "helpers/formatDate";
import InputAdornment from "@material-ui/core/InputAdornment";
import { showError, showSuccess } from "app/components/sweetalertService";
import GreenButton from "app/components/GreenButton";

const AddArusKas = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [state, setState] = useState({
    arus_kas_foto: "",
    arus_kas_kategori_arus_code: "",
    arus_kas_total: "",
    arus_kas_tanggal: "",
    arus_kas_detail: "",
    arus_kas_catatan: "",
    arus_kas_relawan_code: "2",
  });

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [errorField, setErrorField] = useState({});

  const handleSubmit = () => {
    addArusKas(state)
      .then((res) => {
        showSuccess("Data berhasil disimpan");
        history.push("/arus_kas");
      })
      .catch((err) => {
        showError("Data gagal disimpan");
        setErrorField(err.response.data.data);
      });
  };

  const getData = () => {
    dispatch(getKategoriArusKas());
  };

  const { kategori } = useSelector((state) => state.arusKas);

  useEffect(() => {
    getData();
  }, []);

  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);

    setState((prevState) => ({
      ...prevState,
      arus_kas_tanggal: formattedDate,
    }));
  };

  const handleChangeFoto = (file, path) => {
    setState((prev) => ({
      ...prev,
      arus_kas_foto: file,
      arus_kas_foto_preview: path,
    }));
  };

  return (
    <div className="analytics m-sm-30 mt-7">
      <Grid
        container
        spacing={3}
        className="mb-2 d-flex justify-content-center align-items-center"
      >
        <Grid item lg={6} md={6} xs={12} sm={6}>
          <h2 className="font-size-32 fw-700 fw-bold">Tambah Arus Kas</h2>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          sm={6}
          spacing={2}
          container
          justify="flex-end"
        >
          <Grid item>
            <GreenButton
              text="Kembali"
              onClick={() => history.push("/arus_kas")}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <GreenButton
              text="Simpan Data"
              onClick={handleSubmit}
              variant="contained"
            />
          </Grid>
        </Grid>
      </Grid>

      <Card style={{ padding: "20px", marginBottom: "20px" }}>
        <Grid container className="mt-2 p-2" spacing={2}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <h3 className="fw-bold font-size-40">Detail Arus Kas</h3>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="arus_kas_foto">
              <h5 className="font-size-14">Foto Kuitansi</h5>
              <InputFileImg
                noPadding={true}
                name="arus_kas_foto"
                uploadFoto={handleChangeFoto}
                preview={state.arus_kas_foto}
                acceptFile="image"
              />
            </InputLabel>
            <small className="text-danger">{errorField?.arus_kas_foto}</small>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}></Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="arus_kas_kategori_arus_code">
              <h5 className="font-size-14">Kategori</h5>
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: {
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 5,
                  backgroundColor: "#FBFBFB",
                },
              }}
              size="small"
              labelId="arus_kas_kategori_arus_code"
              value={state.arus_kas_kategori_arus_code}
              onChange={handleChange}
              variant="outlined"
              className="w-100"
              name="arus_kas_kategori_arus_code"
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return <span style={{ color: "#999" }}>Pilih Kategori</span>;
                }
                const selectedCategory = kategori.find(
                  (item) => item.kategori_arus_kas_code === selected
                );
                return selectedCategory
                  ? selectedCategory.kategori_arus_kas_nama
                  : "";
              }}
            >
              {kategori.map((item, index) => (
                <MenuItem key={index} value={item.kategori_arus_kas_code}>
                  {item.kategori_arus_kas_nama}
                </MenuItem>
              ))}
            </Select>
            <small className="text-danger">
              {errorField?.arus_kas_kategori_arus_code}
            </small>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="arus_kas_tanggal">
              <h5 className="font-size-14" style={{ marginBottom: "-5px" }}>
                Tanggal
              </h5>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker"]}
                style={{ marginTop: "50px" }}
              >
                <DatePicker
                  className={`w-100`}
                  size="small"
                  required={true}
                  inputFormat="DD/MM/YYYY"
                  onChange={handleDateChange}
                  //   label='Pilih Tanggal'
                  InputProps={{
                    style: {
                      borderRadius: 5,
                    },
                  }}
                  components={{
                    OpenPickerIcon: CalendarIcon,
                  }}
                  variant="outlined"
                  views={["year", "month", "day"]}
                  renderInput={(params) => <TextField {...params} />}
                  format="DD/MM/YYYY"
                />
              </DemoContainer>
            </LocalizationProvider>
            <small className="text-danger">
              {errorField?.arus_kas_tanggal}
            </small>
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="arus_kas_total">
              <h5 className="font-size-14">Total</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.arus_kas_total}
              name="arus_kas_total"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
                startAdornment: (
                  <InputAdornment position="start" style={{ fontSize: 16 }}>
                    Rp
                  </InputAdornment>
                ),
              }}
              placeholder="0"
              variant="outlined"
              onChange={handleChange}
              type="number"
              error={errorField?.arus_kas_total !== ""}
              helperText={errorField?.arus_kas_total}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}></Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="arus_kas_detail">
              <h5 className="font-size-14">Detail Arus Kas</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.arus_kas_detail}
              name="arus_kas_detail"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              multiline
              rows={3}
              placeholder="Tulis detail disini (Contoh: Pembelian, Survey)"
              variant="outlined"
              onChange={handleChange}
              type="text"
              error={errorField?.arus_kas_detail !== ""}
              helperText={errorField?.arus_kas_detail}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} sm={12}>
            <InputLabel htmlFor="arus_kas_catatan">
              <h5 className="font-size-14">Catatan</h5>
            </InputLabel>
            <TextField
              required={true}
              size="small"
              value={state.arus_kas_catatan}
              name="arus_kas_catatan"
              className={`w-100`}
              InputProps={{
                style: {
                  borderRadius: 5,
                },
              }}
              multiline
              rows={3}
              placeholder="Tulis catatan disini (Contoh: Dana masih kurang, Belum lunas)"
              variant="outlined"
              onChange={handleChange}
              type="text"
              error={errorField?.arus_kas_catatan !== ""}
              helperText={errorField?.arus_kas_catatan}
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    kategori: state.arusKas.kategori,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getKategoriArusKas: () => dispatch(getKategoriArusKas()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddArusKas);
